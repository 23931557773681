import { useState, FC, useEffect, ChangeEvent } from 'react';
import { Container } from '../styles';
import { Models } from '@services/ms-core-trinus-auth/models';
import { TextInput } from '@components/form/form-inputs/text-input';
import { FormTextAreaInput } from '@components/form/form-inputs/textarea-input';
import { SelectList } from '@components/form/select-list';
import { useForm } from '@hooks/useForm';

interface AppFormProps {
  entityData?: Models.AuthEntityData;
  onUpdate?: (entityData: Models.AuthEntityData) => void;
  errorForms?: { [key: string]: string };
  onErrorChange?: (value: string, field: string) => void;
}

export const AppForm: FC<AppFormProps> = ({
  entityData,
  onUpdate,
  errorForms,
  onErrorChange,
}) => {
  const [disableEntityStatusType, setDisableEntityStatusType] =
    useState<boolean>(true);
  const [entityStatusType, setEntityStatusType] = useState<string>('Ativo');
  const { onChangeTextInputValue } = useForm({ ...entityData }, onUpdate);

  const entityDataStatusType =
    entityData?.statusType === 'INA' ? 'Inativo' : 'Ativo';

  useEffect(() => {
    if (entityData?.entityId) {
      setEntityStatusType(entityDataStatusType);
      setDisableEntityStatusType(false);
    } else {
      setDisableEntityStatusType(true);
    }
  }, [entityData?.entityId]);

  const handleEntityStatus = (e: ChangeEvent<HTMLSelectElement>) => {
    setEntityStatusType(e.target.value);
    const statusType = e.target.value === 'Inativo' ? 'INA' : 'ACT';
    onUpdate({ ...entityData, statusType });
  };

  return (
    <Container>
      <TextInput
        value={entityData?.fullName}
        dataTest={`application-full-name-input`}
        label="Razão Social"
        type="text"
        required={true}
        onChange={(e) => onChangeTextInputValue(e, 'fullName')}
        errorMessage={errorForms?.fullName}
        minLength={3}
        maxLength={300}
        onErrorChange={(value) => onErrorChange(value, 'fullName')}
      />
      <TextInput
        value={entityData?.emailDescription}
        dataTest={`application-email-input`}
        label="E-mail"
        type="email"
        onChange={(e) => onChangeTextInputValue(e, 'emailDescription')}
        errorMessage={errorForms?.emailDescription}
        maxLength={256}
        onErrorChange={(value) => onErrorChange(value, 'emailDescription')}
      />
      <FormTextAreaInput
        value={entityData?.detailDescription}
        dataTest={`application-description-input`}
        label="Informações adicionais"
        onChange={(e: any) => onChangeTextInputValue(e, 'detailDescription')}
        height={'10rem'}
        maxLength={2000}
      />
      <SelectList
        width={'30%'}
        dataTest={`application-status-Select`}
        label={'Status'}
        selectOptions={['Ativo', 'Inativo']}
        onChange={(e) => handleEntityStatus(e)}
        selectValue={entityStatusType}
        disabled={disableEntityStatusType}
      />
    </Container>
  );
};
