import { FC, HTMLAttributes, useEffect, useState } from 'react';
import { SessionFormDefault } from '@components/form/session-form';
import { TextInput } from '@components/form/form-inputs/text-input';
import { FlexWrapFormContainer } from '@components/form/styles';
import { Models } from '@services/ms-core-trinus-pay/models';
import { SelectList } from '@components/form/select-list';
import Library from '@utils/Library';
import { useFormContext } from '@context/form-context/FormContext';

interface InternalAccountBasicDataSessionProps
  extends HTMLAttributes<HTMLDivElement> {
  internalBankAccountData: Models.InternalBankAccountData;
  width?: string;
  height?: string;
  errorForms?: { [key: string]: string };
  onErrorChange?: (value: string, field: string) => void;
}

export const InternalAccountBasicDataSession: FC<
  InternalAccountBasicDataSessionProps
> = ({
  internalBankAccountData,
  width,
  height,
  errorForms,
  onErrorChange,
  ...props
}) => {
  const {
    onChangeTextInputValue,
    onChangeMainRegistration,
    onChangeStateType,
    onChangeZipCode,
    errorMessageInputMainRegistration,
    formatZipCode,
    errorMessageInputZipCode,
    handlePersonType,
    handleStatusType,
    personType,
    statusType,
    handlePersonTypeChangeSelect,
    handleStatusTypeChangeSelect,
    disableStatusType,
  } = useFormContext();

  const [personTypeOptions, setPersonTypeOptions] = useState<string[]>([]);

  const determinePersonType = (mainRegId: string) => {
    if (!mainRegId) {
      return null;
    }
    if (Library.validateCNPJ(mainRegId)) {
      return 'Jurídica';
    }
    if (Library.validateCPF(mainRegId)) {
      return 'Física';
    }
    return null;
  };

  const optionsPersonType = () => {
    const mainRegId = internalBankAccountData?.interBkBenefMainRegId;
    const specificType = determinePersonType(mainRegId);
    if (specificType) {
      setPersonTypeOptions([specificType]);
      handlePersonTypeChangeSelect(specificType, 'interBkBenefPersonType');
    } else {
      setPersonTypeOptions(['Jurídica', 'Física']);
    }
  };

  useEffect(() => {
    if (internalBankAccountData) {
      handlePersonType('interBkBenefPersonType');
      handleStatusType('statusType');
    }
  }, [internalBankAccountData]);

  useEffect(() => {
    optionsPersonType();
  }, [internalBankAccountData?.interBkBenefMainRegId]);

  return (
    <SessionFormDefault subTitle="Dados básicos" width="100%" {...props}>
      <TextInput
        dataTest={`internal-bank-benef-main-registration-input`}
        type="string"
        maxLength={50}
        value={internalBankAccountData?.interBkBenefMainRegId ?? ''}
        label="CPF/CNPJ"
        onChange={(e) => onChangeMainRegistration(e, 'interBkBenefMainRegId')}
        errorMessage={
          errorMessageInputMainRegistration || errorForms?.interBkBenefMainRegId
        }
        required
        onErrorChange={(value) =>
          onErrorChange?.(value, 'interBkBenefMainRegId')
        }
      />
      <TextInput
        dataTest={`internal-bank-benef-name-input`}
        type="text"
        value={internalBankAccountData?.interBkBenefFullName ?? ''}
        maxLength={100}
        label="Nome"
        required
        onChange={(e) => {
          onChangeTextInputValue(e, 'interBkBenefFullName');
        }}
        errorMessage={errorForms?.interBkBenefFullName}
        onErrorChange={(value) =>
          onErrorChange?.(value, 'interBkBenefFullName')
        }
      />
      <SelectList
        dataTest={`internal-bank-benef-person-type-select`}
        selectValue={personType}
        label="Tipo de Pessoa"
        width="100%"
        selectOptions={personTypeOptions}
        onChange={(e) => {
          handlePersonTypeChangeSelect(
            e.target.value,
            'interBkBenefPersonType'
          );
        }}
        required
      />
      <TextInput
        dataTest={`internal-bank-benef-address-input`}
        type="text"
        value={internalBankAccountData?.interBkBenAddrDescription ?? ''}
        maxLength={100}
        label="Endereço"
        onChange={(e) => onChangeTextInputValue(e, 'interBkBenAddrDescription')}
        required
        errorMessage={errorForms?.interBkBenAddrDescription}
        onErrorChange={(value) =>
          onErrorChange?.(value, 'interBkBenAddrDescription')
        }
      />
      <TextInput
        dataTest={`internal-bank-benef-neighborhood-input`}
        type="text"
        value={internalBankAccountData?.interBkBenefNeighbName ?? ''}
        maxLength={100}
        label="Bairro"
        onChange={(e) => onChangeTextInputValue(e, 'interBkBenefNeighbName')}
        required
        errorMessage={errorForms?.interBkBenefNeighbName}
        onErrorChange={(value) =>
          onErrorChange?.(value, 'interBkBenefNeighbName')
        }
      />
      <FlexWrapFormContainer $justifyContent="space-between">
        <TextInput
          dataTest={`internal-bank-benef-city-input`}
          type="text"
          label="Cidade"
          maxLength={100}
          value={internalBankAccountData?.interBkBenefCityName ?? ''}
          width="50%"
          onChange={(e) => onChangeTextInputValue(e, 'interBkBenefCityName')}
          required
          errorMessage={errorForms?.interBkBenefCityName}
          onErrorChange={(value) =>
            onErrorChange?.(value, 'interBkBenefCityName')
          }
        />
        <TextInput
          dataTest={`internal-bank-benef-federation-state-input`}
          type="text"
          label="Estado"
          maxLength={2}
          value={internalBankAccountData?.interBkBenefStateType ?? ''}
          width="50%"
          onChange={(e) => onChangeStateType(e, 'interBkBenefStateType')}
          required
          errorMessage={errorForms?.interBkBenefStateType}
          onErrorChange={(value) =>
            onErrorChange?.(value, 'interBkBenefStateType')
          }
        />
        <TextInput
          dataTest={`internal-bank-benef-postal-code-input`}
          type="text"
          label="CEP"
          maxLength={9}
          value={formatZipCode('interBkBenefZipCode') ?? ''}
          width="50%"
          onChange={(e) => onChangeZipCode(e, 'interBkBenefZipCode')}
          errorMessage={
            errorMessageInputZipCode || errorForms?.interBkBenefZipCode
          }
          required
          onErrorChange={(value) =>
            onErrorChange?.(value, 'interBkBenefZipCode')
          }
        />
        <SelectList
          dataTest={`internal-bank-benef-status-type-select`}
          selectValue={statusType}
          label="Status"
          width="100%"
          selectOptions={['Ativo', 'Inativo']}
          onChange={(e) => handleStatusTypeChangeSelect(e, 'statusType')}
          disabled={disableStatusType}
          required
        />
      </FlexWrapFormContainer>
    </SessionFormDefault>
  );
};
