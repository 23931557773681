import { AxiosResponse } from 'axios';
import Context from '@services/ms-core-trinus-pay/context';
import { Models } from '@services/ms-core-trinus-pay/models';
import Auth from '@services/ms-core-trinus-auth/controllers/auth';

class Entity {
  /* Sobre esta função */ /**
   * Buscar a lista de entidades vinculadas ao beneficiário
   * @param {number} finalBeneficiaryId Recebe o id do beneficiário a ser pesquisado
   **/
  public async findEntityByFinalBeneficiaryId(
    finalBeneficiaryId: number
  ): Promise<
    AxiosResponse<Models.FinalBeneficiaryEntities> &
      Models.MsCoreTrinusPayErrors
  > {
    const token = await Auth.getToken();
    if (!token) return;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return Context.get(
      `/entity/final-beneficiary/${finalBeneficiaryId}`,
      config
    )
      .then((Response) => {
        return Response;
      })
      .catch((Exception) => {
        return Exception;
      });
  }

  /* Sobre esta função */ /**
   * Vincular a entidade ao beneficiário final
   * @param {number} finalBeneficiaryId Recebe o id do beneficiário que será vinculado
   * @param {number} entityId Recebe o id da entidade que será vinculada
   **/
  public async linkEntityToFinalBeneficiary(
    finalBeneficiaryId: number,
    entityId: number
  ): Promise<AxiosResponse & Models.MsCoreTrinusPayErrors> {
    const token = await Auth.getToken();
    if (!token) return;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return Context.post(
      `/entity/${entityId}/final-beneficiary/${finalBeneficiaryId}`,
      {},
      config
    )
      .then((Response) => {
        return Response;
      })
      .catch((Exception) => {
        return Exception;
      });
  }

  /* Sobre esta função */ /**
   * Remover vinculo entre entidade e beneficiário final
   * @param {number} finalBeneficiaryId Recebe o id do beneficiário que será desvinculado
   * @param {number} entityId Recebe o id da entidade que será desvinculado
   **/
  public async RemoveLinkOfEntityEndFinalBeneficiary(
    finalBeneficiaryId: number,
    entityId: number
  ): Promise<AxiosResponse & Models.MsCoreTrinusPayErrors> {
    const token = await Auth.getToken();
    if (!token) return;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return Context.delete(
      `/entity/${entityId}/final-beneficiary/${finalBeneficiaryId}`,
      config
    )
      .then((Response) => {
        return Response;
      })
      .catch((Exception) => {
        return Exception;
      });
  }
}

export default new Entity();
