import { FC, useContext } from 'react';
import { Container } from './styles';
import AlertContext, { AlertStatus } from '@context/alert-context/alertContext';
import { LiaTimesSolid } from 'react-icons/lia';

interface GlobalAlertProps {
  width?: string | number;
  height?: string | number;
  top?: string | number;
  left?: string | number;
  backgroundColor?: string;
  color?: string;
  iconSize?: string | number;
  $showAlert?: boolean;
}

export const GlobalAlert: FC<GlobalAlertProps> = ({
  width,
  height,
  backgroundColor,
  color,
  iconSize,
  $showAlert,
}) => {
  const alert = useContext(AlertContext);

  if (alert.alert === AlertStatus.Success) {
    backgroundColor = '#c0da5a';
  }

  if (alert.alert === AlertStatus.Error) {
    backgroundColor = '#f0464c';
  }

  if (alert.alert === AlertStatus.Warning) {
    backgroundColor = '#FF6D2D';
  }

  if (alert.alert === AlertStatus.Info) {
    backgroundColor = '#d9d9d9';
  }

  return (
    <Container
      width={width}
      height={height}
      $backgroundColor={backgroundColor}
      color={color}
      $showAlert={$showAlert}
    >
      <p data-testid='alert-message'>{alert.alertText}</p>
      <LiaTimesSolid
        data-testid={'close-alert'}
        onClick={() => alert.clear()}
        fill={color ?? '#fff'}
        title='Fechar'
        size={iconSize ?? '15px'}
        style={{ backgroundColor: backgroundColor ?? '#d9d9d9' }}
      />
    </Container>
  );
};
