import { FC } from 'react';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';

export const Loading: FC<FontAwesomeIconProps> = ({
  color,
  icon,
  size,
  ...props
}) => {
  return (
    <FontAwesomeIcon color={color} icon={icon} size={size} {...props}/>
  )
}