import { FC, HTMLAttributes } from 'react';
import { DefaultFieldFormsContainer, DefaultSubTitle } from '../styles';

interface SessionFormDefaultProps extends HTMLAttributes<HTMLDivElement> {
  width?: string;
  height?: string;
  subTitle: string;
  disable?: boolean;
  scrollable?: boolean;
}

export const SessionFormDefault: FC<SessionFormDefaultProps> = ({
  width,
  height = 'min-content',
  scrollable,
  subTitle,
  disable,
  children,
  ...props
}) => {
  return (
    <DefaultFieldFormsContainer
      width={width}
      height={height}
      {...props}
      style={disable && { display: 'none' }}
    >
      <DefaultSubTitle>{subTitle}</DefaultSubTitle>
      {children}
    </DefaultFieldFormsContainer>
  );
};
