import styled from 'styled-components';

export const ContainerRelative = styled.div`
  position: relative;
  width: 100%;
`;

export const ContainerAbsolute = styled.div`
  position: absolute;
  width: 100%;
`;
