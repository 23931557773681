import styled, { css } from 'styled-components';

interface ContainerProps {
  $display: boolean;
  $width?: string;
  $height?: string;
  $left?: string;
  $displayStyle?: string;
  $flexDirection?: string;
  $justifyContent?: string;
  $alignItems?: string;
}

export const Container = styled.div<ContainerProps>`
  ${({
    $display,
    $displayStyle,
    $flexDirection,
    $justifyContent,
    $alignItems,
    }) => css`
    visibility: ${$display ? 'visible' : 'hidden'};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    box-sizing: border-box;
    opacity: ${$display ? 1 : 0};
    transition: ease-in-out 0.3s;
    display: ${$displayStyle ?? 'flex'};
    flex-direction: ${$flexDirection ?? 'column'};
    justify-content: ${$justifyContent ?? 'center'};
    align-items: ${$alignItems ?? 'center'};
    z-index: 2;
  `};
`;

export const ModalContainer = styled.div<ContainerProps>`
  ${({
    $display,
    $width,
    $height,
    $left,
    $displayStyle,
    $flexDirection,
    $justifyContent,
    $alignItems,
  }) => css`
    position: relative;
    display: ${$displayStyle ?? 'flex'};
    flex-direction: ${$flexDirection ?? 'column'};
    justify-content: ${$justifyContent ?? 'center'};
    align-items: ${$alignItems ?? 'center'};
    width: ${$width ?? '85vw'};
    height: ${$height ?? '100vh'};
    left: ${$left ?? 0};
    border: none;
    background-color: rgba(255, 255, 255, 1);
    opacity: ${$display ? 1 : 0};
    transition: ease-in-out 0.3s;

    > span {
      color: #0b5dd7;
      padding-top: 0.5rem;
      font-size: 0.7rem;
      text-align: center;
    }
  `}
`;
