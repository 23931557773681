import { FC, HTMLAttributes, useEffect } from 'react';
import { SessionFormDefault } from '@components/form/session-form';
import { TextInput } from '@components/form/form-inputs/text-input';
import { SelectList } from '@components/form/select-list';
import { FlexWrapFormContainer } from '@components/form/styles';
import { Models } from '@services/ms-core-trinus-pay/models';
import { useFormContext } from '@context/form-context/FormContext';

interface FinalBeneficiaryAccountSessionProps
  extends HTMLAttributes<HTMLDivElement> {
  finalBeneficiaryData: Models.FinalBeneficiaryData;
  width?: string;
  height?: string;
  required?: boolean;
  errorForms?: { [key: string]: string };
}

export const FinalBeneficiaryAccountSession: FC<
  FinalBeneficiaryAccountSessionProps
> = ({
  width = '100%',
  height,
  finalBeneficiaryData,
  required,
  errorForms,
  ...props
}) => {
  const {
    onChangeTextInputValue,
    handleAccountTypeChangeSelect,
    handleAccountType,
    accountType,
    handleChangeOnlyNumbers,
    handleChangeWithoutSpecialCharacters,
  } = useFormContext();

  useEffect(() => {
    if (finalBeneficiaryData) {
      handleAccountType('coreBankingAccountType');
    }
  }, [finalBeneficiaryData, handleAccountType]);

  return (
    <SessionFormDefault
      subTitle="Dados do beneficiário no Banco Trinus"
      width={width}
      {...props}
    >
      <FlexWrapFormContainer
        $justifyContent="space-between"
        $flexDirection="row"
      >
        <FlexWrapFormContainer $width="50%" $justifyContent="space-between">
          <TextInput
            dataTest={`final-beneficiary-core-banking-account-number-input`}
            type="text"
            value={finalBeneficiaryData?.coreBankingAccountNumber ?? ''}
            label="Número da conta"
            width="100%"
            onChange={(e) =>
              handleChangeOnlyNumbers(e, 'coreBankingAccountNumber')
            }
            maxLength={50}
          />
          <SelectList
            dataTest={`final-beneficiary-core-banking-account-type-select`}
            selectValue={accountType}
            label="Tipo de Conta"
            width="100%"
            selectOptions={['Conta Corrente', 'Conta Poupança']}
            onChange={(e) =>
              handleAccountTypeChangeSelect(e, 'coreBankingAccountType')
            }
          />
          <TextInput
            dataTest={`final-beneficiary-core-banking-account-id-input`}
            type="text"
            value={finalBeneficiaryData?.coreBankingAccountId ?? ''}
            label="Código de identificação da conta"
            width="100%"
            maxLength={50}
            disabled
          />
        </FlexWrapFormContainer>

        <FlexWrapFormContainer $width="50%" $justifyContent="space-between">
          <TextInput
            dataTest={`final-beneficiary-core-banking-branch-code-input`}
            type="text"
            value={finalBeneficiaryData?.coreBankingBranchCode ?? ''}
            label="Agência"
            width="100%"
            onChange={(e) =>
              handleChangeWithoutSpecialCharacters(e, 'coreBankingBranchCode')
            }
            maxLength={50}
          />
          <TextInput
            dataTest={`final-beneficiary-core-banking-bank-code-input`}
            type="text"
            value={finalBeneficiaryData?.coreBankingBankCode ?? ''}
            maxLength={50}
            label="Código do Banco"
            width="100%"
            onChange={(e) => handleChangeOnlyNumbers(e, 'coreBankingBankCode')}
          />
          <TextInput
            dataTest={`final-beneficiary-core-banking-clearing-code-input`}
            type="Text"
            value={finalBeneficiaryData?.coreBankingClearingCode ?? ''}
            label="Código de compensação"
            width="100%"
            onChange={(e) =>
              onChangeTextInputValue(e, 'coreBankingClearingCode')
            }
            maxLength={50}
          />
        </FlexWrapFormContainer>
      </FlexWrapFormContainer>
    </SessionFormDefault>
  );
};
