import React, { useState, useEffect } from 'react';
import { InfoIcon, TooltipContainer, TooltipText } from './styles';
type ValidSize = `${number}${'px' | 'em' | 'rem' | '%' | 'vw' | 'vh'}`;

interface InfoTooltipProps {
  message: string;
  dataTestId?: string;
  showInfo?: boolean;
  direction?: 'top' | 'bottom' | 'left' | 'right';
  infoSize?: ValidSize;
}

const InfoTooltip: React.FC<InfoTooltipProps> = ({
  message,
  dataTestId,
  showInfo,
  direction = 'top',
  infoSize = '0.8rem',
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [adjustedDirection, setAdjustedDirection] = useState(direction);

  const handleMouse = () => {
    setIsVisible((prevState) => !prevState);
  };

  useEffect(() => {
    const tooltipElement = document.getElementById('tooltip');

    if (tooltipElement) {
      const tooltipRect = tooltipElement.getBoundingClientRect();
      const bodyRect = document.body.getBoundingClientRect();

      if (tooltipRect.right > bodyRect.right) {
        setAdjustedDirection('left');
      } else if (tooltipRect.left < bodyRect.left) {
        setAdjustedDirection('right');
      } else if (tooltipRect.bottom > bodyRect.bottom) {
        setAdjustedDirection('top');
      }
    }
  }, [isVisible]);

  const getTooltipPosition = () => {
    const infoIcon = document.getElementById('infoIcon');
    const tooltip = document.getElementById('tooltip');

    if (infoIcon && tooltip) {
      const infoIconRect = infoIcon.getBoundingClientRect();
      const tooltipRect = tooltip.getBoundingClientRect();

      const styles = {
        bottom: {
          top: `calc(${infoIconRect?.height}px + 0.5rem)`,
          left: `${infoIconRect?.width / 2 - tooltipRect?.width / 2}px`,
        },
        left: {
          top: `${infoIconRect?.height / 2 - tooltipRect?.height / 2}px`,
          left: `calc(-${tooltipRect?.width}px - 0.5rem)`,
        },
        right: {
          top: `${infoIconRect?.height / 2 - tooltipRect?.height / 2}px`,
          left: `calc(${infoIconRect?.width}px + 0.5rem)`,
        },
        default: {
          top: `calc(-${tooltipRect?.height}px - 0.5rem)`,
          left: `${infoIconRect?.width / 2 - tooltipRect?.width / 2}px`,
        },
      };

      return styles[adjustedDirection] || styles.default;
    }

    return {};
  };

  return (
    <>
      {showInfo && (
        <TooltipContainer $infoSize={infoSize} data-testid={dataTestId}>
          <InfoIcon
            id="infoIcon"
            onMouseEnter={handleMouse}
            onMouseLeave={handleMouse}
            $infoSize={infoSize}
          >
            i
          </InfoIcon>
          <TooltipText
            id="tooltip"
            style={getTooltipPosition()}
            $isVisible={isVisible}
          >
            {message}
          </TooltipText>
        </TooltipContainer>
      )}
    </>
  );
};

export default InfoTooltip;
