import styled from 'styled-components';

export const CheckboxContainer = styled.input.attrs({ type: 'checkbox' })`
  position: relative;
  appearance: none;
  width: 1.5rem;
  min-width: 1.5rem;
  height: 1.5rem;
  border: 2px solid #d9d9d9;
  border-radius: 0.25rem;
  background-color: white;

  &:checked {
    background-color: #1a6ce8;
    border: 2px solid #d9d9d9;
  }

  &:disabled {
    background-color: #c6c6c6;
    border: 2px solid #ccc;
  }

  &:checked:after {
    content: '\\2714';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1.3);
    color: white;
  }

  &:hover {
    cursor: pointer;
  }
`;
