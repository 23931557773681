import { FinalBeneficiaryForm } from '@components/final-beneficiary-form';
import { FormProvider } from '@context/form-context/FormContext';

export const FinalBeneficiary = () => {
  return (
    <FormProvider
      initialFormData={{
        calcPayOffFeeBoolean: 'N',
        payOffFeeAmount: 0,

        calcRegistrationFeeBoolean: 'N',
        registrationFeeAmount: 0,

        calcCancellationFeeBoolean: 'N',
        cancellationFeeAmount: 0,

        calcMaintenanceFeeBoolean: 'N',
        maintenanceFeeAmount: 0,
        coreBankingAccountType: 'CACC',
        internalBankAccountId: undefined,
        beneficiaryName: '',
      }}
    >
      <FinalBeneficiaryForm />
    </FormProvider>
  );
};
