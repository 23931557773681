import { AxiosResponse } from 'axios';
import Context from '@services/ms-core-trinus-auth/context';
import { Models } from '@services/ms-core-trinus-auth/models';
import Auth from '../auth';

class Grants {
  /* Sobre esta função */ /**
   * Buscar grants pelo id da entidade que a entidade logada tem acesso
   * @param {number} entityId Recebe o entityId da entidade a ser pesquisada
   **/
  public async findGrantListByEntity(
    entityId: number
  ): Promise<
    (AxiosResponse<Models.ListGrant> & Models.AuthErrors) | undefined
  > {
    const token = await Auth.getToken();
    if (!token) return;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return await Context.get(`/grant/entity/${entityId}/list`, config)
      .then((Response) => {
        return Response;
      })
      .catch((Exception) => {
        return Exception;
      });
  }

  /* Sobre esta função */ /**
   * Remover permissões que a entidade possui
   * @param {number} entityId Recebe o entityId da entidade a ser pesquisada
   * @param {string[]} keyList Recebe o keyList que são a lista das permissões que serão removidas
   **/
  public async revokeGrants(
    entityId: number,
    keyList: string[]
  ): Promise<
    (AxiosResponse<Models.ListGrant> & Models.AuthErrors) | undefined
  > {
    const token = await Auth.getToken();
    if (!token) return;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return await Context.post(
      `/grant/entity/${entityId}/revoke`,
      { keyList },
      config
    )
      .then((Response) => {
        return Response;
      })
      .catch((Exception) => {
        return Exception;
      });
  }

  /* Sobre esta função */ /**
   * Adicionar permissões para a entidade
   * @param {number} entityId Recebe o entityId da entidade a ser pesquisada
   * @param {string[]} keyList Recebe o keyList que são a lista das permissões que serão adicionadas
   **/
  public async allowGrants(
    entityId: number,
    keyList: string[]
  ): Promise<
    (AxiosResponse<Models.ListGrant> & Models.AuthErrors) | undefined
  > {
    const token = await Auth.getToken();
    if (!token) return;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return await Context.post(
      `/grant/entity/${entityId}/allow`,
      { keyList },
      config
    )
      .then((Response) => {
        return Response;
      })
      .catch((Exception) => {
        return Exception;
      });
  }
}

export default new Grants();
