import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const ModalWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  animation-duration: 0.5s;

  &.fade-in {
    animation-name: ${fadeIn};
  }

  &.fade-out {
    animation-name: ${fadeOut};
  }
`;

export const CloseIcon = styled.button`
  width: 30px;
  height: 30px;
  background-color: transparent;
  box-sizing: border-box;
  outline: none;
  border: none;
  position: absolute;
  top: 0px;
  right: 0px;

  cursor: pointer;

  svg {
    &:hover {
      cursor: pointer;
    }
  }
`;

export const ChildWrapper = styled.div`
  box-sizing: border-box;
`;
