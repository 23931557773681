import { FC, ChangeEvent } from 'react';
import { CheckboxContainer } from './styles';

interface CheckboxProps {
  dataTestId?: string;
  checked?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  disabled?: boolean;
}

export const Checkbox: FC<CheckboxProps> = ({
  dataTestId,
  checked,
  onChange,
  required,
  disabled,
}) => {
  return (
    <CheckboxContainer
      data-testid={dataTestId}
      type="checkbox"
      checked={checked}
      onChange={onChange}
      required={required}
      disabled={disabled}
    />
  );
};
