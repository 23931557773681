import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useAuth } from '../context/auth-context/AuthContext';
import { AlertProvider } from '../context/alert-context/alertContext';
import { RoutesManager } from '../routes/RoutesManager';
import { LoadingProvider } from '../context/loading-context/loadingContext';

const Banking: React.FC = () => {
  const { updatePermissions } = useAuth();
  const { token } = JSON.parse(sessionStorage.getItem('entitySession'));

  useEffect(() => {
    updatePermissions(token);
  }, [token]);

  return (
    <AlertProvider>
      <LoadingProvider>
        <Router>
          <RoutesManager />
        </Router>
      </LoadingProvider>
    </AlertProvider>
  );
};

export default Banking;
