import { FC, ChangeEvent, MouseEvent } from 'react';
import { Checkbox } from '@components/form/form-inputs/check-box';
import { Container, ItemContainer, OptionItemContainer } from './styles';

interface OptionItemProps {
  modalHasCheckBoxes: boolean;
  optionData: any;
  optionsKeys?: string[];
  checkBoxChecked?: boolean;
  onCheckBoxChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onClick?: (e: MouseEvent<HTMLInputElement>) => void;
  requiredCheckBox?: boolean;
}

export const OptionItem: FC<OptionItemProps> = ({
  modalHasCheckBoxes,
  optionData,
  optionsKeys,
  checkBoxChecked,
  onCheckBoxChange,
  onClick,
  requiredCheckBox,
}) => {
  return (
    <Container data-testid="option-item-container" onClick={onClick}>
      {!modalHasCheckBoxes ? (
        <></>
      ) : (
        <Checkbox
          dataTestId="option-checkbox"
          checked={checkBoxChecked}
          onChange={onCheckBoxChange}
          required={requiredCheckBox}
        />
      )}
      <OptionItemContainer>
        {optionsKeys?.length ? (
          optionsKeys.map((optionKey: string) => (
            <ItemContainer key={optionKey}>
              <label>{optionKey}:</label>
              <span>{optionData[optionKey]}</span>
            </ItemContainer>
          ))
        ) : (
          <span>{optionData}</span>
        )}
      </OptionItemContainer>
    </Container>
  );
};
