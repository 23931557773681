import { FC, useEffect, useState, useContext } from 'react';
import Grants from '@services/ms-core-trinus-auth/controllers/grants';
import {
  OptionItem,
  OptionsList,
  Label,
  AppTitle,
  Container,
  GrantList,
  HeaderWrapper,
  ButtonsWrapper,
  SimpleButton,
} from './styles';
import { RiArrowDownSFill, RiArrowUpSFill } from 'react-icons/ri';
import { grantsDescriptions } from './grantsDescriptions';
import DefaultButton from '@components/default-button';
import AlertContext from '@context/alert-context/alertContext';
import { ThemeName } from '@components/default-button/styles';
import { LiaTimesSolid } from 'react-icons/lia';
import LoadingContext from '@context/loading-context/loadingContext';
import { Checkbox } from '@components/form/form-inputs/check-box';

interface EntityGrantsProps {
  entityId: number;
  onClose?: () => void;
}

interface GroupedOptions {
  [appName: string]: {
    keyList?: {
      key: string;
      isChecked: boolean;
      isDisabled: boolean;
      keyDescription: string;
    }[];
  };
}

const EntityGrants: FC<EntityGrantsProps> = ({ entityId, onClose }) => {
  const alert = useContext(AlertContext);
  const loading = useContext(LoadingContext);

  const [groupedOptions, setGroupedOptions] = useState<GroupedOptions>({});
  const [InitialGroupedOptions, setInitialGroupedOptions] =
    useState<GroupedOptions>({});
  const [minimized, setMinimized] = useState<{ [appName: string]: boolean }>(
    {}
  );
  const [revokeGrants, setRevokeGrants] = useState<string[]>([]);
  const [allowGrants, setAllowGrants] = useState<string[]>([]);
  const [disabledSave, setDisabledSave] = useState<boolean>(true);

  const getGrantList = async () => {
    loading.startLoading(true);

    if (!entityId) {
      return;
    }

    const result = await Grants.findGrantListByEntity(entityId);

    if (result?.status === 200) {
      const grantList = result.data;
      const auxGroupedOptions: GroupedOptions = {};

      grantList?.availableGrants?.forEach((grant) => {
        const appName = grant.fullName;
        const keyList = grant.keyList.map((key) => {
          return {
            key: key,
            isChecked: false,
            isDisabled: false,
            keyDescription: grantsDescriptions[key] ?? key,
          };
        });

        auxGroupedOptions[appName] = {
          keyList,
        };
      });

      grantList?.activeGrants?.forEach((grant) => {
        const appName = grant.fullName;
        const keyList = grant.keyList.map((key) => {
          return {
            key: key,
            isChecked: true,
            isDisabled: !grant.isRevoked,
            keyDescription: grantsDescriptions[key] ?? key,
          };
        });

        if (auxGroupedOptions[appName]?.keyList?.length > 0) {
          auxGroupedOptions[appName].keyList.push(...keyList);
        } else {
          auxGroupedOptions[appName] = {
            keyList,
          };
        }
      });

      Object.keys(auxGroupedOptions).forEach((appName) => {
        auxGroupedOptions[appName].keyList = auxGroupedOptions[
          appName
        ].keyList.sort((a, b) => a.key.localeCompare(b.key));
      });

      setGroupedOptions(auxGroupedOptions);
      setInitialGroupedOptions(JSON.parse(JSON.stringify(auxGroupedOptions)));
      loading.startLoading(false);
    }
  };

  const handleToggleMinimized = (
    appName: string,
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    setMinimized((prevState) => ({
      ...prevState,
      [appName]: !prevState[appName],
    }));
  };

  const handleCheckboxChange = (
    appName: string,
    keyListIndex: number,
    key: string
  ) => {
    const updatedOptions = { ...groupedOptions };

    updatedOptions[appName].keyList[keyListIndex].isChecked =
      !updatedOptions[appName].keyList[keyListIndex].isChecked;

    if (
      InitialGroupedOptions[appName].keyList[keyListIndex].isChecked !==
      updatedOptions[appName].keyList[keyListIndex].isChecked
    ) {
      if (updatedOptions[appName].keyList[keyListIndex].isChecked) {
        setAllowGrants((prevGrants) => [...prevGrants, key]);
      } else {
        setRevokeGrants((prevGrants) => [...prevGrants, key]);
      }
    } else if (updatedOptions[appName].keyList[keyListIndex].isChecked) {
      setRevokeGrants((prevGrants) =>
        prevGrants.filter((item) => item !== key)
      );
    } else {
      setAllowGrants((prevGrants) => prevGrants.filter((item) => item !== key));
    }

    setGroupedOptions(updatedOptions);
  };

  const handleSubmit = async () => {
    loading.startLoading(true);
    if (revokeGrants.length > 0) {
      const response = await Grants.revokeGrants(entityId, revokeGrants);
      if (response?.status === 200) {
        loading.startLoading(false);
        alert.success('Permissões removidas com sucesso!');
      } else {
        loading.startLoading(false);
        alert.error(response?.response?.data?.message || response?.message);
      }
    }

    if (allowGrants.length > 0) {
      const response = await Grants.allowGrants(entityId, allowGrants);
      if (response?.status === 201) {
        loading.startLoading(false);
        alert.success('Permissões salvas com sucesso!');
      } else {
        loading.startLoading(false);
        alert.error(response?.response?.data?.message || response?.message);
      }
    }

    setRevokeGrants([]);
    setAllowGrants([]);
    setInitialGroupedOptions(JSON.parse(JSON.stringify(groupedOptions)));
  };

  const capitalizeWords = (textToFormat: string): string => {
    const words = textToFormat.toLowerCase().split(' ');
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }
    return words.join(' ');
  };

  useEffect(() => {
    getGrantList();
  }, [entityId]);

  useEffect(() => {
    if (revokeGrants.length > 0 || allowGrants.length > 0) {
      setDisabledSave(false);
    } else {
      setDisabledSave(true);
    }
  }, [revokeGrants, allowGrants]);

  return (
    <Container>
      <HeaderWrapper>
        <Label>Permissões</Label>
        <ButtonsWrapper>
          <DefaultButton
            dataTest={`save-grant-edit`}
            theme={ThemeName.PRIMARY_REGULAR}
            disabled={disabledSave}
            width="auto"
            label="Salvar"
            title="Salvar"
            onClick={handleSubmit}
          />

          <SimpleButton
            data-testid={`close-grant-editing-modal`}
            onClick={() => {
              onClose?.();
            }}
          >
            <LiaTimesSolid
              fill="#3D4975"
              title="Cancelar"
              cursor={'pointer'}
              size={20}
            />
          </SimpleButton>
        </ButtonsWrapper>
      </HeaderWrapper>
      <GrantList>
        {Object.keys(groupedOptions).map((appName) => (
          <div key={appName}>
            <SimpleButton
              data-testid={`minimize-grant-list-${appName}`}
              onClick={(e) => handleToggleMinimized(appName, e)}
            >
              <AppTitle>
                {capitalizeWords(appName)}
                {minimized[appName] ? <RiArrowDownSFill /> : <RiArrowUpSFill />}
              </AppTitle>
            </SimpleButton>
            {!minimized[appName] && (
              <OptionsList>
                {groupedOptions[appName].keyList.map((option, index) => (
                  <OptionItem key={option.key}>
                    <Checkbox
                      dataTestId={`handle-enabling-or-disabling-grants-${option.key}`}
                      checked={option.isChecked}
                      onChange={() =>
                        handleCheckboxChange(appName, index, option.key)
                      }
                      disabled={option.isDisabled}
                    />
                    {option.keyDescription}
                  </OptionItem>
                ))}
              </OptionsList>
            )}
          </div>
        ))}
      </GrantList>
    </Container>
  );
};

export default EntityGrants;
