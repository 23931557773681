import {
  FC,
  useState,
  KeyboardEvent,
  forwardRef,
  useImperativeHandle,
  useEffect,
  ChangeEvent,
} from 'react';
import { InputArea } from './styles';
import { FaSearch, FaTimes } from 'react-icons/fa';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Loading } from '../../loading';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  valueToSearch: (value: string) => void;
  placeholder?: string;
  searchOnAction?: boolean;
  isFocused?: boolean;
  isValid?: boolean;
  selectedValue?: string;
  onRemoveValue?: () => void;
  maxLength?: number;
  minLength?: number;
  isLoading?: boolean;
  dataTest: string;
  onChange?: (value: any) => void;
  disableSearch?: boolean;
  value?: string;
  ref?: any;
}
export interface InputRef {
  clearInput: () => void;
}

export const Input: FC<InputProps> = forwardRef<InputRef, InputProps>(
  (
    {
      valueToSearch,
      placeholder,
      searchOnAction = false,
      selectedValue,
      onRemoveValue,
      isFocused,
      isValid,
      maxLength,
      dataTest,
      minLength = 0,
      isLoading,
      onChange,
      disableSearch,
      value = '',
      ...props
    },
    ref
  ) => {
    const [inputValue, setInputValue] = useState('');

    useImperativeHandle(ref, () => ({
      clearInput: () => {
        setInputValue('');
      },
    }));

    useEffect(() => {
      setInputValue(value);
    }, [value]);

    const handleSearch = (valueSearch: string) => {
      if (isLoading) return;

      if (valueSearch?.length >= minLength) {
        valueToSearch(valueSearch);
      } else {
        valueToSearch('');
      }
    };

    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
      const newInputValue = e.target.value;

      if (!searchOnAction) {
        handleSearch(newInputValue);
      }

      if (onChange) {
        onChange(e);
      } else {
        setInputValue(newInputValue);
      }
    };

    const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
      if (searchOnAction && e.key === 'Enter') {
        handleSearch(inputValue);
      }
    };

    const handleRemoveValue = () => {
      onRemoveValue?.();

      if (value) {
        setInputValue(value);
      } else {
        setInputValue('');
      }
    };

    const renderInputIcon = () => {
      if (isLoading) {
        return <Loading color="#0b5dd7" icon={faSpinner} spin size="sm" />;
      } else if (selectedValue) {
        return (
          <FaTimes
            data-testid={`remove-${dataTest}`}
            cursor="pointer"
            onClick={handleRemoveValue}
            color={'#1a6ce8'}
          />
        );
      } else {
        const able =
          searchOnAction && !disableSearch && inputValue?.length >= minLength;
        return (
          <FaSearch
            onClick={() => able && handleSearch(inputValue)}
            data-testid={
              searchOnAction ? `search-by-click-${dataTest}` : undefined
            }
            cursor={able ? 'pointer' : 'default'}
            color={!searchOnAction || able ? '#1a6ce8' : '#ededed'}
          />
        );
      }
    };

    return (
      <>
        <InputArea
          data-testid={`input-${dataTest}`}
          value={selectedValue || inputValue}
          onChange={(e) => handleOnChange(e)}
          onKeyDown={handleKeyPress}
          placeholder={placeholder}
          disabled={!!selectedValue || (isLoading && searchOnAction)}
          maxLength={maxLength}
          minLength={minLength}
          {...props}
        />
        {renderInputIcon()}
      </>
    );
  }
);
