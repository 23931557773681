import { Application } from '@pages/entity-page/application';
import { FinalBeneficiary } from '@pages/final-beneficiary';
import { InternalBankAccount } from '@pages/internal-bank-account';
import { User } from '@pages/entity-page/user';

interface RoutesProps {
  [key: string]: {
    route: string;
    component: React.ComponentType;
    key: string;
    grants: string[];
  };
}

export const routes: RoutesProps = {
  application: {
    route: '/app/banking/application',
    component: Application,
    key: 'application',
    grants: ['MAINTAIN_ANY'],
  },
  user: {
    route: '/app/banking/user',
    component: User,
    key: 'user',
    grants: ['MAINTAIN_ANY'],
  },
  final_beneficiary: {
    route: '/app/banking/final-beneficiary',
    component: FinalBeneficiary,
    key: 'final_beneficiary',
    grants: ['MAINTAIN', 'GET'],
  },
  internal_bank_account: {
    route: '/app/banking/internal-bank-account',
    component: InternalBankAccount,
    key: 'internal_bank_account',
    grants: ['MAINTAIN', 'GET'],
  },
};
