import { FC, FormHTMLAttributes, useContext, useEffect, useState } from 'react';
import { FormDefault } from '@components/form';
import InternalBankAccountService from '@services/ms-core-trinus-pay/controllers/internal-bank-account';
import { FormHeader } from '@components/form/form-header';
import AlertContext from '@context/alert-context/alertContext';
import { GridFormContainer } from '@components/form/styles';
import { Models } from '@services/ms-core-trinus-pay/models';
import Library from '@utils/Library';
import { TaxBankSlipSession } from '@components/form/session-form/tax-bank-slip-session';
import { AccountIdentificationSession } from '@components/internal-bank-account-form/account-identification-session';
import { InternalAccountBasicDataSession } from './account-owner-identification-session';
import LoadingContext from '@context/loading-context/loadingContext';
import { MdAccountBalance } from 'react-icons/md';
import { SearchInput } from '@components/input-search';
import { useFormContext } from '@context/form-context/FormContext';
import { ConfirmationModal } from '@components/modal/confirmation-modal';

interface InternalBankAccountFormProps
  extends FormHTMLAttributes<HTMLFormElement> {}

interface InternalBankAccountSearchInputProps {
  onClickInItem: (value: Models.InternalBankAccount) => void;
}

const InternalBankAccountSearchInput: FC<
  InternalBankAccountSearchInputProps
> = ({ onClickInItem }) => {
  const [searchResults, setSearchResults] =
    useState<Models.InternalBankAccount[]>(undefined);
  const [notFoundMsg, setNotFoundMsg] = useState<string>();
  const [isLoadingSearch, setIsLoadingSearch] = useState<boolean>(false);

  const getDisplayResult = (data: Models.InternalBankAccount) => {
    if (!data) {
      return;
    }

    return `N.º da conta: ${data?.accountNumberCode} AG: ${data?.bankBranchCode} - ${data?.interBKBenefFullName}`;
  };

  const handleInternalBankAccountSearch = async (value: string) => {
    if (!value) {
      setNotFoundMsg(undefined);
      setSearchResults(undefined);
      return;
    }
    setIsLoadingSearch(true);

    const result =
      await InternalBankAccountService.findInternalBankAccountByTerm(value);
    if (result?.status !== 200) {
      const msg = 'Nenhum resultado...';
      setNotFoundMsg(msg);
      setSearchResults(undefined);
      setIsLoadingSearch(false);
      return;
    }

    setNotFoundMsg(undefined);
    setSearchResults(result.data);
    setIsLoadingSearch(false);
  };

  const handleInternalBankAccountToEdit = (
    e: React.MouseEvent<HTMLButtonElement>,
    internalBankAccount: Models.InternalBankAccount
  ) => {
    e.preventDefault();
    onClickInItem(internalBankAccount);
  };

  const renderSVGIcon = () => {
    return <MdAccountBalance fill="#1a6ce8" />;
  };

  return (
    <SearchInput
      dataTest={`search-internal-bank-account-by-account-number`}
      itemIcon={renderSVGIcon()}
      searchValue={handleInternalBankAccountSearch}
      displayResult={getDisplayResult}
      searchResults={searchResults}
      notFoundMsg={notFoundMsg}
      resultToEdit={handleInternalBankAccountToEdit}
      isLoading={isLoadingSearch}
      maxLength={120}
    />
  );
};

export const InternalBankAccountForm: FC<InternalBankAccountFormProps> = ({
  ...props
}) => {
  const alert = useContext(AlertContext);
  const loading = useContext(LoadingContext);

  const {
    confirmModal,
    errorForms,
    formattedMainRegistration,
    formToEdit,
    handleActionWithConfirmation,
    initialFormData,
    isValidMainRegistrationId,
    loadValueWithCents,
    onErrorChange,
    savedForm,
    setConfirmModal,
    setErrorForms,
    setErrorMessageInputMainRegistration,
    setErrorMessageInputZipCode,
    setFormToEdit,
    setSavedForm,
    updateData,
    validateFeeFields,
    validateRequiredFields,
    validationRequiredFieldsForm,
  } = useFormContext();

  const [internalBankAccountData, setInternalBankAccountData] =
    useState<Models.InternalBankAccount>();

  const [disableSaveButton, setDisableSaveButton] = useState<boolean>(false);

  const [formInternalBankAccountTitle, setFormInternalBankAccountTitle] =
    useState<string>('');

  const emptyErrors = {
    statusType: '',
    accountNumberCode: '',
    accountVerifyingNumberCode: '',
    accountType: '',
    bankCode: '',
    bankBranchCode: '',
    regionType: '',
    interBkBenefCode: '',
    interBkBenefFullName: '',
    interBkBenefPersonType: '',
    interBkBenefMainRegId: '',
    interBkBenAddrDescription: '',
    interBkBenefNeighbName: '',
    interBkBenefCityName: '',
    interBkBenefStateType: '',
    interBkBenefZipCode: '',
    maintenanceFeeAmount: '',
    registrationFeeAmount: '',
    cancellationFeeAmount: '',
    payOffFeeAmount: '',
  };

  useEffect(() => {
    if (savedForm?.accountNumberCode) {
      setFormInternalBankAccountTitle(
        `Conta Interna N.º ${savedForm?.accountNumberCode}`
      );
    } else {
      setFormInternalBankAccountTitle('Cadastrando Conta Interna');
    }
  }, [savedForm]);

  useEffect(() => {
    if (internalBankAccountData?.internaBankAccountId > 0) {
      handleFindInternalBankAccountById(
        internalBankAccountData?.internaBankAccountId
      );
    }
  }, [internalBankAccountData?.internaBankAccountId]);

  const handleSendInternalBankAccountDataToSave = async () => {
    setDisableSaveButton(true);
    loading.startLoading(true);

    const result = await InternalBankAccountService.maintainInternalBankAccount(
      formToEdit
    );
    const status = result?.status;
    if (status === 201 || status === 200) {
      const message = savedForm?.internalBankAccountId
        ? `A conta interna foi editada.`
        : `A conta interna foi criada.`;
      updateInternalBankAccountData(result?.data);
      alert.success(message);
    } else {
      alert.error(result?.response.data.message || result?.message);
    }

    setDisableSaveButton(false);
    loading.startLoading(false);
  };

  const handleSelect = (data: Models.InternalBankAccount) => {
    handleActionWithConfirmation(() => setInternalBankAccountData(data));
  };

  const handleFormClean = () => {
    handleActionWithConfirmation(clearForm);
  };

  const handleFindInternalBankAccountById = async (
    internalBankAccountId: number
  ) => {
    clearForm();
    loading.startLoading(true);

    const foundInternalBankAccount =
      await InternalBankAccountService.findInternalBankAccountById(
        internalBankAccountId
      );

    if (foundInternalBankAccount?.status !== 200) {
      loading.startLoading(false);
      alert.error('Não foi possível achar a conta interna.');
      return;
    }

    loading.startLoading(false);
    updateInternalBankAccountData(foundInternalBankAccount.data);
  };

  const updateInternalBankAccountData = (
    data: Models.InternalBankAccountFindById | Models.InternalBankAccountData
  ) => {
    const chooseProperty = (
      nestedProp: any,
      directProp: any,
      defaultValue = undefined
    ) => {
      return nestedProp ?? directProp ?? defaultValue;
    };

    const dataToUpdate = {
      internalBankAccountId: data?.internalBankAccountId,
      statusType: data?.statusType,
      accountNumberCode: data?.accountNumberCode,
      accountVerifyingNumberCode: data?.accountVerifyingNumberCode,
      accountType: data?.accountType,
      bankCode: data?.bankCode,
      bankBranchCode: data?.bankBranchCode,
      bankWalletCode: data?.bankWalletCode,
      ispbCode: data?.ispbCode,
      regionType: data?.regionType,
      calcPayOffFeeBoolean: chooseProperty(data?.calcPayOffFeeBoolean, 'N'),
      payOffFeeAmount: loadValueWithCents(
        chooseProperty(data?.payOffFeeAmount, 0)
      ),
      calcRegistrationFeeBoolean: chooseProperty(
        data?.calcRegistrationFeeBoolean,
        'N'
      ),
      registrationFeeAmount: loadValueWithCents(
        chooseProperty(data?.registrationFeeAmount, 0)
      ),
      calcCancellationFeeBoolean: chooseProperty(
        data?.calcCancellationFeeBoolean,
        'N'
      ),
      cancellationFeeAmount: loadValueWithCents(
        chooseProperty(data?.cancellationFeeAmount, 0)
      ),
      calcMaintenanceFeeBoolean: chooseProperty(
        data?.calcMaintenanceFeeBoolean,
        'N'
      ),
      maintenanceFeeAmount: loadValueWithCents(
        chooseProperty(data?.maintenanceFeeAmount, 0)
      ),
      interBkBenefCode: data?.interBkBenefCode,
      interBkBenefFullName: data?.interBkBenefFullName,
      interBkBenefPersonType: data?.interBkBenefPersonType,
      interBkBenefMainRegId: formattedMainRegistration(
        data?.interBkBenefMainRegId
      ),
      interBkBenAddrDescription: chooseProperty(
        (data as Models.InternalBankAccountFindById)?.beneficiaryAddress
          ?.addressDescription,
        (data as Models.InternalBankAccountData)?.interBkBenAddrDescription
      ),
      interBkBenefNeighbName: chooseProperty(
        (data as Models.InternalBankAccountFindById)?.beneficiaryAddress
          ?.neighborhoodName,
        (data as Models.InternalBankAccountData)?.interBkBenefNeighbName
      ),
      interBkBenefCityName: chooseProperty(
        (data as Models.InternalBankAccountFindById)?.beneficiaryAddress
          ?.cityName,
        (data as Models.InternalBankAccountData)?.interBkBenefCityName
      ),
      interBkBenefStateType: chooseProperty(
        (data as Models.InternalBankAccountFindById)?.beneficiaryAddress
          ?.stateType,
        (data as Models.InternalBankAccountData)?.interBkBenefStateType
      ),
      interBkBenefZipCode: chooseProperty(
        (data as Models.InternalBankAccountFindById)?.beneficiaryAddress
          ?.zipCode,
        (data as Models.InternalBankAccountData)?.interBkBenefZipCode
      ),
    };

    updateData(dataToUpdate);
  };

  const validateInternalBankAccountData = () => {
    const booleanFields = [
      { field: 'calcPayOffFeeBoolean', associatedField: 'payOffFeeAmount' },
      {
        field: 'calcRegistrationFeeBoolean',
        associatedField: 'registrationFeeAmount',
      },
      {
        field: 'calcCancellationFeeBoolean',
        associatedField: 'cancellationFeeAmount',
      },
      {
        field: 'calcMaintenanceFeeBoolean',
        associatedField: 'maintenanceFeeAmount',
      },
    ];

    const requiredFields = [
      'statusType',
      'accountNumberCode',
      'accountVerifyingNumberCode',
      'accountType',
      'bankCode',
      'bankBranchCode',
      'regionType',
      'interBkBenefCode',
      'interBkBenefFullName',
      'interBkBenefPersonType',
      'interBkBenefMainRegId',
      'interBkBenAddrDescription',
      'interBkBenefNeighbName',
      'interBkBenefCityName',
      'interBkBenefStateType',
      'interBkBenefZipCode',
    ];

    const resultValidateRequiredFields = validationRequiredFieldsForm(
      { ...formToEdit },
      requiredFields,
      emptyErrors
    );

    const resultValidateFeeFields = validateFeeFields(
      { ...formToEdit },
      booleanFields
    );

    setErrorForms({
      ...resultValidateRequiredFields.formErrorsFound,
      ...resultValidateFeeFields.errors,
    });

    if (
      resultValidateRequiredFields.isValid &&
      resultValidateFeeFields.isValid &&
      isValidMainRegistrationId(formToEdit['interBkBenefMainRegId']) &&
      formToEdit['interBkBenefZipCode']?.length === 8 &&
      formToEdit['interBkBenefStateType']?.length === 2
    ) {
      handleSendInternalBankAccountDataToSave();
    } else {
      alert.error(
        'Não foi possível salvar. Por favor, revise o formulário e tente novamente.'
      );
    }
  };

  const clearForm = () => {
    setConfirmModal({ show: false });
    setSavedForm(undefined);
    setFormToEdit({ ...initialFormData });
    setErrorForms({ ...emptyErrors });
    setErrorMessageInputZipCode('');
    setErrorMessageInputMainRegistration('');
    setInternalBankAccountData(undefined);
    alert.clear();
  };

  return (
    <FormDefault {...props}>
      <FormHeader
        title={formInternalBankAccountTitle}
        disableButton={disableSaveButton}
        hasHeaderDefaultButtons={true}
        handleButton={validateInternalBankAccountData}
        handleCancel={handleFormClean}
      >
        <InternalBankAccountSearchInput onClickInItem={handleSelect} />
      </FormHeader>
      <GridFormContainer>
        <div style={{ width: '100%' }}>
          <AccountIdentificationSession
            internalBankAccountData={formToEdit}
            onUpdate={(data: Models.FinalBeneficiaryData) => {
              setFormToEdit(data);
            }}
            errorForms={errorForms}
            onErrorChange={onErrorChange}
          />
          <TaxBankSlipSession
            data={formToEdit}
            onUpdate={(data: Models.InternalBankAccountData) => {
              setFormToEdit(data);
            }}
            type="internal-bank-account"
            width="100%"
            errorForms={errorForms}
            onErrorChange={onErrorChange}
          />
        </div>
        <div>
          <InternalAccountBasicDataSession
            internalBankAccountData={formToEdit}
            errorForms={errorForms}
            onErrorChange={onErrorChange}
          />
        </div>
      </GridFormContainer>
      {confirmModal.show && (
        <ConfirmationModal
          displayModal={true}
          title={confirmModal?.title}
          message={confirmModal?.message}
          onConfirm={() => confirmModal?.confirmAction?.()}
          onCancel={() => {
            setConfirmModal({ show: false });
          }}
        />
      )}
    </FormDefault>
  );
};
