import { FC, useRef, useState } from 'react';
import { Container, SearchContainer } from './styles';
import { Input, InputRef } from './search-input';
import { SearchResults } from './search-results';
import {
  ContainerAbsolute,
  ContainerRelative,
} from '../form/form-inputs/input-selected/styles';

interface SearchInputProps {
  itemIcon?: React.ReactNode;
  customPlaceholder?: string;
  searchValue?: (value: string) => void;
  displayResult: (result: any) => any;
  searchResults?: any[];
  notFoundMsg: string;
  resultToEdit?: (e: React.MouseEvent<HTMLButtonElement>, data: any) => void;
  maxLength?: number;
  minLength?: number;
  isLoading?: boolean;
  dataTest: string;
}

export const SearchInput: FC<SearchInputProps> = ({
  itemIcon,
  resultToEdit,
  searchValue,
  displayResult,
  searchResults,
  notFoundMsg,
  customPlaceholder,
  maxLength,
  dataTest,
  isLoading,
  minLength,
}) => {
  const inputRef = useRef<InputRef>(null);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const handleSearchValue = (value: string) => {
    searchValue(value);
  };

  const handleResultToEdit = (
    e: React.MouseEvent<HTMLButtonElement>,
    result: any
  ) => {
    resultToEdit(e, result);
    searchValue('');
    inputRef.current.clearInput();
  };

  const handleCustomOnBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
    if (
      !e.relatedTarget ||
      !e.currentTarget.contains(e.relatedTarget as Node)
    ) {
      setIsFocused(false);
    }
  };

  return (
    <Container onFocus={() => setIsFocused(true)} onBlur={handleCustomOnBlur}>
      <SearchContainer>
        <Input
          ref={inputRef}
          valueToSearch={handleSearchValue}
          placeholder={customPlaceholder}
          maxLength={maxLength}
          minLength={minLength}
          isLoading={isLoading}
          dataTest={dataTest}
        />
      </SearchContainer>

      {isFocused && (
        <ContainerRelative>
          <ContainerAbsolute>
            <SearchResults
              results={searchResults}
              displayResult={displayResult}
              onClick={handleResultToEdit}
              itemIcon={itemIcon}
              notFoundMsg={notFoundMsg}
            />
          </ContainerAbsolute>
        </ContainerRelative>
      )}
    </Container>
  );
};
