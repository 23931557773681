import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 20vw;
`;

export const SearchContainer = styled.div`
  background-color: #fff;
  width: 20vw;
  border-radius: 0.5rem;
  height: 2rem;
  padding: 0 0.5rem;
  box-shadow: 0 0 0.5rem #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;

  > svg {
    width: 1rem;
    height: 1rem;
  }

  @media (max-width: 300px) {
    > svg {
      width: 100%;
    }
  }
`;
