import { AxiosError, AxiosResponse } from 'axios';
import Context from '@services/ms-core-trinus-auth/context';
import { Models } from '@services/ms-core-trinus-auth/models';
import jwtDecode from 'jwt-decode';

export enum EntityType {
  APPLICATION = 'APP',
  USER = 'USE',
}

class Auth {
  /* Sobre esta função */ /**
   * Obter um novo token valido a partir do refreshToken
   * @param {string} refreshToken Recebe o refresh token para obter um novo tokes valido
   * @param {string | undefined} loginOrigin Recebe a identificação da forma de login
   **/
  public async refreshToken(
    refreshToken: string,
    loginOrigin: string | undefined
  ): Promise<AxiosResponse<Models.AuthToken> & Models.AuthErrors> {
    let appOrigin = undefined;

    if (loginOrigin) {
      appOrigin = process.env.APP_ORIGIN_CORE_TRINUS;
    }

    const config = {
      headers: {
        'app-origin': appOrigin,
      },
    };

    return await Context.post('/auth/refresh', { refreshToken }, config)
      .then((Response) => {
        return Response;
      })
      .catch((Exception) => {
        return Exception;
      });
  }

  /* Sobre esta função */ /**
   * Obter um novo token valido a partir da seção atual
   **/
  public async getToken(): Promise<string | undefined> {
    const auth = new Auth();
    const { token, refreshToken, loginOrigin, lastAccess } = JSON.parse(
      sessionStorage.getItem('entitySession')
    );

    const isTokenExpired = (tokenJwt: string) => {
      try {
        const decodedToken: any = jwtDecode(tokenJwt);

        if (!decodedToken?.exp) {
          return true;
        }

        const currentTime = Date.now() / 1000;
        const shortly = currentTime + 10;
        return decodedToken.exp < shortly;
      } catch (error) {
        return true;
      }
    };

    if (token && !isTokenExpired(token)) {
      return token;
    } else if (refreshToken && !isTokenExpired(refreshToken)) {
      const resultAuth = await auth.refreshToken(refreshToken, loginOrigin);

      if (resultAuth.status !== 201 || !resultAuth.data) {
        return;
      } else {
        let result: any = resultAuth.data;
        result.loginOrigin = loginOrigin;
        result.lastAccess = lastAccess;

        const entitySessionUpdate = new CustomEvent('entitySessionUpdated', {
          detail: result,
        });
        window.dispatchEvent(entitySessionUpdate);

        return resultAuth.data.token;
      }
    } else {
      //TODO: tratar quando não for possível obter um novo token, como será para obter novo login, atualmente está fazendo logout
      const entitySessionRemoved = new CustomEvent('entitySessionRemoved');
      window.dispatchEvent(entitySessionRemoved);
    }
  }

  /* Sobre esta função */ /**
   * Cria ou gera novas credenciais para Users ou Applications previamente cadastradas no auth
   **/
  public async changeCrendentials(
    entityType: string,
    entityId: number,
    entityKey: string
  ): Promise<
    (AxiosResponse<Models.AuthCredentials> & Models.AuthErrors) | undefined
  > {
    const token = await this.getToken();
    if (!token) return;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let entityRoute: string = 'application';
    let data = {};

    if (entityType === EntityType.USER) {
      entityRoute = 'user';
      data = {
        key: entityKey,
      };
    }

    return await Context.patch(
      `/auth/${entityRoute}/${entityId}/change-credentials`,
      data,
      config
    )
      .then((Response) => Response)
      .catch((Exception) => Exception);
  }
}

export default new Auth();
