import { AuthProvider } from './context/auth-context/AuthContext';
import './index.css';
import Banking from './pages/App';

export default function Root() {
  return (
    <AuthProvider>
      <Banking />
    </AuthProvider>
  );
}
