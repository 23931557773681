import {
  ChangeEvent,
  ForwardedRef,
  InputHTMLAttributes,
  forwardRef,
} from 'react';
import { Input } from './styles';
import {
  DefaultErrorContainer,
  DefaultFieldFormsContainer,
  DefaultFormsLabel,
} from '../../styles';
import InfoTooltip from '@components/info-tooltip';
import useFormField from '@hooks/useFormField';

interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  value: string;
  type: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  placeholder?: string;
  width?: string | number;
  height?: string | number;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  errorMessage?: string;
  dataTest: string;
  onErrorChange?: (value: string) => void;
  disabled?: boolean;
  displayStyle?: string;
  directionStyle?: string;
  alignItems?: string;
  informative?: string;
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      type,
      label,
      placeholder,
      width,
      height,
      required,
      value,
      onChange,
      minLength,
      maxLength,
      errorMessage,
      dataTest,
      onErrorChange,
      disabled,
      informative,
      displayStyle,
      directionStyle,
      alignItems,
      ...props
    },
    ref?: ForwardedRef<HTMLInputElement>
  ) => {
    const { isFocused, isValid, handleOnBlur, handleOnFocus } = useFormField(
      errorMessage,
      onErrorChange
    );

    return (
      <DefaultFieldFormsContainer
        width={width}
        height={height}
        $display={displayStyle}
        $alignItems={alignItems}
        $direction={directionStyle}
      >
        {label && (
          <DefaultFormsLabel $disabled={disabled} required={required}>
            {label}
            {required && <span>*</span>}
            <InfoTooltip
              message={informative}
              dataTestId={`info-tooltip-${dataTest}`}
              showInfo={!!informative}
            />
          </DefaultFormsLabel>
        )}

        <Input
          data-testid={dataTest}
          ref={ref ?? null}
          type={type}
          value={value ?? ''}
          $isFocused={isFocused}
          $isValid={isValid}
          onFocus={() => handleOnFocus()}
          onBlur={() => handleOnBlur()}
          onChange={(e) => onChange(e)}
          minLength={minLength}
          maxLength={maxLength}
          placeholder={placeholder}
          disabled={disabled}
          {...props}
        />

        <DefaultErrorContainer $isValid={isValid}>
          <span>{!isFocused ? errorMessage : ''}</span>
        </DefaultErrorContainer>
      </DefaultFieldFormsContainer>
    );
  }
);
