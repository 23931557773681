import styled from 'styled-components';

export const Label = styled.label`
  display: flex;
  padding-right: 0.3rem;
  padding-left: 0.3rem;
  align-items: center;
  font-size: 1.2rem;
  font-weight: bold;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;

export const AppTitle = styled.label`
  margin-left: 0.6rem;
  margin-right: 0.3rem;
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  gap: 0.6rem;
`;

export const OptionsList = styled.ul`
  list-style-type: none;
  display: flex;
  padding: 0.3rem 0.3rem 0.3rem 0.9rem;
  gap: 0.3rem;
  flex-direction: column;
  align-items: flex-start;
`;

export const OptionItem = styled.li`
  display: flex;
  gap: 0.6rem;
  align-items: center;
`;

export const Container = styled.div`
  box-sizing: border-box;
  height: 100%;
`;

export const GrantList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  margin-top: 0.6rem;
  overflow-y: auto;
  height: 38rem;
  width: 28rem;

  @media (max-width: 38rem) {
    width: 22rem;
  }

  @media (max-height: 40rem) {
    height: 28rem;
  }

  @media (max-height: 30rem) {
    height: 23rem;
  }
`;

export const SimpleButton = styled.button`
  display: flex;
  outline: none;
  border: none;
  background-color: transparent;
  align-items: center;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.6rem;
`;
