import { FC, FormHTMLAttributes, useEffect, useState, useContext } from 'react';
import { FaServicestack } from 'react-icons/fa';
import { MdOutlineJoinLeft } from 'react-icons/md';
import { AppForm } from './app-form-fields';
import { EntityType } from '@services/ms-core-trinus-auth/controllers/auth';
import { FormDefault } from '@components/form';
import { FormHeader } from '@components/form/form-header';
import { Models } from '@services/ms-core-trinus-auth/models';
import { SearchInput } from '@components/input-search';
import { useAuth } from '@context/auth-context/AuthContext';
import { UseForm } from './user-form-fields';
import AlertContext from '@context/alert-context/alertContext';
import DynamicPositionedModal from '@components/dynamic-positioned-modal';
import Entity from '@services/ms-core-trinus-auth/controllers/entity';
import EntityCredentials from '@components/entity-credentials';
import EntityGrants from '@components/entity-grants';
import jwtDecode from 'jwt-decode';
import LoadingContext from '@context/loading-context/loadingContext';
import { useFormContext } from '@context/form-context/FormContext';
import { ConfirmationModal } from '@components/modal/confirmation-modal';
import { GridFormContainer } from '@components/form/styles';

interface EntityFormProps extends FormHTMLAttributes<HTMLFormElement> {
  entityType: string;
}
interface EntitySearchInputProps {
  entityType: string;
  onClickInItem: (value: Models.AuthEntity) => void;
  setFormEntityPageTitle: (value: string) => void;
}
interface GrantsEditorProps {
  formToEdit: Models.AuthEntityData;
}

const EntitySearchInput: FC<EntitySearchInputProps> = ({
  entityType,
  onClickInItem,
  setFormEntityPageTitle,
}) => {
  const [searchResults, setSearchResults] = useState(undefined);
  const [notFoundMsg, setNotFoundMsg] = useState<string>();
  const [isLoadingSearch, setIsLoadingSearch] = useState<boolean>(false);

  const handleEntityToEdit = async (
    e: React.MouseEvent<HTMLButtonElement>,
    entity: Models.AuthEntity
  ) => {
    e.preventDefault();
    onClickInItem(entity);
    setFormEntityPageTitle('');
  };

  const getEntityName = (entity: Models.AuthEntity) => entity.fullName;

  const handleEntityInputSearch = async (value: string) => {
    if (!value) {
      setNotFoundMsg(undefined);
      setSearchResults(undefined);
      return;
    }
    setIsLoadingSearch(true);

    const result = await Entity.findEntityByEntityName(entityType, value);
    if (result?.status !== 200) {
      const msg = 'Nenhum resultado...';
      setNotFoundMsg(msg);
      setSearchResults(undefined);
      setIsLoadingSearch(false);
      return;
    }

    setNotFoundMsg(undefined);
    setSearchResults(result.data);
    setIsLoadingSearch(false);
  };
  const renderSVGIcon = () => {
    if (entityType === Models.EntityType.etUser) {
      return <FaServicestack fill="#3D4975" />;
    }

    return <MdOutlineJoinLeft fill="#3D4975" />;
  };

  return (
    <SearchInput
      dataTest={`search-entity-${entityType?.toLowerCase()}-by-name`}
      itemIcon={renderSVGIcon()}
      searchValue={handleEntityInputSearch}
      displayResult={getEntityName}
      searchResults={searchResults}
      notFoundMsg={notFoundMsg}
      resultToEdit={handleEntityToEdit}
      maxLength={300}
      isLoading={isLoadingSearch}
    />
  );
};

const GrantsEditor: FC<GrantsEditorProps> = ({ formToEdit }) => {
  const [grantsDisable, setGrantsDisable] = useState<boolean>(true);

  useEffect(() => {
    const hasGrantsPermissions = checkGrantsPermissions();
    setGrantsDisable(!hasGrantsPermissions);
  }, []);

  const checkGrantsPermissions = () => {
    try {
      const { token } = JSON.parse(sessionStorage.getItem('entitySession'));
      const decodedToken: any = jwtDecode(token);

      const keyList = decodedToken?.keyList;

      for (const key in keyList) {
        const permissions = keyList[key]?.grant;
        if (
          permissions &&
          (permissions.includes('ALLOW') ||
            permissions.includes('REVOKE') ||
            permissions.includes('LIST'))
        ) {
          return true;
        }
      }

      return false;
    } catch (error) {
      return false;
    }
  };

  const renderGrantsEditor = () => {
    if (!formToEdit?.entityId) {
      return <></>;
    }

    return (
      <DynamicPositionedModal
        dataTest={`handle-permissions-modal-visibility`}
        buttonTitle="Permissões"
        disabled={formToEdit?.statusType === 'ACT' ? grantsDisable : true}
      >
        <EntityGrants entityId={formToEdit?.entityId} />
      </DynamicPositionedModal>
    );
  };

  return renderGrantsEditor();
};

export const EntityForm: FC<EntityFormProps> = ({ entityType, ...props }) => {
  const alert = useContext(AlertContext);
  const loading = useContext(LoadingContext);

  const {
    onErrorChange,
    validationRequiredFieldsForm,
    setErrorForms,
    errorForms,
    setFormToEdit,
    formToEdit,
    initialFormData,
    savedForm,
    setSavedForm,
    confirmModal,
    setConfirmModal,
    updateData,
    handleActionWithConfirmation,
  } = useFormContext();

  const [disableSaveButton, setDisableSaveButton] = useState<boolean>(false);

  const [entityData, setEntityData] = useState<Models.AuthEntity>();
  const [formEntityPageTitle, setFormEntityPageTitle] = useState<string>('');

  const [credentialsDisable, setCredentialsDisable] = useState<boolean>(false);
  const { permissions, hasPermission } = useAuth();

  useEffect(() => {
    if (entityData) {
      handleEntityDataSearch();
    }
    if (entityData?.fullName) {
      setFormEntityPageTitle(entityData?.fullName);
    } else {
      setFormEntityPageTitle('Cadastrando');
    }
  }, [entityData]);

  useEffect(() => {
    const hasCredentialsPermissions = checkCredentialsPermissions();
    setCredentialsDisable(!hasCredentialsPermissions);
  }, []);

  const checkCredentialsPermissions = () => {
    const entityTypePermissions: string =
      entityType === EntityType.USER ? 'user' : 'application';

    const checkRefreshAnyKeyPermissions = hasPermission(permissions, {
      key: entityTypePermissions,
      grants: ['REFRESH_ANY_KEY'],
    });

    const checkRefreshAnySecretPermissions = hasPermission(permissions, {
      key: entityTypePermissions,
      grants: ['REFRESH_ANY_SECRET'],
    });

    if (checkRefreshAnyKeyPermissions && checkRefreshAnySecretPermissions) {
      return true;
    }

    return false;
  };

  const handleEntityTitleForm = () => {
    if (entityType === Models.EntityType.etUser) {
      return `${formEntityPageTitle} ${entityData ? '' : 'shark'}`;
    }

    return `${formEntityPageTitle} ${entityData ? '' : 'aplicação'}`;
  };

  const handleEntityDataSearch = async () => {
    loading.startLoading(true);
    clearForm();

    const result = await Entity.findEntityByEntityId(entityData.entityId);
    if (result?.status !== 200) {
      loading.startLoading(false);
      setFormToEdit(undefined);
      setSavedForm(undefined);
      alert.error(result.message || result.response.data.message);
      return;
    }

    const loadData = {
      ...result.data,
      firstName: result.data?.fullName?.split(' ')?.[0],
      lastName: result.data?.fullName?.split(' ')?.slice(1)?.join(' '),
    };

    loading.startLoading(false);
    updateData(loadData);
  };

  const handleSendEntityDataSave = async () => {
    setDisableSaveButton(true);
    loading.startLoading(true);
    let saveEntity: any;
    if (formToEdit?.entityId) {
      saveEntity = await Entity.maintainEntity(
        formToEdit.entityId,
        formToEdit.firstName,
        formToEdit.lastName,
        formToEdit.fullName,
        formToEdit.emailDescription,
        formToEdit.detailDescription,
        formToEdit?.keyDescription,
        formToEdit.statusType,
        entityType
      );
    }

    if (!formToEdit?.entityId) {
      saveEntity = await Entity.createNewEntity(
        formToEdit.firstName,
        formToEdit.lastName,
        formToEdit.fullName,
        formToEdit.emailDescription,
        formToEdit.detailDescription,
        formToEdit?.keyDescription,
        formToEdit.statusType,
        entityType
      );
    }

    if (saveEntity?.status !== 201 && saveEntity?.status !== 200) {
      loading.startLoading(false);
      alert.error(saveEntity?.response?.data?.message || saveEntity?.message);
      return;
    }

    loading.startLoading(false);
    setDisableSaveButton(false);
    updateData(saveEntity.data);
    alert.success('Salvo!');
  };

  const validateEmail = (emailToValidate: string) => {
    const defaultEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

    if (emailToValidate?.length > 0 && !defaultEmail.test(emailToValidate)) {
      return false;
    }
    return true;
  };

  const validationFieldsEntityForm = () => {
    let isValid = true;
    let requiredFields = [];
    let errors = {
      firstName: '',
      lastName: '',
      fullName: '',
      emailDescription: '',
      detailDescription: '',
      statusType: '',
      keyDescription: '',
    };

    if (entityType === 'USE') {
      requiredFields = [
        'firstName',
        'lastName',
        'emailDescription',
        'keyDescription',
      ];

      if (formToEdit?.firstName?.length < 3) {
        errors = {
          ...errors,
          firstName: renderTextInputMinLengthErrorMsg(3),
        };
        isValid = false;
      }

      if (formToEdit?.lastName?.length < 3) {
        errors = {
          ...errors,
          lastName: renderTextInputMinLengthErrorMsg(3),
        };
        isValid = false;
      }

      if (formToEdit?.keyDescription?.length < 8) {
        errors = {
          ...errors,
          keyDescription: renderTextInputMinLengthErrorMsg(8),
        };
        isValid = false;
      }
    } else {
      requiredFields = ['fullName'];
      if (formToEdit.fullName && formToEdit.fullName.length < 3) {
        errors = {
          ...errors,
          fullName: renderTextInputMinLengthErrorMsg(3),
        };
        isValid = false;
      }
    }

    const validationEmail = validateEmail(formToEdit.emailDescription);
    if (!validationEmail) {
      errors = {
        ...errors,
        emailDescription: `E-mail "${formToEdit.emailDescription}" inválido!`,
      };
      isValid = false;
    }

    const validationRequired = validationRequiredFieldsForm(
      { ...formToEdit },
      requiredFields,
      errors
    );
    if (!validationRequired.isValid) {
      errors = { ...errors, ...validationRequired.formErrorsFound };
      isValid = false;
    }

    setErrorForms(errors);
    return isValid;
  };

  const renderTextInputMinLengthErrorMsg = (minLenght: number) => {
    return `O campo requer no mínimo ${minLenght} caracteres`;
  };

  const handleValidationAndSave = async (
    e?: React.MouseEvent<HTMLButtonElement>
  ) => {
    e?.preventDefault?.();

    if (validationFieldsEntityForm()) {
      await handleSendEntityDataSave();
    } else {
      alert.error(
        'Não foi possível salvar. Por favor, revise o formulário e tente novamente.'
      );
    }
  };

  const clearForm = (e?: React.MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault?.();
    setConfirmModal({ show: false });
    setSavedForm(undefined);
    setFormToEdit({ ...initialFormData });
    setEntityData(undefined);
    setFormEntityPageTitle('Cadastrando');
    setErrorForms(initialFormData);
    alert.clear();
  };

  const handleSelect = (data: Models.AuthEntity) => {
    handleActionWithConfirmation(() => setEntityData(data));
  };

  const handleFormClean = () => {
    handleActionWithConfirmation(clearForm);
  };

  const handleUnsavedChangeKeyDescription = () => {
    if (savedForm.keyDescription !== formToEdit.keyDescription) {
      setConfirmModal({
        title: 'CONFIRMAÇÃO DE SALVAMENTO.',
        message:
          'Há alterações não salvas no campo de usuário. É necessário salvar essas alterações antes de gerar novas credenciais. Deseja salvar agora?',
        show: true,
        confirmAction: () => {
          handleValidationAndSave();
          setConfirmModal({ show: false });
        },
      });
      return true;
    }
    return false;
  };

  const renderEntityForm = () => {
    if (entityType === 'USE') {
      return (
        <UseForm
          entityData={formToEdit}
          onUpdate={setFormToEdit}
          errorForms={errorForms}
          onErrorChange={onErrorChange}
        />
      );
    }

    return (
      <AppForm
        entityData={formToEdit}
        onUpdate={setFormToEdit}
        errorForms={errorForms}
        onErrorChange={onErrorChange}
      />
    );
  };

  const renderCredentialsEditor = () => {
    if (!formToEdit?.entityId) {
      return <></>;
    }

    return (
      <EntityCredentials
        entityType={entityType}
        entityId={formToEdit?.entityId}
        disabled={formToEdit?.statusType === 'ACT' ? credentialsDisable : true}
        keyDescription={savedForm?.keyDescription}
        onUnsavedChanges={handleUnsavedChangeKeyDescription}
      />
    );
  };

  return (
    <FormDefault {...props}>
      <FormHeader
        title={handleEntityTitleForm()}
        disableButton={disableSaveButton}
        hasHeaderDefaultButtons={true}
        handleButton={handleValidationAndSave}
        handleCancel={handleFormClean}
      >
        <GrantsEditor formToEdit={formToEdit} />
        <EntitySearchInput
          entityType={entityType}
          onClickInItem={handleSelect}
          setFormEntityPageTitle={setFormEntityPageTitle}
        />
      </FormHeader>
      <GridFormContainer>
        {renderEntityForm()}
        {renderCredentialsEditor()}
      </GridFormContainer>
      {confirmModal.show && (
        <ConfirmationModal
          displayModal={true}
          title={confirmModal?.title}
          message={confirmModal?.message}
          onConfirm={() => confirmModal?.confirmAction?.()}
          onCancel={() => {
            setConfirmModal({ show: false });
          }}
        />
      )}
    </FormDefault>
  );
};
