import { ChangeEvent, FC, SelectHTMLAttributes } from 'react';
import { Select } from './styles';
import {
  DefaultErrorContainer,
  DefaultFieldFormsContainer,
  DefaultFormsLabel,
} from '../styles';
import InfoTooltip from '@components/info-tooltip';
import useFormField from '@hooks/useFormField';

interface SelectListProps extends SelectHTMLAttributes<HTMLSelectElement> {
  width: string | number;
  selectOptions: string[];
  selectValue?: string;
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  dataTest: string;
  onErrorChange?: (value: string) => void;
  informative?: string;
}

export const SelectList: FC<SelectListProps> = ({
  width,
  selectOptions,
  selectValue,
  onChange,
  label,
  required,
  errorMessage,
  disabled,
  dataTest,
  onErrorChange,
  informative,
}) => {
  const { isFocused, isValid, handleOnBlur, handleOnFocus } = useFormField(
    errorMessage,
    onErrorChange
  );

  return (
    <DefaultFieldFormsContainer width={width}>
      {label && (
        <DefaultFormsLabel required={required}>
          {label}
          {required && <span>*</span>}
          <InfoTooltip
            message={informative}
            dataTestId={`info-tooltip-${dataTest}`}
            showInfo={!!informative}
          />
        </DefaultFormsLabel>
      )}
      <Select
        data-testid={dataTest}
        $isFocused={isFocused}
        disabled={disabled}
        onChange={(e) => onChange(e)}
        onFocus={() => handleOnFocus()}
        onBlur={() => handleOnBlur()}
        value={selectValue}
      >
        {selectOptions.map((item: string) => {
          return (
            <option key={`${item}`} value={item}>
              {item}
            </option>
          );
        })}
      </Select>
      <DefaultErrorContainer $isValid={isValid}>
        <span>{!isFocused ? errorMessage : ''}</span>
      </DefaultErrorContainer>
    </DefaultFieldFormsContainer>
  );
};
