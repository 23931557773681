import React, { createContext, useContext } from 'react';
import jwtDecode from 'jwt-decode';

const AuthContext = createContext(null);

export function useAuth() {
  return useContext(AuthContext);
}

interface AuthProviderProps {
  children: React.ReactNode;
}

export class AuthProvider extends React.Component<AuthProviderProps> {
  constructor(props: AuthProviderProps) {
    super(props);

    const { token } = JSON.parse(sessionStorage.getItem('entitySession'));

    const { authPermissions, payPermissions } = this.updatePermissions(token);

    this.state = {
      permissions: {
        ...authPermissions,
        ...payPermissions,
      },
      hasPermission: this.hasPermission,
      updatePermissions: this.updatePermissions,
    };
  }

  public hasPermission(permissions: any, permissionData: any) {
    return permissions[permissionData.key].some((permission: string) =>
      permissionData.grants.includes(permission)
    );
  }

  public updatePermissions(token: string) {
    try {
      const decodedToken: any = jwtDecode(token);

      if (decodedToken.keyList) {
        const core_auth = decodedToken.keyList?.core_auth || {};
        const core_pay = decodedToken.keyList?.core_pay || {};

        const authPermissions = {
          application: core_auth?.entity?.application || [],
          user: core_auth?.entity?.user || [],
          common: core_auth?.entity?.common || [],
          core_auth_grant: core_auth?.grant || [],
          group: core_auth?.group || [],
        };

        const payPermissions = {
          core_pay_grant: core_pay?.grant || [],
          final_beneficiary: core_pay?.final_beneficiary || [],
          internal_bank_account: core_pay?.internal_bank_account || [],
          bank_slip: core_pay?.bank_slip || [],
          transact_moviments: core_pay?.core_banking?.transact_moviments || [],
          cash_in: core_pay?.core_banking?.cash_in || [],
          cash_out: core_pay?.core_banking?.cash_out || [],
        };

        return {
          authPermissions,
          payPermissions,
        };
      }

      return {
        authPermissions: null,
        payPermissions: null,
      };
    } catch (error) {
      console.error('Error decoding or parsing token:', error);
      return {
        authPermissions: null,
        payPermissions: null,
      };
    }
  }

  render() {
    return (
      <AuthContext.Provider value={this.state}>
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}
