import { FC } from 'react';
import {
  ButtonsContainer,
  Container,
  HeaderTitle,
  IconContainer,
} from './styles';
import { LiaTimesSolid } from 'react-icons/lia';
import DefaultButton from '@components/default-button';
import { ThemeName } from '@components/default-button/styles';

interface FormHeaderProps {
  children?: React.ReactNode;
  title?: string;
  hasHeaderDefaultButtons?: boolean;
  handleButton?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disableButton?: boolean;
  handleCancel?: (e?: React.MouseEvent<any>) => void;
}

export const FormHeader: FC<FormHeaderProps> = ({
  children,
  title,
  hasHeaderDefaultButtons,
  handleButton,
  disableButton,
  handleCancel,
}) => {
  const handleHeaderTitle = () => {
    const capitalize = (name: string) =>
      name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();

    if (title) return title.toLowerCase().split(' ').map(capitalize).join(' ');
  };
  const handleCancelAndPreventDefault = (e) => {
    e?.preventDefault?.();
    handleCancel?.();
  };

  const handleDefaultButtons = () => {
    return (
      <>
        <DefaultButton
          dataTest={`save-form`}
          theme={ThemeName.PRIMARY_REGULAR}
          disabled={disableButton}
          label="Salvar"
          title="Salvar"
          onClick={handleButton}
        />
        <IconContainer>
          <LiaTimesSolid
            data-testid={`close-form`}
            fill="#1a6ce8"
            title="Cancelar"
            onClick={handleCancelAndPreventDefault}
          />
        </IconContainer>
      </>
    );
  };

  return (
    <Container>
      <HeaderTitle>{handleHeaderTitle()}</HeaderTitle>
      <ButtonsContainer>
        {children}
        {hasHeaderDefaultButtons ? handleDefaultButtons() : <></>}
      </ButtonsContainer>
    </Container>
  );
};
