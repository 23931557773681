import styled, { css } from 'styled-components';

interface SelectProps {
  $isFocused?: boolean;
}

export const Select = styled.select<SelectProps>`
  ${({ $isFocused }) => css`
    margin: 0;
    box-sizing: border-box;
    width: 100%;
    font-size: 0.9rem;
    padding: 0.5rem;
    border-radius: 0.25rem;
    border: 1px solid ${$isFocused ? '#000' : '#d9d9d9'};
    outline: none;
  `}
`;
