import { FC, InputHTMLAttributes, useEffect, useState } from 'react';
import { ContainerAbsolute, ContainerRelative } from './styles';
import { SearchResults } from '@components/input-search/search-results';
import {
  DefaultErrorContainer,
  DefaultFieldFormsContainer,
  DefaultFormsLabel,
  DefaultSearchContainer,
} from '../../styles';
import { Input } from '@components/input-search/search-input';
import InfoTooltip from '@components/info-tooltip';
import useFormField from '@hooks/useFormField';

interface InputSelectedProps extends InputHTMLAttributes<HTMLInputElement> {
  itemIcon?: React.ReactNode;
  label?: string;
  customPlaceholder?: string;
  searchValue?: (value: string) => void;
  displayResult: (result: any) => any;
  searchResults?: any[];
  notFoundMsg: string;
  onSelectedValue?: (e: React.MouseEvent<HTMLButtonElement>, data: any) => void;
  selectedValue?: string;
  onRemoveValue?: () => void;
  width?: string;
  height?: string;
  required?: boolean;
  errorMessage?: string;
  maxLength?: number;
  minLength?: number;
  isLoading?: boolean;
  dataTest: string;
  inputRef?: any;
  onErrorChange?: (value: string) => void;
  informative?: string;
}

export const InputSelected: FC<InputSelectedProps> = ({
  itemIcon,
  label,
  width,
  height,
  customPlaceholder,
  onSelectedValue,
  searchValue,
  displayResult,
  searchResults,
  notFoundMsg,
  selectedValue,
  onRemoveValue,
  errorMessage,
  required,
  isLoading,
  minLength,
  maxLength,
  dataTest,
  inputRef,
  onErrorChange,
  informative,
}) => {
  const [shouldValidate, setShouldValidate] = useState<boolean>(false);
  const {
    isFocused,
    isValid,
    handleOnBlur,
    handleOnFocus,
    setIsFocused,
    handleValidation,
  } = useFormField(errorMessage, onErrorChange);

  const handleCustomOnBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
    if (
      !e.relatedTarget ||
      !e.currentTarget.contains(e.relatedTarget as Node)
    ) {
      handleOnBlur();
    }
  };

  const handleSearchValue = (value: string) => {
    searchValue(value);
  };

  const handleItemSelection = (
    e: React.MouseEvent<HTMLButtonElement>,
    result: any
  ) => {
    onSelectedValue(e, result);
  };

  const handleRemove = () => {
    onRemoveValue?.();
    setIsFocused(false);
    handleValidation();
  };

  useEffect(() => {
    if (shouldValidate) {
      handleValidation();
    }
    setShouldValidate(true);
  }, [selectedValue]);

  return (
    <DefaultFieldFormsContainer
      width={width}
      height={height}
      onFocus={handleOnFocus}
      onBlur={handleCustomOnBlur}
      data-testid={`search-container-${dataTest}`}
    >
      {label && (
        <DefaultFormsLabel required={required}>
          {label}
          {required && <span>*</span>}
          <InfoTooltip
            message={informative}
            dataTestId={`info-tooltip-${dataTest}`}
            showInfo={!!informative}
          />
        </DefaultFormsLabel>
      )}
      <DefaultSearchContainer $isFocused={isFocused} $isValid={isValid}>
        <Input
          ref={inputRef}
          dataTest={dataTest}
          valueToSearch={handleSearchValue}
          placeholder={customPlaceholder}
          selectedValue={selectedValue}
          isFocused={isFocused}
          isValid={isValid}
          onRemoveValue={handleRemove}
          maxLength={maxLength}
          isLoading={isLoading}
          minLength={minLength}
        />
      </DefaultSearchContainer>

      {!selectedValue && isFocused && (
        <ContainerRelative>
          <ContainerAbsolute>
            <SearchResults
              data-testid={`selectable-item-${searchResults}`}
              results={searchResults}
              displayResult={displayResult}
              onClick={handleItemSelection}
              itemIcon={itemIcon}
              notFoundMsg={notFoundMsg}
            />
          </ContainerAbsolute>
        </ContainerRelative>
      )}

      <DefaultErrorContainer $isValid={isValid}>
        <span>{!isFocused ? errorMessage : ''}</span>
      </DefaultErrorContainer>
    </DefaultFieldFormsContainer>
  );
};
