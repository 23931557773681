import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useForm } from '@hooks/useForm';
import Library from '@utils/Library';

interface FormContextProps extends ReturnType<typeof useForm> {
  initialFormData: Record<string, any>;
  formToEdit: Record<string, any>;
  setFormToEdit: React.Dispatch<React.SetStateAction<any>>;
  handleActionWithConfirmation: (action: () => void) => void;
  confirmModal: {
    title?: string;
    message?: string;
    confirmAction?: () => void;
    show: boolean;
  };
  setConfirmModal: React.Dispatch<
    React.SetStateAction<{
      title?: string;
      message?: string;
      confirmAction?: () => void;
      show: boolean;
    }>
  >;
  savedForm: Record<string, any>;
  setSavedForm: React.Dispatch<React.SetStateAction<any>>;
  updateData: (dataToUpdate: Record<string, any>) => void;
}

export const FormContext = createContext<FormContextProps | null>(null);

export const FormProvider = ({ children, initialFormData }) => {
  const [formToEdit, setFormToEdit] = useState(initialFormData);
  const [savedForm, setSavedForm] = useState();

  const [confirmModal, setConfirmModal] = useState<{
    message?: string;
    title?: string;
    confirmAction?: () => void;
    show: boolean;
  }>({
    show: false,
  });

  const formFunctions = useForm(formToEdit, setFormToEdit);

  const handleActionWithConfirmation = useCallback(
    (action: () => void) => {
      const hasUnsavedData =
        !Library.compareObjects(formToEdit, savedForm) &&
        !Library.compareObjects(formToEdit, initialFormData);

      if (hasUnsavedData) {
        setConfirmModal({
          title: 'CONFIRMAÇÃO NECESSÁRIA.',
          message:
            'Ao confirmar, todas as alterações não salvas serão descartadas. Deseja prosseguir?',
          show: true,
          confirmAction: action,
        });
      } else {
        action();
      }
    },
    [formToEdit, savedForm, initialFormData, setConfirmModal]
  );

  const updateData = useCallback(
    (dataToUpdate: Record<string, any>) => {
      setSavedForm(JSON.parse(JSON.stringify(dataToUpdate)));
      setFormToEdit(JSON.parse(JSON.stringify(dataToUpdate)));
    },
    [setSavedForm, setFormToEdit]
  );

  const contextValue = useMemo(() => {
    return {
      ...formFunctions,
      initialFormData,
      formToEdit,
      setFormToEdit,
      handleActionWithConfirmation,
      confirmModal,
      setConfirmModal,
      savedForm,
      setSavedForm,
      updateData,
    };
  }, [
    formFunctions,
    initialFormData,
    formToEdit,
    setFormToEdit,
    handleActionWithConfirmation,
    confirmModal,
    setConfirmModal,
    savedForm,
    setSavedForm,
    updateData,
  ]);

  return (
    <FormContext.Provider value={contextValue}>{children}</FormContext.Provider>
  );
};

export const useFormContext = () => {
  return useContext(FormContext);
};
