import styled, { css } from 'styled-components';

interface DefaultFormsLabelProps {
  required?: boolean;
  $disabled?: boolean;
}

interface DefaultFieldFormsContainerProps {
  width?: string | number;
  height?: string | number;
  $display?: string;
  $alignItems?: string;
  $direction?: string;
}

interface DefaultErrorContainerProps {
  $isValid?: boolean;
}

interface FlexWrapFormContainerProps {
  $width?: string | number;
  $height?: string | number;
  $flexDirection?: string;
  $flexWrapType?: string;
  $justifyContent?: string;
  $alignItems?: string;
}

export const FormContainer = styled.form<{ $containerHeight: string }>`
  height: ${({ $containerHeight }) => $containerHeight};
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ededed;
`;

export const DefaultFieldFormsContainer = styled.div<DefaultFieldFormsContainerProps>`
  ${({ width, height, $display, $alignItems, $direction }) => css`
    width: ${width ?? ''};
    height: ${height ?? ''};
    display: ${$display ?? ''};
    align-items: ${$alignItems ?? ''};
    flex-direction: ${$direction ?? ''};
    padding: 0.2rem;
  `}
`;

export const DefaultFormsLabel = styled.label<DefaultFormsLabelProps>`
  ${({ required, $disabled }) => css`
    font-size: 0.75rem;
    padding-bottom: 0.1rem;
    display: flex;
    flex-direction: row;
    padding-left: 0.1rem > span {
      display: ${required ? '' : 'none'};
      padding-left: 0.1rem;
    }

    div {
      margin-left: 2px;
    }

    color: ${$disabled ? '#B6C1C2' : 'inherit'};
  `}
`;

interface SearchContainerProps {
  $isFocused?: boolean;
  $isValid?: boolean;
}

export const DefaultSearchContainer = styled.div<SearchContainerProps>`
  display: flex;
  width: 100%;
  background-color: white;
  outline: none;

  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  border-radius: 0.25rem;
  gap: 0.2rem;

  ${({ $isFocused, $isValid }) => css`
    border: 1px solid ${$isFocused ? '#000' : !$isValid ? 'red' : '#d9d9d9'};
  `};

  > svg {
    width: 1rem;
    height: 1rem;
  }

  @media (max-width: 300px) {
    > svg {
      width: 100%;
    }
  }

  .animate-spin {
    animation: fa-spin 1s infinite linear;

    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
`;

export const DefaultErrorContainer = styled.div<DefaultErrorContainerProps>`
  ${({ $isValid }) => css`
    height: 1rem;
    display: flex;
    align-items: center;
    max-width: 100%;
    min-width: 0;
    > span {
      margin-left: 1rem;
      font-size: 0.6rem;
      color: red;
      display: inline;
      overflow: hidden;
      visibility: ${$isValid ? 'hidden' : 'visible'};
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;
      min-width: 0;
    }
  `}
`;

export const DefaultSubTitle = styled.p`
  padding: 0.4rem 0.2rem;
  font-size: 0.9rem;
  font-weight: bold;
`;

export const FlexWrapFormContainer = styled.div<FlexWrapFormContainerProps>`
  ${({
    $width,
    $height,
    $flexDirection,
    $flexWrapType,
    $justifyContent,
    $alignItems,
  }) => css`
    height: ${$height ?? 'min-content'};
    width: ${$width ?? '100%'};
    display: flex;
    flex-direction: ${$flexDirection ?? 'row'};
    flex-wrap: ${$flexWrapType ?? 'wrap'};
    justify-content: ${$justifyContent ?? 'start'};
    align-items: ${$alignItems ?? ''};
  `}
`;

export const GridFormContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(25rem, 1fr));
  gap: 0.6rem;
  max-height: 88vh;
  overflow-y: auto;

  > :nth-child(1) {
    grid-column: 1;
  }

  > :nth-child(2) {
    grid-column: 2;
  }

  @media (max-width: 60rem) {
    grid-template-columns: 1fr;
    div:nth-child(1) {
      grid-column: 1;
    }

    div:nth-child(2) {
      grid-column: 1;
    }
  }
`;
