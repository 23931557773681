import { ButtonHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

export const enum ThemeName {
  PRIMARY_REGULAR = 'primaryRegular',
  PRIMARY_BOLD = 'primaryBold',
}

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  disabled: boolean;
  $theme: ThemeName;
  $width: string | number;
  $height: string | number;
}

const themeProperties = {
  primaryRegular: {
    normal: css`
      background-color: #1a6ce8;
      color: #ffffff;
      border: none;
      font-weight: normal;
    `,
    hover: css`
      &:hover {
        background-color: #0b5dd7;
      }
    `,
    active: css`
      &:active {
        background-color: #1a6ce8;
      }
    `,
    content: css`
      > svg {
        fill: #1a6ce8;
        color: #1a6ce8;
      }
      > i {
        color: #ffffff;
      }
    `,
    disabled: css`
      background-color: #c6c6c6;
      border: none;
      color: #ffffff;
      cursor: default;
      font-weight: normal;
    `,
    disabledContent: css`
      > svg {
        fill: #c6c6c6;
        color: #c6c6c6;
      }
      > i {
        color: #ffffff;
      }
    `,
  },
  primaryBold: {
    normal: css`
      background-color: #0b5dd7;
      color: #ffffff;
      border: none;
      font-weight: bold;
    `,
    hover: css`
      &:hover {
        background-color: #1a6ce8;
      }
    `,
    active: css`
      &:active {
        background-color: #0b5dd7;
      }
    `,
    content: css`
      > svg {
        fill: #0b5dd7;
        color: #0b5dd7;
      }
      > i {
        color: #ffffff;
      }
    `,
    disabled: css`
      background-color: #c6c6c6;
      border: none;
      color: #ffffff;
      cursor: default;
      font-weight: bold;
    `,
    disabledContent: css`
      > svg {
        fill: #c6c6c6;
        color: #c6c6c6;
      }
      > i {
        color: #ffffff;
      }
    `,
  },
};

export const Button = styled.button<ButtonProps>`
  ${({ disabled, $theme, $width, $height }) => css`
    width: ${$width ?? 'auto'};
    max-width: 20rem;
    height: ${$height ?? 'auto'};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    padding: 0.3rem 0.5rem;
    gap: 0.5rem;
    font-size: 1rem;
    cursor: pointer;
    ${disabled
      ? `
      ${themeProperties[$theme].disabled}
      ${themeProperties[$theme].disabledContent}
      `
      : `
      ${themeProperties[$theme].normal}
      ${themeProperties[$theme].hover}
      ${themeProperties[$theme].content}
      ${themeProperties[$theme].active}
      `}
  `};
`;
