import { AxiosResponse } from 'axios';
import Context from '@services/ms-core-trinus-auth/context';
import { Models } from '@services/ms-core-trinus-auth/models';
import Auth from '../auth';

class Entity {
  /* Sobre esta função */ /**
   * Buscar entidade pelo nome
   * @param {string} entityType Recebe o entityType da entidade a ser pesquisada
   * @param {string} entityName Recebe o nome da entidade a ser pesquisada
   **/
  public async findEntityByEntityName(
    entityType: string,
    entityName: string
  ): Promise<AxiosResponse<Models.AuthEntity> & Models.AuthErrors> {
    const token = await Auth.getToken();
    if (!token) return;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return await Context.get(
      `/entity?entityType=${entityType}&entityName=${entityName}`,
      config
    )
      .then((Response) => {
        return Response;
      })
      .catch((Exception) => {
        return Exception;
      });
  }

  /* Sobre esta função */ /**
   * Busca a entidade pelo entityId
   * @param {number} entityId Recebe o entityId da entidade a ser visualizada
   **/
  public async findEntityByEntityId(
    entityId: number
  ): Promise<AxiosResponse<Models.AuthEntityData> & Models.AuthErrors> {
    const token = await Auth.getToken();
    if (!token) return;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return await Context.get(`/entity/entity-id/${entityId}`, config)
      .then((Response) => {
        return Response;
      })
      .catch((Exception) => {
        return Exception;
      });
  }

  /* Sobre esta função */ /**
   * Cadastra uma nova entidade
   * @param {string | undefined} firstName Informa o nome da entidade a ser cadastrada
   * @param {string | undefined} lastName Informa o sobrenome da entidade a ser cadastrada
   * @param {string | undefined} fullName Informa o nome completo ou razão social da entidade a ser cadastrada
   * @param {string | undefined} emailDescription Informa o email da entidade a ser cadastrada
   * @param {string | undefined} detailDescription Informa as informações adicionais da entidade a ser cadastrada
   * @param {string} statusType Informa o status do cadastro da entidade
   * @param {string} entityType Informa a entityType para cadastro
   **/
  public async createNewEntity(
    firstName: string | undefined,
    lastName: string | undefined,
    fullName: string | undefined,
    emailDescription: string | undefined,
    detailDescription: string | undefined,
    keyDescription: string | undefined,
    statusType: string,
    entityType: string
  ): Promise<
    AxiosResponse<Models.AuthMaintainEntityResponse> & Models.AuthErrors
  > {
    const token = await Auth.getToken();
    if (!token) return;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const data: Models.AuthEntityData = {
      firstName,
      lastName,
      fullName,
      emailDescription,
      detailDescription,
      statusType,
    };

    let entityTypeToMaintain: string;
    if (entityType === 'USE') {
      entityTypeToMaintain = 'user';
      data.keyDescription = keyDescription;
    }

    if (entityType === 'APP') entityTypeToMaintain = 'application';

    return await Context.post(`/entity/${entityTypeToMaintain}`, data, config)
      .then((Response) => {
        return Response;
      })
      .catch((Exception) => {
        return Exception;
      });
  }

  /* Sobre esta função */ /**
   * Atualiza o cadastro de uma entidade
   * @param {number} entityId Informa o entityId da entidade a ser atualizada
   * @param {string | null} firstName Informa o nome da entidade a ser atualizado
   * @param {string | null} lastName Informa o sobrenome da entidade a ser atualizado
   * @param {string | null} fullName Informa o nome completo ou razão social da entidade a ser atualizada
   * @param {string | null} emailDescription Informa o email da entidade a ser atualizado
   * @param {string | null} detailDescription Informa as informações adicionais da entidade a serem atualizadas
   * @param {string} statusType Informa o status do cadastro da entidade
   * @param {string} entityType Informa a entityType para atualização
   **/
  public async maintainEntity(
    entityId: number,
    firstName: string | null,
    lastName: string | null,
    fullName: string | null,
    emailDescription: string | null,
    detailDescription: string | null,
    keyDescription: string | null,
    statusType: string,
    entityType: string
  ): Promise<
    AxiosResponse<Models.AuthMaintainEntityResponse> & Models.AuthErrors
  > {
    const token = await Auth.getToken();
    if (!token) return;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const data: Models.AuthEntityData = {
      entityId,
      firstName,
      lastName,
      fullName,
      emailDescription,
      detailDescription,
      statusType,
    };

    let entityTypeToMaintain: string;
    if (entityType === 'USE') {
      entityTypeToMaintain = 'user';
      data.keyDescription = keyDescription;
    }

    if (entityType === 'APP') entityTypeToMaintain = 'application';

    return await Context.put(
      `/entity/${entityTypeToMaintain}/${entityId}`,
      data,
      config
    )
      .then((Response) => {
        return Response;
      })
      .catch((Exception) => {
        return Exception;
      });
  }
}

export default new Entity();
