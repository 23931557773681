import { FC } from 'react';
import { EntityForm } from '@components/entity-form';
import { FormProvider } from '@context/form-context/FormContext';

interface EntityPageProps {
  entityType: string;
}

export const EntityPage: FC<EntityPageProps> = ({ entityType }) => {
  return (
    <FormProvider
      initialFormData={{
        firstName: '',
        lastName: '',
        fullName: '',
        emailDescription: '',
        detailDescription: '',
        statusType: 'ACT',
        keyDescription: '',
      }}
    >
      <EntityForm entityType={entityType} />
    </FormProvider>
  );
};
