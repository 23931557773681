import styled from 'styled-components';

export const InputArea = styled.input`
  background-color: transparent;
  border: none;
  height: 100%;
  font-size: 0.9rem;
  width: 100%;

  &:focus {
    outline: none;
  }

  @media (max-width: 300px) {
    font-size: 0.5rem;
  }
`;
