import { Modal } from '@components/modal';
import styled from 'styled-components';

export const CustomModal = styled(Modal)`
  margin: auto;
  min-height: 8rem;
  max-height: 20rem;
  padding: 0.5rem;
  gap: 1rem;
  border-radius: 0.5rem;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  overflow-y: scroll;
`;
