import React, {
  createContext,
  useCallback,
  useMemo,
  useState,
  useRef,
  useEffect,
} from 'react';

export enum AlertStatus {
  None = 'NONE',
  Success = 'SUCCESS',
  Error = 'ERROR',
  Warning = 'WARNING',
  Info = 'INFO',
}

export interface AlertContextProps {
  alert: AlertStatus;
  alertText: string;
  showAlert: boolean;
  success: (text: string, timeout?: number | undefined) => void;
  error: (text: string, timeout?: number | undefined) => void;
  warning: (text: string, timeout?: number | undefined) => void;
  info: (text: string, timeout?: number | undefined) => void;
  clear: () => void;
}

const AlertContext = createContext<AlertContextProps | null>(null);

const AlertProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [alert, setAlert] = useState<AlertStatus>(AlertStatus.None);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertText, setAlertText] = useState<string>('');

  const isMountedRef = useRef(true);

  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const setTimer = useCallback((timeout: number | undefined) => {
    const timer = setTimeout(() => {
      if (isMountedRef.current) {
        setAlert(AlertStatus.None);
        setShowAlert(false);
        setAlertText('');
      }
    }, timeout * 1000 || 5000);

    return () => clearTimeout(timer);
  }, []);

  const showAlertWithType = useCallback(
    (type: AlertStatus, text: string, timeout: number | undefined) => {
      setAlert(type);
      setAlertText(text);
      setShowAlert(true);
      setTimer(timeout);
    },
    [setTimer]
  );

  const handleClearAlert = () => {
    setAlert(AlertStatus.None);
    setShowAlert(false);
    setAlertText('');
  };

  const value: any = useMemo(
    () => ({
      alert,
      alertText,
      showAlert,
      success: (text: string, timeout?: number | undefined) =>
        showAlertWithType(AlertStatus.Success, text, timeout),
      error: (text: string, timeout?: number | undefined) =>
        showAlertWithType(AlertStatus.Error, text, timeout),
      warning: (text: string, timeout?: number | undefined) =>
        showAlertWithType(AlertStatus.Warning, text, timeout),
      info: (text: string, timeout?: number | undefined) =>
        showAlertWithType(AlertStatus.Info, text, timeout),
      clear: handleClearAlert,
    }),
    [alert, alertText, showAlert, showAlertWithType]
  );

  return (
    <AlertContext.Provider value={value}>{children}</AlertContext.Provider>
  );
};
export { AlertProvider };
export default AlertContext;
