export default class Library {
  static compareObjects = (objA: any, objB: any): boolean => {
    if (typeof objA !== 'object' || typeof objB !== 'object') {
      return objA === objB;
    }

    const keysA = Object.keys(objA);
    const keysB = Object.keys(objB);

    if (keysA.length !== keysB.length) {
      return false;
    }

    for (const key of keysA) {
      if (!this.compareObjects(objA[key], objB[key])) {
        return false;
      }
    }

    return true;
  };

  static toString(value: any) {
    if (!value) {
      return undefined;
    }

    try {
      return value.toString();
    } catch (e) {
      return value;
    }
  }

  static onlyNumbers(value: any): string | undefined {
    if (!value) {
      return undefined;
    }

    if (typeof value !== 'string') {
      return undefined;
    }

    const vector = value.match(/\d/g);
    if (!vector) {
      return undefined;
    }

    return vector.join('');
  }

  private static validateCPFCalcPositions(digits: any, positions: number) {
    digits = digits.toString();

    let digitsSum = 0;

    for (const element of digits) {
      digitsSum = digitsSum + element * positions;
      positions--;

      if (positions < 2) {
        positions = 9;
      }
    }

    digitsSum = digitsSum % 11;

    if (digitsSum < 2) {
      digitsSum = 0;
    } else {
      digitsSum = 11 - digitsSum;
    }

    return digits + digitsSum;
  }

  static validateCPF(cpfStr: any) {
    cpfStr = cpfStr.toString();
    cpfStr = Library.onlyNumbers(cpfStr);

    if (!cpfStr) return false;

    if (cpfStr.length != 11) {
      return false;
    }

    if (cpfStr.split('').every((char: string) => char === cpfStr[0])) {
      return false;
    }

    const digits = cpfStr.substr(0, 9);

    let newCPF = Library.validateCPFCalcPositions(digits, 10);
    newCPF = Library.validateCPFCalcPositions(newCPF, 11);

    if (newCPF === cpfStr) {
      return true;
    }

    return false;
  }

  static validateCNPJ(cnpjStr: any) {
    cnpjStr = cnpjStr.toString();
    cnpjStr = Library.onlyNumbers(cnpjStr);

    if (!cnpjStr) return false;

    if (cnpjStr.split('').every((char: string) => char === cnpjStr[0])) {
      return false;
    }

    const originalCNPJ = cnpjStr;
    const firstCNPJNumbers = cnpjStr.substr(0, 12);
    const firstCalc = Library.validateCPFCalcPositions(firstCNPJNumbers, 5);
    const cnpj = Library.validateCPFCalcPositions(firstCalc, 6);

    if (cnpj === originalCNPJ) {
      return true;
    }

    return false;
  }

  static validateEmail(email: any): boolean {
    const emailRegex = new RegExp(
      /^[A-Za-z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
      'gm'
    );
    return emailRegex.test(email);
  }

  static getObjectKeys(data: any) {
    if (Array.isArray(data)) {
      for (const item of data) {
        if (typeof item === 'object' && item !== null) {
          return Object.keys(item);
        } else {
          return [];
        }
      }
    } else if (typeof data === 'object' && data !== null) {
        return Object.keys(data);
    } else {
      return []
    }
  }
}
