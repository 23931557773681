import styled from 'styled-components';

export const Container = styled.nav`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.2rem;
  border-bottom: 1px solid #d9d9d9;
`;

export const HeaderTitle = styled.h2`
  font-size: 1rem;
  color: #606060;

  &::first-letter {
    text-transform: uppercase;
  }
`;

export const ButtonsContainer = styled.div`
  width: 30vw;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0.5rem 0;
  gap: 0.5rem;
`;

export const IconContainer = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    width: 100vw;
    height: 100vh;

    &:hover {
      cursor: pointer;
    }
  }
`;
