import React, { FC } from 'react';
import DefaultButton from '@components/default-button';
import { FlexWrapFormContainer } from '@components/form/styles';
import { CustomModal } from '../options-modal/styles';
import { Container } from './styles';

interface ConfirmationModalProps {
  displayModal: boolean;
  message?: string;
  title?: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export const ConfirmationModal: FC<ConfirmationModalProps> = ({
  displayModal,
  message,
  title,
  onConfirm,
  onCancel,
}) => {
  return (
    <CustomModal
      displayModal={displayModal}
      width={'25rem'}
      height={'auto'}
      modalCanBeClosed={false}
    >
      <Container>
        {title && <h3>{title}</h3>}
        <div>{message}</div>
      </Container>
      <FlexWrapFormContainer $justifyContent={'flex-end'}>
        <DefaultButton
          dataTest="options-modal-cancel-button"
          width={'6rem'}
          height={'2rem'}
          label="Cancelar"
          title="Cancelar"
          onClick={onCancel}
          style={{
            backgroundColor: 'white',
            color: '#1a6ce8',
          }}
        />
        <DefaultButton
          dataTest="options-modal-confirm-button"
          width={'6rem'}
          height={'2rem'}
          label="Confirmar"
          title="Confirmar"
          onClick={onConfirm}
        />
      </FlexWrapFormContainer>
    </CustomModal>
  );
};
