import { AxiosResponse } from 'axios';
import Context from '@services/ms-core-trinus-pay/context';
import { Models } from '@services/ms-core-trinus-pay/models';
import Auth from '@services/ms-core-trinus-auth/controllers/auth';

class FinalBeneficiary {
  /* Sobre esta função */ /**
   * Buscar lista de beneficiários pelo nome
   * @param {string} searchTerm Recebe o termo de busca a ser pesquisada
   **/
  public async findFinalBeneficiariesByTerm(
    searchTerm: string
  ): Promise<
    AxiosResponse<Models.FinalBeneficiaryFindResponse[]> &
      Models.MsCoreTrinusPayErrors
  > {
    const token = await Auth.getToken();
    if (!token) return;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return await Context.get(`/final-beneficiary/search/${searchTerm}`, config)
      .then((Response) => {
        return Response;
      })
      .catch((Exception) => {
        return Exception;
      });
  }

  /* Sobre esta função */ /**
   * Buscar informações de beneficiário final por id
   * @param {string} finalBeneficiaryId Recebe o id do beneficiário a ser pesquisada
   **/
  public async findFinalBeneficiaryById(
    finalBeneficiaryId: number
  ): Promise<
    AxiosResponse<Models.FinalBeneficiaryFindResponse> &
      Models.MsCoreTrinusPayErrors
  > {
    const token = await Auth.getToken();
    if (!token) return;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return await Context.get(
      `/final-beneficiary/final-beneficiary-id/${finalBeneficiaryId}`,
      config
    )
      .then((Response) => {
        return Response;
      })
      .catch((Exception) => {
        return Exception;
      });
  }

  /* Sobre esta função */ /**
   * Criar e editar um beneficiário final
   * @param {Models.FinalBeneficiaryData} finalBeneficiaryData Recebe o os dados beneficiário final a ser cadastrado ou editado
   **/
  public async maintainFinalBeneficiary(
    finalBeneficiaryData: Models.FinalBeneficiaryData
  ): Promise<
    AxiosResponse<Models.FinalBeneficiaryData> & Models.MsCoreTrinusPayErrors
  > {
    const token = await Auth.getToken();
    if (!token) return;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    let response: Promise<AxiosResponse>;

    if (finalBeneficiaryData?.finalBeneficiaryId) {
      response = Context.put(
        `/final-beneficiary/${finalBeneficiaryData.finalBeneficiaryId}`,
        finalBeneficiaryData,
        config
      );
    } else {
      response = Context.post(
        `/final-beneficiary/`,
        finalBeneficiaryData,
        config
      );
    }

    return response
      .then((Response) => Response)
      .catch((Exception) => Exception);
  }

  /* Sobre esta função */ /**
   * Consultar contas no Core Bank relacionadas ao documento principal
   * @param {string} mainRegistrationId Recebe o CPF ou CNPJ para buscar as contas relacionadas
   **/
  public async getCoreBankingAccountByDocumentNumber(
    mainRegistrationId: string
  ): Promise<
    (AxiosResponse<Array<Models.CoreBankAccountData> | undefined>) &
    Models.MsCoreTrinusPayErrors
  > {
    const token = await Auth.getToken();
    if (!token) return;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return await Context.get(
      `/final-beneficiary/core-banking/main-registration-id/${mainRegistrationId}`,
      config
    )
      .then((Response) => {
        return Response;
      })
      .catch((Exception) => {
        return Exception;
      });
  }
}

export default new FinalBeneficiary();
