import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.CORE_TRINUS_PAY_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Accept-Encoding': 'gzip, deflate',
  },
  responseType: 'json',
});

instance.interceptors.request.use((config) => {
  const userLanguage = navigator?.language;
  config.headers['Content-Language'] = userLanguage || 'pt-BR';
  return config;
});

export default instance;
