import React, { useState, useMemo, useCallback } from 'react';

const LoadingContext = React.createContext(null);

const LoadingProvider = ({ children }) => {
  const [showLoading, setShowLoading] = useState<boolean>(false);

  const startLoading = useCallback((setLoading: boolean) => {
    setShowLoading(setLoading);
  }, []);

  const value = useMemo(
    () => ({
      showLoading: showLoading,
      startLoading: startLoading,
    }),
    [showLoading, startLoading]
  );

  return (
    <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>
  );
};

export { LoadingProvider };
export default LoadingContext;
