import { Container, ModalContainer } from './styles';
import { FC, HTMLAttributes } from 'react';

export interface ModalProps extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  displayModal: boolean;
  width?: string;
  height?: string;
  left?: string;
  displayStyle?: string;
  flexDirection?: string;
  justifyContent?: string;
  alignItems?: string;
  closeModal?: () => void;
  modalCanBeClosed?: boolean;
}

export const Modal: FC<ModalProps> = ({
  children,
  displayModal,
  width,
  height,
  left,
  displayStyle,
  flexDirection,
  justifyContent,
  alignItems,
  closeModal,
  modalCanBeClosed,
  ...props
}) => {
  const handleContainerClick = () => {
    if (!modalCanBeClosed) {
      return;
    }

    closeModal();
  };

  const handleInnerDivClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const renderChildren = () => {
    if (!displayModal) return <></>;

    return children;
  };

  return (
    <Container
      $display={displayModal}
      onDoubleClick={handleContainerClick}
      data-testid={'click-out-container'}
      $displayStyle={displayStyle}
      $flexDirection={flexDirection}
      $justifyContent={justifyContent}
      $alignItems={alignItems}
    >
      <ModalContainer
        onDoubleClick={handleInnerDivClick}
        $display={displayModal}
        $left={left}
        $width={width}
        $height={height}
        $displayStyle={displayStyle}
        $flexDirection={flexDirection}
        $justifyContent={justifyContent}
        $alignItems={alignItems}
        {...props}
      >
        {renderChildren()}
      </ModalContainer>
    </Container>
  );
};
