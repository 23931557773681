import { FormContainer } from './styles';
import { FC, FormHTMLAttributes, useEffect, useState } from 'react';

interface FormProps extends FormHTMLAttributes<HTMLFormElement> {
  children: React.ReactNode;
}

export const FormDefault: FC<FormProps> = ({ children, ...props }) => {
  const [containerHeight, setContainerHeight] = useState<string>('100vh');

  useEffect(() => {
    const vh = window.innerHeight * 0.01;

    const rootFontSize = parseFloat(
      window.getComputedStyle(document.documentElement).fontSize
    );

    const minHeightInRemHeader = 2.2;

    if (6 * vh < minHeightInRemHeader * rootFontSize) {
      const dynamicHeight = `calc(100vh - ${minHeightInRemHeader}rem)`;
      setContainerHeight(dynamicHeight);
    } else {
      const dynamicHeight = `calc(100vh - 6vh)`;
      setContainerHeight(dynamicHeight);
    }
  }, []);

  return (
    <FormContainer $containerHeight={containerHeight} {...props}>
      {children}
    </FormContainer>
  );
};
