import styled, { css } from 'styled-components';

interface TextAreaInputProps {
  width?: string | number;
  height?: string | number;
  $isValid?: boolean;
  $isFocused?: boolean;
}

export const TextAreaInput = styled.textarea<TextAreaInputProps>`
  ${({ $isFocused, $isValid }) => css`
    width: 100%;
    height: calc(100% - 2rem);
    font-size: 0.9rem;
    padding: 0.5rem;
    border: 1px solid ${$isFocused ? '#000' : !$isValid ? 'red' : '#d9d9d9'};
    border-radius: 0.25rem;
    resize: none;
    outline: none;
  `}
`;
