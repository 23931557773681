import {
  FC,
  useCallback,
  useState,
  useEffect,
  HTMLAttributes,
  useContext,
} from 'react';
import { Models } from '@services/ms-core-trinus-pay/models';
import InternalBankAccount from '@services/ms-core-trinus-pay/controllers/internal-bank-account';
import { InputSelected } from '@components/form/form-inputs/input-selected';
import { SessionFormDefault } from '@components/form/session-form';
import { MdAccountBalance } from 'react-icons/md';
import AlertContext from '@context/alert-context/alertContext';

interface InternalAccountSessionsProps extends HTMLAttributes<HTMLDivElement> {
  internalBankAccountId?: number;
  onUpdate: (value: number) => void;
  errorMessage?: string;
  width?: string;
  errorForms?: { [key: string]: string };
  onErrorChange?: (value: string, field: string) => void;
  inputRef?: any;
}

export const InternalAccountSessions: FC<InternalAccountSessionsProps> = ({
  internalBankAccountId,
  onUpdate,
  errorMessage,
  width = '100%',
  errorForms,
  onErrorChange,
  inputRef,
  ...props
}) => {
  const alert = useContext(AlertContext);
  const [internalBankAccountData, setInternalBankAccountData] =
    useState<Models.InternalBankAccount>();
  const [searchResults, setSearchResults] = useState(undefined);
  const [isLoading, setIsLoading] = useState<boolean>();
  const [notFoundMsg, setNotFoundMsg] = useState<string>();

  const getName = (data: Models.InternalBankAccount) => {
    if (!data) {
      return;
    }

    return `N.º da conta: ${data?.accountNumberCode} AG: ${data?.bankBranchCode} - ${data?.interBKBenefFullName}`;
  };

  const findSelectedInternalBankAccountById = async () => {
    if (!internalBankAccountId) {
      handleRemoveSelectedValue();
      return;
    }

    setIsLoading(true);
    const result = await InternalBankAccount.findInternalBankAccountById(
      internalBankAccountId
    );

    if (result?.status !== 200) {
      setIsLoading(false);
      alert.error(result?.message || result?.response?.data?.message);
      return;
    }

    const data = {
      internaBankAccountId: result.data.internalBankAccountId,
      accountNumberCode: result.data.accountNumberCode,
      bankBranchCode: result.data.bankBranchCode,
      interBKBenefFullName: result.data.interBkBenefFullName,
    };

    setInternalBankAccountData(data);
    setIsLoading(false);
  };

  const handleFinalBeneficiarySearch = async (value: string) => {
    if (!value) {
      setNotFoundMsg(undefined);
      setSearchResults(undefined);
      return;
    }
    setIsLoading(true);

    const result = await InternalBankAccount.findInternalBankAccountByTerm(
      value
    );
    if (result?.status !== 200) {
      const msg = 'Nenhum resultado...';
      setNotFoundMsg(msg);
      setSearchResults(undefined);
      setIsLoading(false);
      return;
    }

    setNotFoundMsg(undefined);
    setSearchResults(result.data);
    setIsLoading(false);
  };

  const handleSelectedValue = useCallback(
    (
      e: React.MouseEvent<HTMLButtonElement>,
      internalBank: Models.InternalBankAccount
    ) => {
      e.preventDefault();
      setInternalBankAccountData(internalBank);
      setSearchResults(undefined);
      onUpdate?.(internalBank?.internaBankAccountId);
    },
    [onUpdate]
  );

  const handleRemoveSelectedValue = useCallback(() => {
    setInternalBankAccountData(undefined);
    setSearchResults(undefined);
    onUpdate?.(undefined);
  }, [onUpdate]);

  const renderSVGIcon = () => {
    return <MdAccountBalance fill="#1a6ce8" />;
  };

  useEffect(() => {
    findSelectedInternalBankAccountById();
  }, [internalBankAccountId]);

  const selectedValue = getName(internalBankAccountData);

  return (
    <SessionFormDefault subTitle="Banco Conveniado" width={width} {...props}>
      <InputSelected
        dataTest={`link-internal-account-to-final-beneficiary`}
        itemIcon={renderSVGIcon()}
        label="Conta interna"
        customPlaceholder={'Pesquisar por número da conta'}
        searchValue={handleFinalBeneficiarySearch}
        displayResult={getName}
        searchResults={searchResults}
        notFoundMsg={notFoundMsg}
        onSelectedValue={handleSelectedValue}
        selectedValue={selectedValue}
        onRemoveValue={handleRemoveSelectedValue}
        required
        isLoading={isLoading}
        errorMessage={errorMessage || errorForms?.internalBankAccountId}
        maxLength={50}
        inputRef={inputRef}
        onErrorChange={(value) => onErrorChange(value, 'internalBankAccountId')}
      />
    </SessionFormDefault>
  );
};
