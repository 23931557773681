import {
  ChangeEvent,
  FC,
  InputHTMLAttributes,
  useEffect,
  useState,
} from 'react';

import {
  DefaultErrorContainer,
  DefaultFieldFormsContainer,
  DefaultFormsLabel,
  DefaultSearchContainer,
} from '../../styles';
import { Input } from '@components/input-search/search-input';
import InfoTooltip from '@components/info-tooltip';
import useFormField from '@hooks/useFormField';

interface MainRegistrationInputProps
  extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  searchValue?: (value: string) => void;
  selectedValue?: string;
  onRemoveValue?: () => void;
  width?: string;
  height?: string;
  required?: boolean;
  errorMessage?: string;
  maxLength?: number;
  minLength?: number;
  isLoading?: boolean;
  dataTest: string;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onErrorChange?: (value: string) => void;
  disableSearch?: boolean;
  informative?: string;
}

export const MainRegistrationInput: FC<MainRegistrationInputProps> = ({
  label,
  width,
  height,
  searchValue,
  selectedValue,
  onRemoveValue,
  errorMessage,
  required,
  isLoading,
  minLength,
  maxLength,
  dataTest,
  onChange,
  onErrorChange,
  value = '',
  disableSearch,
  informative,
}) => {
  const [shouldValidate, setShouldValidate] = useState<boolean>(false);
  const {
    isFocused,
    isValid,
    handleOnBlur,
    handleOnFocus,
    setIsFocused,
    handleValidation,
  } = useFormField(errorMessage, onErrorChange);

  const handleSearchValue = (value: string) => {
    searchValue(value);
  };

  const handleRemove = () => {
    onRemoveValue?.();
    setIsFocused(false);
    handleValidation();
  };

  useEffect(() => {
    if (shouldValidate) {
      handleValidation();
    }
    setShouldValidate(true);
  }, [selectedValue]);

  return (
    <DefaultFieldFormsContainer
      width={width}
      height={height}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
      data-testid={`search-container-${dataTest}`}
    >
      {label && (
        <DefaultFormsLabel required={required}>
          {label}
          {required && <span>*</span>}
          <InfoTooltip
            message={informative}
            dataTestId={`info-tooltip-${dataTest}`}
            showInfo={!!informative}
          />
        </DefaultFormsLabel>
      )}
      <DefaultSearchContainer $isFocused={isFocused} $isValid={isValid}>
        <Input
          dataTest={dataTest}
          valueToSearch={handleSearchValue}
          selectedValue={selectedValue}
          isFocused={isFocused}
          isValid={isValid}
          onRemoveValue={handleRemove}
          maxLength={maxLength}
          isLoading={isLoading}
          minLength={minLength}
          searchOnAction
          onChange={onChange}
          value={value}
          disableSearch={disableSearch}
        />
      </DefaultSearchContainer>

      <DefaultErrorContainer $isValid={isValid}>
        <span>{!isFocused ? errorMessage : ''}</span>
      </DefaultErrorContainer>
    </DefaultFieldFormsContainer>
  );
};
