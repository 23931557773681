import { useState, FC, useEffect, ChangeEvent } from 'react';
import { Container } from '../styles';
import { Models } from '@services/ms-core-trinus-auth/models';
import { TextInput } from '@components/form/form-inputs/text-input';
import { FormTextAreaInput } from '@components/form/form-inputs/textarea-input';
import { SelectList } from '@components/form/select-list';
import { useForm } from '@hooks/useForm';

interface UseFormProps {
  entityData?: Models.AuthEntityData;
  onUpdate?: (entityData: Models.AuthEntityData) => void;
  errorForms?: { [key: string]: string };
  onErrorChange?: (value: string, field: string) => void;
}

export const UseForm: FC<UseFormProps> = ({
  entityData,
  onUpdate,
  errorForms,
  onErrorChange,
}) => {
  const [disableEntityStatusType, setDisableEntityStatusType] =
    useState<boolean>(true);
  const [entityStatusType, setEntityStatusType] = useState<string | null>(
    'Ativo'
  );

  const { onChangeTextInputValue } = useForm({ ...entityData }, onUpdate);

  const entityDataStatusType =
    entityData?.statusType === 'INA' ? 'Inativo' : 'Ativo';

  useEffect(() => {
    if (entityData?.entityId) {
      setEntityStatusType(entityDataStatusType);
      setDisableEntityStatusType(false);
    } else {
      setDisableEntityStatusType(true);
    }
  }, [entityData?.entityId]);

  useEffect(() => {
    const firstName = entityData?.firstName || '';
    const lastName = entityData?.lastName || '';

    const fullName =
      firstName && lastName
        ? `${firstName} ${lastName}`
        : `${firstName}${lastName}`;

    onUpdate({ ...entityData, fullName });
  }, [entityData?.firstName, entityData?.lastName]);

  const handleEntityStatus = (e: ChangeEvent<HTMLSelectElement>) => {
    setEntityStatusType(e.target.value);
    const statusType = e.target.value === 'Inativo' ? 'INA' : 'ACT';
    onUpdate({ ...entityData, statusType });
  };

  return (
    <Container>
      <TextInput
        value={entityData?.keyDescription}
        dataTest={`user-key-description-input`}
        label="Usuário"
        type="text"
        required={true}
        onChange={(e) => onChangeTextInputValue(e, 'keyDescription')}
        minLength={8}
        errorMessage={errorForms?.keyDescription}
        maxLength={300}
        onErrorChange={(value) => onErrorChange(value, 'keyDescription')}
      />
      <TextInput
        value={entityData?.firstName}
        dataTest={`user-first-name-input`}
        label="Nome"
        type="text"
        required={true}
        onChange={(e) => onChangeTextInputValue(e, 'firstName')}
        minLength={3}
        errorMessage={errorForms?.firstName}
        maxLength={50}
        onErrorChange={(value) => onErrorChange(value, 'firstName')}
      />
      <TextInput
        value={entityData?.lastName}
        dataTest={`user-last-name-input`}
        label="Sobrenome"
        type="text"
        required={true}
        onChange={(e) => onChangeTextInputValue(e, 'lastName')}
        minLength={3}
        errorMessage={errorForms?.lastName}
        maxLength={100}
        onErrorChange={(value) => onErrorChange(value, 'lastName')}
      />
      <TextInput
        value={entityData?.emailDescription}
        dataTest={`user-email-input`}
        label="E-mail"
        type="email"
        required={true}
        onChange={(e) => onChangeTextInputValue(e, 'emailDescription')}
        errorMessage={errorForms?.emailDescription}
        maxLength={256}
        onErrorChange={(value) => onErrorChange(value, 'emailDescription')}
      />
      <FormTextAreaInput
        value={entityData?.detailDescription}
        dataTest={`user-description-input`}
        label="Informações adicionais"
        height={'9rem'}
        onChange={(e: any) => onChangeTextInputValue(e, 'detailDescription')}
        maxLength={2000}
      />
      <SelectList
        width={'30%'}
        dataTest={`user-status-select`}
        label={'Status'}
        selectOptions={['Ativo', 'Inativo']}
        disabled={disableEntityStatusType}
        onChange={(e) => handleEntityStatus(e)}
        selectValue={entityStatusType}
      />
    </Container>
  );
};
