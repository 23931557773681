import styled from 'styled-components';

export const InfoIcon = styled.div<{ $infoSize: string }>`
  margin: 0;
  padding: 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: ${({ $infoSize }) => $infoSize};
  height: ${({ $infoSize }) => $infoSize};
  padding: 0.2rem;
  border: 1px solid #1a6ce8;

  color: #1a6ce8;
  font-size: ${({ $infoSize }) => `calc(0.8 * ${$infoSize})`};
  font-weight: bold;
`;

export const TooltipContainer = styled.div<{ $infoSize: string }>`
  position: relative;
  width: ${({ $infoSize }) => $infoSize};
  height: ${({ $infoSize }) => $infoSize};
`;

export const TooltipText = styled.div<{ $isVisible: boolean }>`
  position: absolute;
  flex-direction: column;

  visibility: ${({ $isVisible }) => ($isVisible ? 'visible' : 'hidden')};
  background-color: #1a6ce8;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 0.3rem;
  z-index: 1;
  white-space: nowrap;
`;
