import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0.625rem;

  .copy-button {
    appearance: none;
    border: none;
    padding: 0;
    background: none;
    outline: none;
  }
`;

const CredentialsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  padding: auto;
`;

export { Container, CredentialsContainer };
