import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
  border-radius: 0.5rem;
  text-align: start;
  padding: 1rem;

  h3 {
    margin-bottom: 1rem;
  }
`;
