import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/auth-context/AuthContext';
import { useState, useEffect, useContext } from 'react';
import { routes } from './routes';
import AlertContext from '../context/alert-context/alertContext';
import { GlobalAlert } from '../components/global-alert';
import { Loading } from '../components/loading';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import LoadingContext from '../context/loading-context/loadingContext';
import { LoadingModal } from '../components/loading/styles';

export const RoutesManager = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const alert = useContext(AlertContext);
  const loading = useContext(LoadingContext);

  const { permissions, hasPermission } = useAuth();

  const allRoutesNames = Object.keys(routes);
  const [unauthorizedRoutes, setUnauthorizedRoutes] = useState<string[]>([]);

  useEffect(() => {
    if (unauthorizedRoutes.includes(location.pathname)) {
      navigate('/app');
    }
  }, [location]);

  const renderRoute = (routeKey: string) => {
    const checkPermission = hasPermission(permissions, routes[routeKey]);

    if (!checkPermission) {
      if (!unauthorizedRoutes.includes(routes[routeKey].route)) {
        setUnauthorizedRoutes([...unauthorizedRoutes, routes[routeKey].route]);
      }

      return <Route path={routes[routeKey].route} key={routeKey} />;
    }

    return (
      <Route
        path={routes[routeKey].route}
        key={routeKey}
        Component={routes[routeKey].component}
      />
    );
  };

  return (
    <>
      <Routes>
        {allRoutesNames.map((routeName: string) => renderRoute(routeName))}
      </Routes>
      <GlobalAlert $showAlert={alert.showAlert} />
      <LoadingModal
        data-testid={'loading-data-modal'}
        displayModal={loading.showLoading}
        modalCanBeClosed={false}
        width='5rem'
        height='rem'
      >
        <Loading
          color='#0b5dd7'
          icon={faSpinner}
          spin
          size='2xl'
        />
        <span>Carregando...</span>
      </LoadingModal>
    </>
  );
};
