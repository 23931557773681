import { ChangeEvent, FC, TextareaHTMLAttributes } from 'react';
import { TextAreaInput } from './styles';
import {
  DefaultErrorContainer,
  DefaultFieldFormsContainer,
  DefaultFormsLabel,
} from '../../styles';
import InfoTooltip from '@components/info-tooltip';
import useFormField from '@hooks/useFormField';

interface FormTextAreaInputProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  value: string;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onErrorChange?: (value: string) => void;
  label?: string;
  placeholder?: string;
  width?: string | number;
  height?: string | number;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  errorMessage?: string;
  dataTest: string;
  informative?: string;
}

export const FormTextAreaInput: FC<FormTextAreaInputProps> = ({
  label,
  placeholder,
  width,
  height,
  required,
  value,
  onChange,
  minLength,
  maxLength,
  errorMessage,
  dataTest,
  informative,
  onErrorChange,
  ...props
}) => {
  const { isFocused, isValid, handleOnBlur, handleOnFocus } = useFormField(
    errorMessage,
    onErrorChange
  );

  return (
    <DefaultFieldFormsContainer
      width={width}
      height={height}
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      {label && (
        <DefaultFormsLabel required={required}>
          {label}
          {required && <span>*</span>}
          <InfoTooltip
            message={informative}
            dataTestId={`info-tooltip-${dataTest}`}
            showInfo={!!informative}
          />
        </DefaultFormsLabel>
      )}

      <TextAreaInput
        data-testid={dataTest}
        value={value}
        $isValid={isValid}
        $isFocused={isFocused}
        onFocus={() => handleOnFocus()}
        onBlur={() => handleOnBlur()}
        onChange={(e) => onChange(e)}
        minLength={minLength}
        maxLength={maxLength}
        placeholder={placeholder}
        {...props}
      />

      <DefaultErrorContainer $isValid={isValid}>
        <span>{!isFocused ? errorMessage : ''}</span>
      </DefaultErrorContainer>
    </DefaultFieldFormsContainer>
  );
};
