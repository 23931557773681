import { FC, HTMLAttributes, useState, useContext } from 'react';
import { SessionFormDefault } from '@components/form/session-form';
import { TextInput } from '@components/form/form-inputs/text-input';
import { FlexWrapFormContainer } from '@components/form/styles';
import { Models } from '@services/ms-core-trinus-pay/models';
import { useFormContext } from '@context/form-context/FormContext';
import { MainRegistrationInput } from '@components/form/form-inputs/main-registration-input';
import FinalBeneficiaryService from '@services/ms-core-trinus-pay/controllers/final-beneficiary';
import AlertContext from '@context/alert-context/alertContext';
import Library from '@utils/Library';
import { OptionsModal } from '@components/modal/options-modal';
import DefaultButton from '@components/default-button';

interface BasicDataSessionProps extends HTMLAttributes<HTMLDivElement> {
  finalBeneficiaryData: Models.FinalBeneficiaryData;
  width?: string;
  height?: string;
  errorForms?: { [key: string]: string };
}

export const BasicDataSession: FC<BasicDataSessionProps> = ({
  finalBeneficiaryData,
  width = '100%',
  height,
  errorForms,
  ...props
}) => {
  const {
    onChangeTextInputValue,
    onChangeMainRegistration,
    onChangeStateType,
    onChangeZipCode,
    errorMessageInputMainRegistration,
    formatZipCode,
    errorMessageInputZipCode,
    handleUpdate,
    formattedMainRegistration,
    onErrorChange,
  } = useFormContext();

  const alert = useContext(AlertContext);

  const [isLoadingCoreBank, setIsLoadingCoreBank] = useState<boolean>(false);
  const [showCoreBankModal, setShowCoreBankModal] = useState<boolean>(false);
  const [coreBankData, setCoreBankData] =
    useState<Models.CoreBankAccountData[]>();
  const [selectedCoreBankAccount, setSelectedCoreBankAccount] =
    useState(undefined);

  const searchCoreBankByMainRegistration = async (mainRegistration: string) => {
    if (!Library.validateCPF(mainRegistration) && !Library.validateCNPJ(mainRegistration)) {
      return;
    }

    setIsLoadingCoreBank(true);

    const mainRegIdWithoutMask = Library.onlyNumbers(mainRegistration);

    const result =
      await FinalBeneficiaryService.getCoreBankingAccountByDocumentNumber(
        mainRegIdWithoutMask
      );

    if (result?.status !== 200 && result?.status !== 204) {
      setIsLoadingCoreBank(false);
      alert.error(result?.message || result?.response?.data?.message);
      return;
    }

    if (result?.status === 204) {
      setIsLoadingCoreBank(false);
      alert.error('Nenhuma conta encontrada para o CPF/CNPJ informado.');
      return;
    }

    handleResultData(result.data);
    setIsLoadingCoreBank(false);
  };

  const handleResultData = (data: Models.CoreBankAccountData[]) => {
    const accountDataToShow: any[] = [];
    for (const account of data) {
      const formattedResult =
        formattedMainRegistration(account?.mainRegistrationId) ||
        account?.mainRegistrationId;

      accountDataToShow.push({
        'Id Core Bancário': account?.accountId,
        'Razão Social': account?.beneficiaryName,
        'CPF/CNPJ': formattedResult,
        'E-mail': account?.email,
      });
    }

    if (accountDataToShow.length === 1) {
      setSelectedCoreBankAccount(accountDataToShow[0]);
    }

    setCoreBankData(accountDataToShow);
    setShowCoreBankModal(true);
  };

  const removeSelectedMainRegistration = () => {
    setSelectedCoreBankAccount(undefined);
    handleUpdate('', 'coreBankingAccountId');
  };

  const handleCoreBankAccountConfirmed = () => {
    if (!selectedCoreBankAccount) {
      alert.warning('É necessário selecionar 1 conta.');
      return;
    }

    handleUpdate(
      selectedCoreBankAccount['Id Core Bancário'],
      'coreBankingAccountId'
    );

    setShowCoreBankModal(false);
    alert.clear();
  };

  const handleCloseOptionModal = () => {
    setSelectedCoreBankAccount(undefined);
    setShowCoreBankModal(false);
  };

  return (
    <>
      <SessionFormDefault subTitle="Dados básicos" width={width} {...props}>
        <MainRegistrationInput
          label="CPF/CNPJ"
          searchValue={searchCoreBankByMainRegistration}
          selectedValue={
            finalBeneficiaryData?.mainRegistrationId &&
            finalBeneficiaryData?.coreBankingAccountId
              ? finalBeneficiaryData?.mainRegistrationId
              : ''
          }
          onRemoveValue={removeSelectedMainRegistration}
          errorMessage={
            errorMessageInputMainRegistration || errorForms?.mainRegistrationId
          }
          required
          maxLength={50}
          isLoading={isLoadingCoreBank}
          dataTest={`final-beneficiary-main-registration-input`}
          value={finalBeneficiaryData?.mainRegistrationId ?? ''}
          onChange={(e) => onChangeMainRegistration(e, 'mainRegistrationId')}
          onErrorChange={(value) => onErrorChange(value, 'mainRegistrationId')}
          disableSearch={
            !!errorMessageInputMainRegistration ||
            !finalBeneficiaryData?.mainRegistrationId
          }
        />
        <TextInput
          dataTest={`final-beneficiary-name-input`}
          type="text"
          value={finalBeneficiaryData?.beneficiaryName ?? ''}
          maxLength={120}
          label="Nome"
          required
          onChange={(e) => onChangeTextInputValue(e, 'beneficiaryName')}
          errorMessage={errorForms?.beneficiaryName}
          onErrorChange={(value) => onErrorChange(value, 'beneficiaryName')}
        />
        <TextInput
          dataTest={`final-beneficiary-address-input`}
          type="text"
          value={finalBeneficiaryData?.addressDescription ?? ''}
          maxLength={100}
          label="Endereço"
          onChange={(e) => onChangeTextInputValue(e, 'addressDescription')}
          required
          errorMessage={errorForms?.addressDescription}
          onErrorChange={(value) => onErrorChange(value, 'addressDescription')}
        />
        <TextInput
          dataTest={`final-beneficiary-neighborhood-input`}
          type="text"
          value={finalBeneficiaryData?.neighborhoodName ?? ''}
          maxLength={100}
          label="Bairro"
          onChange={(e) => onChangeTextInputValue(e, 'neighborhoodName')}
          required
          errorMessage={errorForms?.neighborhoodName}
          onErrorChange={(value) => onErrorChange(value, 'neighborhoodName')}
        />
        <FlexWrapFormContainer $justifyContent="space-between">
          <TextInput
            dataTest={`final-beneficiary-city-input`}
            type="text"
            label="Cidade"
            maxLength={100}
            value={finalBeneficiaryData?.cityName ?? ''}
            width="50%"
            onChange={(e) => onChangeTextInputValue(e, 'cityName')}
            required
            errorMessage={errorForms?.cityName}
            onErrorChange={(value) => onErrorChange(value, 'cityName')}
          />
          <TextInput
            dataTest={`final-beneficiary-federation-state-input`}
            type="text"
            label="Estado"
            maxLength={2}
            value={finalBeneficiaryData?.stateType ?? ''}
            width="50%"
            onChange={(e) => onChangeStateType(e, 'stateType')}
            required
            errorMessage={errorForms?.stateType}
            onErrorChange={(value) => onErrorChange(value, 'stateType')}
          />
          <TextInput
            dataTest={`final-beneficiary-postal-code-input`}
            type="text"
            label="CEP"
            maxLength={9}
            value={formatZipCode('zipCode') ?? ''}
            width="50%"
            onChange={(e) => onChangeZipCode(e, 'zipCode')}
            errorMessage={errorMessageInputZipCode || errorForms?.zipCode}
            required
            onErrorChange={(value) => onErrorChange(value, 'zipCode')}
          />
        </FlexWrapFormContainer>
      </SessionFormDefault>
      {!showCoreBankModal ? (
        <></>
      ) : (
        <OptionsModal
          data-testid="core-bank-account-options-modal"
          displayModal={showCoreBankModal}
          $width={'25rem'}
          $height={'auto'}
          justifyChildrenContent="flex-end"
          modalHasCheckBoxes={true}
          modalOptions={coreBankData}
          optionSelected={selectedCoreBankAccount}
          onCheckBoxChange={setSelectedCoreBankAccount}
          onClick={setSelectedCoreBankAccount}
          required={false}
        >
          <DefaultButton
            dataTest="options-modal-cancel-button"
            width={'6rem'}
            height={'2rem'}
            label="Cancelar"
            title="Cancelar"
            onClick={handleCloseOptionModal}
            style={{
              backgroundColor: 'white',
              color: '#1a6ce8',
            }}
          />
          <DefaultButton
            dataTest="options-modal-confirm-button"
            width={'6rem'}
            height={'2rem'}
            label="Confirmar"
            title="Confirmar"
            onClick={handleCoreBankAccountConfirmed}
          />
        </OptionsModal>
      )}
    </>
  );
};
