import { ChangeEvent, useState, useEffect } from 'react';
import Library from '../../utils/Library';

export const useForm = (
  formData?: { [key: string]: string | number },
  onUpdate?: (data: { [key: string]: string | number }) => void
) => {
  const [
    errorMessageInputMainRegistration,
    setErrorMessageInputMainRegistration,
  ] = useState<string>('');

  const [errorMessageInputZipCode, setErrorMessageInputZipCode] =
    useState<string>('');
  const [statusType, setStatusType] = useState<string>('Ativo');
  const [personType, setPersonType] = useState<string>('Jurídica');
  const [accountType, setAccountType] = useState<string>('Conta Corrente');
  const [disableStatusType, setDisableStatusType] = useState<boolean>(true);
  const [errorForms, setErrorForms] = useState<{ [key: string]: string }>();

  useEffect(() => {
    if (formData?.internalBankAccountId) {
      setDisableStatusType(false);
    } else {
      setDisableStatusType(true);
    }
  }, [formData]);

  const handleUpdate = (value: string, field: string) => {
    if (value?.length === 0) {
      onUpdate({ ...formData, [field]: null });
      return;
    }

    onUpdate({ ...formData, [field]: value });
  };

  const onChangeTextInputValue = (
    e: ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    const newTextInputValue = e.target.value;
    handleUpdate(newTextInputValue, field);
  };

  const formattedMainRegistration = (mainRegistration?: string) => {
    const mainRegistrationClean = mainRegistration?.replace(/\D/g, '');
    let result = '';

    if (
      mainRegistrationClean?.length > 3 &&
      mainRegistrationClean?.length <= 6
    ) {
      result = mainRegistrationClean.replace(/(\d{3})(\d{0,3})/, '$1.$2');
    } else if (mainRegistrationClean?.length <= 9) {
      result = mainRegistrationClean.replace(
        /(\d{3})(\d{3})(\d{0,3})/,
        '$1.$2.$3'
      );
    } else if (mainRegistrationClean?.length <= 11) {
      result = mainRegistrationClean.replace(
        /(\d{3})(\d{3})(\d{3})(\d{0,2})/,
        '$1.$2.$3-$4'
      );
    } else if (mainRegistrationClean?.length <= 12) {
      result = mainRegistrationClean.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})/,
        '$1.$2.$3/$4'
      );
    } else if (mainRegistrationClean?.length <= 14) {
      result = mainRegistrationClean.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{0,2})/,
        '$1.$2.$3/$4-$5'
      );
    }

    return result;
  };

  const onChangeMainRegistration = (
    e: ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    const newMainRegistration = e.target.value.replace(/\D/g, '');

    const formattedResult = formattedMainRegistration(newMainRegistration);

    const errorMessage = `${formattedResult} não é um registro válido`;

    handleUpdate(formattedResult, field);

    const clearMainRegistration = newMainRegistration.replace(/\D/g, '');

    if (
      (clearMainRegistration.length === 11 &&
        Library.validateCPF(clearMainRegistration)) ||
      (clearMainRegistration.length === 14 &&
        Library.validateCNPJ(clearMainRegistration)) ||
      clearMainRegistration.length === 0
    ) {
      setErrorMessageInputMainRegistration('');
    } else {
      setErrorMessageInputMainRegistration(errorMessage);
    }
  };

  const onChangeStateType = (
    e: ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    const inputStateType = e.target.value
      .toLocaleUpperCase()
      .replace(/[^A-Z]/g, '');

    const validStates = [
      'AC',
      'AL',
      'AP',
      'AM',
      'BA',
      'CE',
      'DF',
      'ES',
      'GO',
      'MA',
      'MT',
      'MS',
      'MG',
      'PA',
      'PB',
      'PR',
      'PE',
      'PI',
      'RJ',
      'RN',
      'RS',
      'RO',
      'RR',
      'SC',
      'SP',
      'SE',
      'TO',
    ];

    const validInitials = validStates
      .map((state) => state[0])
      .filter((initial, index, array) => array.indexOf(initial) === index);

    if (
      inputStateType.length === 0 ||
      (inputStateType.length === 1 && validInitials.includes(inputStateType)) ||
      (inputStateType.length === 2 && validStates.includes(inputStateType))
    ) {
      handleUpdate(inputStateType, field);
    }
  };

  const onChangeZipCode = (e: ChangeEvent<HTMLInputElement>, field: string) => {
    const newZipCode = Library.onlyNumbers(e.target.value);
    const newZipCodeLength = newZipCode?.length;

    if (!newZipCode || newZipCodeLength === 0) {
      setErrorMessageInputZipCode('');
      handleUpdate(newZipCode, field);
      return;
    } else if (newZipCodeLength === 8) {
      setErrorMessageInputZipCode('');
    } else {
      setErrorMessageInputZipCode(`${newZipCode} não é um registro válido`);
    }

    handleUpdate(Library.onlyNumbers(newZipCode), field);
  };

  const formatZipCode = (field: string) => {
    const formDataZipCode = formData?.[field]?.toString();

    return formDataZipCode?.replace(/(\d{5})(\d{1,3})/, '$1-$2');
  };

  const loadValueWithCents = (value: number | string) => {
    const numericalValue = Number(value);
    if (numericalValue >= 0) {
      return `${numericalValue.toFixed(2)}`;
    }

    return `0.00`;
  };

  const validateRequiredFields = (
    data: { [key: string]: string | number },
    formRequiredFields: string[]
  ): { isValid: boolean; errors: { [key: string]: string } } => {
    let isValid = true;
    let errors = {};
    const requiredFields = formRequiredFields;
    for (const field of requiredFields) {
      if (!data[field]) {
        isValid = false;
        errors = { ...errors, [field]: 'O campo é obrigatório' };
      }
    }
    return { isValid, errors };
  };

  const validateFeeFields = (
    data: { [key: string]: string | number },
    booleanFields: { field: string; associatedField: string }[]
  ): { isValid: boolean; errors: { [key: string]: string } } => {
    const expectedValue = ['Y', 'N'];
    let isValid = true;
    let errors = {};

    for (const { field, associatedField } of booleanFields) {
      if (!data[field] || !expectedValue.includes(data[field]?.toString())) {
        isValid = false;
        errors = { ...errors, [field]: 'Valor fora do esperado' };
      }

      if (data[associatedField]) {
        const value = Number(data[associatedField]);
        if (data[field] === 'Y' && value <= 0) {
          isValid = false;
          errors = {
            ...errors,
            [associatedField]: 'Tarifa habilitada requer taxa maior que zero.',
          };
        }
        if (data[field] === 'N' && value !== 0) {
          isValid = false;
          errors = {
            ...errors,
            [associatedField]: 'Tarifa desabilitada requer taxa zero.',
          };
        }
      }
    }

    return { isValid, errors };
  };

  const isValidMainRegistrationId = (mainRegistrationId: string): boolean => {
    if (!mainRegistrationId) return false;
    return (
      Library.validateCPF(mainRegistrationId) ||
      Library.validateCNPJ(mainRegistrationId)
    );
  };

  const handlePersonType = (field: string) => {
    if (formData?.[field] === 'NAT') {
      setPersonType('Física');
    }

    if (formData?.[field] === 'LEG') {
      setPersonType('Jurídica');
    }
  };

  const handleStatusType = (field: string) => {
    if (formData?.[field] === 'INA') {
      setStatusType('Inativo');
    }

    if (formData?.[field] === 'ACT') {
      setStatusType('Ativo');
    }
  };

  const handleAccountType = (field: string) => {
    if (formData?.[field] === 'SAV' || formData?.[field] === 'SVGS') {
      setAccountType('Conta Poupança');
    }

    if (formData?.[field] === 'CUR' || formData?.[field] === 'CACC') {
      setAccountType('Conta Corrente');
    }
  };

  const handlePersonTypeChangeSelect = (value: string, field: string) => {
    setPersonType(value);

    let personTypeAbreviation = 'LEG';
    if (value === 'Física') {
      personTypeAbreviation = 'NAT';
    }

    handleUpdate(personTypeAbreviation, field);
  };

  const handleStatusTypeChangeSelect = (
    e: ChangeEvent<HTMLSelectElement>,
    field: string
  ) => {
    setStatusType(e.target.value);

    let statusTypeAbreviation = 'ACT';
    if (e.target.value === 'Inativo') {
      statusTypeAbreviation = 'INA';
    }

    handleUpdate(statusTypeAbreviation, field);
  };

  const handleAccountTypeChangeSelect = (
    e: ChangeEvent<HTMLSelectElement>,
    field: string
  ) => {
    setAccountType(e.target.value);

    let accountTypeAbreviation: string;
    if (e.target.value === 'Conta Poupança' && formData?.beneficiaryName) {
      accountTypeAbreviation = 'SVGS';
    }

    if (e.target.value === 'Conta Poupança' && formData?.interBkBenefFullName) {
      accountTypeAbreviation = 'SAV';
    }

    if (e.target.value === 'Conta Corrente' && formData?.beneficiaryName) {
      accountTypeAbreviation = 'CACC';
    }

    if (e.target.value === 'Conta Corrente' && formData?.interBkBenefFullName) {
      accountTypeAbreviation = 'CUR';
    }

    handleUpdate(accountTypeAbreviation, field);
  };

  const handleChangeOnlyNumbers = (
    e: ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    const newValue = e.target.value.replace(/\D/g, '');
    handleUpdate(newValue, field);
  };

  const handleChangeWithoutSpecialCharacters = (
    e: ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    const newValue = e.target.value.replace(/[^a-zA-Z0-9-]/g, '');
    handleUpdate(newValue, field);
  };

  const validationRequiredFieldsForm = (
    data: { [key: string]: string | number },
    requiredFields: string[],
    formErrorsFound: { [key: string]: string }
  ) => {
    let isValid = true;

    for (const field of requiredFields) {
      if (!data?.[field]) {
        formErrorsFound = {
          ...formErrorsFound,
          [field]: 'O campo é obrigatório',
        };

        isValid = false;
      }
    }

    const validationRequired = validateRequiredFields(data, requiredFields);

    if (!validationRequired.isValid) {
      formErrorsFound = { ...formErrorsFound, ...validationRequired.errors };
      isValid = false;
    }

    return {
      isValid,
      formErrorsFound,
    };
  };

  const onErrorChange = (value: string, field: string) => {
    setErrorForms({
      ...errorForms,
      [field]: value,
    });
  };

  return {
    accountType,
    disableStatusType,
    errorForms,
    errorMessageInputMainRegistration,
    errorMessageInputZipCode,
    formattedMainRegistration,
    formatZipCode,
    handleAccountType,
    handleAccountTypeChangeSelect,
    handleChangeOnlyNumbers,
    handleChangeWithoutSpecialCharacters,
    handlePersonType,
    handlePersonTypeChangeSelect,
    handleStatusType,
    handleStatusTypeChangeSelect,
    handleUpdate,
    isValidMainRegistrationId,
    loadValueWithCents,
    onChangeMainRegistration,
    onChangeStateType,
    onChangeTextInputValue,
    onChangeZipCode,
    onErrorChange,
    personType,
    setAccountType,
    setDisableStatusType,
    setErrorForms,
    setErrorMessageInputMainRegistration,
    setErrorMessageInputZipCode,
    setPersonType,
    setStatusType,
    statusType,
    validateFeeFields,
    validateRequiredFields,
    validationRequiredFieldsForm,
  };
};
