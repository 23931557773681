export const grantsDescriptions = {
  'core_auth.entity.common.LOGIN': 'Acesso à autenticação comum.',
  'core_auth.entity.user.MAINTAIN_ANY': 'Manutenção de usuários.',
  'core_auth.entity.user.REFRESH_ANY_KEY':
    'Atualização de chave de qualquer usuário.',
  'core_auth.entity.user.REFRESH_ANY_SECRET':
    'Atualização de segredo de qualquer usuário.',
  'core_auth.entity.user.REFRESH_KEY': 'Atualização de chave do próprio usuário.',
  'core_auth.entity.application.MAINTAIN_ANY': 'Manutenção de aplicações.',
  'core_auth.entity.application.REFRESH_ANY_KEY':
    'Atualização de chave de qualquer aplicação.',
  'core_auth.entity.application.REFRESH_ANY_SECRET':
    'Atualização de segredo de qualquer aplicação.',
  'core_auth.entity.application.REFRESH_KEY':
    'Atualização de chave da própria aplicação.',
  'core_auth.grant.MAINTAIN_ANY': 'Manutenção de permissões.',
  'core_auth.grant.ALLOW': 'Conceder permissões.',
  'core_auth.grant.REVOKE': 'Revogar permissões.',
  'core_auth.grant.LIST': 'Listar permissões concedidas.',
  'core_auth.group.MAINTAIN_ANY': 'Manutenção de grupos.',
  'core_pay.final_beneficiary.MAINTAIN': 'Manutenção de beneficiários finais.',
  'core_pay.final_beneficiary.GET':
    'Consultar informações de beneficiários finais.',
  'core_pay.final_beneficiary.ACTIVATE': 'Ativar beneficiários finais.',
  'core_pay.final_beneficiary.INACTIVATE': 'Desativar beneficiários finais.',
  'core_pay.internal_bank_account.MAINTAIN':
    'Manutenção de contas bancárias internas.',
  'core_pay.internal_bank_account.GET':
    'Consultar informações de contas bancárias internas.',
  'core_pay.bank_slip.POST': 'Criação de boletos bancários.',
  'core_pay.bank_slip.GET_ANY':
    'Consultar informações de qualquer boleto bancário.',
  'core_pay.bank_slip.GET':
    'Consultar informações de boletos bancários próprios.',
  'core_pay.bank_slip.PUT': 'Atualizar informações de boletos bancários.',
  'core_pay.core_banking.transact_moviments.POST':
    'Criação de movimentos de transação bancária.',
  'core_pay.core_banking.transact_moviments.GET':
    'Consultar informações de movimentos de transação bancária.',
  'core_pay.core_banking.cash_in.POST':
    'Depósito de dinheiro em conta bancária.',
  'core_pay.core_banking.cash_out.POST': 'Saque de dinheiro de conta bancária.',
  'core_pay.bank_slip.PUT_INVOICE_DUE_DATE':
    'Atualização da data de vencimento do boleto.',
  'core_pay.bank_slip.PUT_DOCUMENT_AMOUNT':
    'Atualização do valor do documento no boleto.',
  'core_pay.bank_slip.PUT_INTEREST': 'Atualização de juros no boleto.',
  'core_pay.bank_slip.PUT_FINE': 'Atualização de multas no boleto.',
  'core_pay.bank_slip.PUT_DISCOUNT': 'Atualização de descontos no boleto.',
  'core_pay.bank_slip.PATCH_CANCEL': 'Cancelamento de boletos bancários.',
  'core_pay.grant.MAINTAIN_ANY': 'Manutenção de permissões.',
  'core_pay.schedules.POST': 'Criação de agendamentos.',
  'core_pay.grant.ALLOW': 'Conceder permissões.',
  'core_pay.grant.REVOKE': 'Revogar permissões.',
  'core_pay.grant.LIST': 'Listar permissões concedidas.',
  'core_pay_broker.grant.MAINTAIN_ANY': 'Manutenção de permissões.',
  'core_pay_broker.grant.ALLOW': 'Conceder permissões.',
  'core_pay_broker.grant.REVOKE': 'Revogar permissões.',
  'core_pay_broker.grant.LIST': 'Listar permissões.',
  'core_pldft.grant.MAINTAIN_ANY': 'Manutenção de permissões.',
  'core_pldft.grant.ALLOW': 'Conceder permissões.',
  'core_pldft.grant.REVOKE': 'Revogar permissões.',
  'core_pldft.grant.LIST': 'Listar permissões concedidas.',
  'core_people.grant.MAINTAIN_ANY': 'Manutenção de permissões.',
  'core_people.grant.ALLOW': 'Conceder permissões.',
  'core_people.grant.REVOKE': 'Revogar permissões.',
  'core_people.grant.LIST': 'Listar permissões concedidas.',
};
