import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { TextInput } from '@components/form/form-inputs/text-input';
import {
  DefaultFieldFormsContainer,
  DefaultFormsLabel,
  FlexWrapFormContainer,
} from '../../styles';
import { Checkbox } from '@components/form/form-inputs/check-box';
import InfoTooltip from '@components/info-tooltip';
import { InputAlignmentWrapper } from './styles';

interface CheckboxInputProps {
  checked: boolean;
  dataTest: string;
  errorMessage?: string;
  informative?: string;
  inputValue: number | string;
  label?: string;
  onChange?: (checked: boolean, inputValue: string) => void;
  onErrorChange?: (value: string) => void;
  placeholder?: string;
  required?: boolean;
  width?: string;
}

export const CheckboxInput: React.FC<CheckboxInputProps> = ({
  checked,
  dataTest,
  errorMessage,
  informative,
  inputValue,
  label,
  onChange,
  onErrorChange,
  placeholder,
  required,
  width,
}) => {
  const [displayedErrorMessage, setDisplayedErrorMessage] =
    useState<string>('');
  const inputTextRef = useRef<HTMLInputElement>(null);

  const handleValidation = () => {
    if (required && !inputValue) {
      setDisplayedErrorMessage('O campo é obrigatório');
    } else if (errorMessage) {
      setDisplayedErrorMessage(errorMessage);
    } else {
      setDisplayedErrorMessage('');
    }
  };

  useEffect(() => {
    handleValidation();
  }, [errorMessage]);

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newCheckedValue = e.target.checked;
    onErrorChange?.(``);
    if (!newCheckedValue) {
      onChange?.(newCheckedValue, '0');
      return;
    }

    if (inputTextRef?.current) {
      inputTextRef.current.focus();
    }
    onChange?.(newCheckedValue, String(inputValue));
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    let newInputValue = e.target.value;
    if (newInputValue?.length === 0) {
      onChange?.(checked, '0');
    } else {
      onChange?.(checked, newInputValue);
    }
  };

  const onFocusInput = () => {
    setDisplayedErrorMessage('');
  };

  return (
    <DefaultFieldFormsContainer width={width}>
      {label && (
        <DefaultFormsLabel required>
          {label}
          {required && <span>*</span>}
          <InfoTooltip
            message={informative}
            dataTestId={`info-tooltip-${dataTest}`}
            showInfo={!!informative}
          />
        </DefaultFormsLabel>
      )}
      <FlexWrapFormContainer
        $flexDirection="row"
        $flexWrapType="nowrap"
        $justifyContent="space-between"
        $alignItems="flex-start"
      >
        <InputAlignmentWrapper>
          <Checkbox
            dataTestId={`checkbox-${dataTest}`}
            checked={checked}
            onChange={handleCheckboxChange}
            required={required}
          />
        </InputAlignmentWrapper>

        <TextInput
          dataTest={`input-${dataTest}`}
          ref={inputTextRef}
          type="text"
          value={String(inputValue)}
          onChange={handleInputChange}
          onFocus={onFocusInput}
          onBlur={handleValidation}
          readOnly={!checked}
          placeholder={placeholder}
          errorMessage={displayedErrorMessage}
          width={'calc(100% - 1.5rem)'}
          maxLength={14}
          displayStyle="flex"
          directionStyle="column"
          onErrorChange={(value) => onErrorChange?.(value)}
        />
      </FlexWrapFormContainer>
    </DefaultFieldFormsContainer>
  );
};
