import { useState, useEffect } from 'react';

const useFormField = (
  errorMessage: string | undefined,
  onErrorChange: ((value: string) => void) | undefined
) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(true);

  const handleValidation = () => {
    if (errorMessage) {
      setIsValid(false);
    } else {
      onErrorChange?.('');
      setIsValid(true);
    }
  };

  useEffect(() => {
    handleValidation();
  }, [errorMessage]);

  const handleOnBlur = () => {
    setIsFocused(false);
    handleValidation();
  };

  const handleOnFocus = () => {
    setIsFocused(true);
    onErrorChange?.('');
    setIsValid(true);
  };

  return {
    isFocused,
    isValid,
    handleOnBlur,
    handleOnFocus,
    handleValidation,
    setIsFocused,
    setIsValid,
  };
};

export default useFormField;
