// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Asap', sans-serif;
}

label,
input,
span,
div,
h1,
h2 {
  font-family: 'Asap', sans-serif;
}

input::placeholder {
  font-family: 'Asap', sans-serif;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;EACtB,+BAA+B;AACjC;;AAEA;;;;;;EAME,+BAA+B;AACjC;;AAEA;EACE,+BAA+B;AACjC","sourcesContent":["* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  font-family: 'Asap', sans-serif;\n}\n\nlabel,\ninput,\nspan,\ndiv,\nh1,\nh2 {\n  font-family: 'Asap', sans-serif;\n}\n\ninput::placeholder {\n  font-family: 'Asap', sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
