import { FC, HTMLAttributes } from 'react';
import { SessionFormDefault } from '@components/form/session-form';
import { FlexWrapFormContainer } from '@components/form/styles';
import { Models } from '@services/ms-core-trinus-pay/models';
import { CheckboxInput } from '@components/form/form-inputs/check-box-input';

interface TaxBankSlipSessionProps extends HTMLAttributes<HTMLDivElement> {
  data: Models.FinalBeneficiaryData | Models.InternalBankAccountData;
  onUpdate: (
    data: Models.FinalBeneficiaryData | Models.InternalBankAccountData
  ) => void;
  width?: string;
  height?: string;
  type: string;
  errorForms?: { [key: string]: string };
  onErrorChange?: (Value: string, field: string) => void;
}

export const TaxBankSlipSession: FC<TaxBankSlipSessionProps> = ({
  data,
  onUpdate,
  width = '100%',
  height,
  type,
  errorForms,
  onErrorChange,
  ...props
}) => {
  const onChangeTax = (
    checked: boolean,
    inputValue: string,
    checkedKey: string,
    valueKey: string
  ) => {
    const newCalcFeeBoolean = checked ? 'Y' : 'N';
    onUpdate({
      ...data,
      [checkedKey]: newCalcFeeBoolean,
      [valueKey]: monetaryMask(inputValue),
    });
  };

  const monetaryMask = (value: string | number) => {
    const stringValue = value?.toString()?.replace(/\D/g, '');
    const numericalValue = Number(stringValue);
    if (numericalValue >= 0) {
      const newValue = (numericalValue / 100).toFixed(2);
      return newValue.replace(',', '.');
    }

    return '0.00';
  };

  const isChecked = (key: string) => data?.[key] === 'Y';

  return (
    <SessionFormDefault
      subTitle="Taxas operacionais de boletos"
      width={width}
      {...props}
    >
      <FlexWrapFormContainer $justifyContent="space-between">
        <CheckboxInput
          dataTest={`enable-registration-fee-value-for-${type}`}
          width="50%"
          label="Registro"
          onChange={(checked, inputValue) =>
            onChangeTax(
              checked,
              inputValue,
              'calcRegistrationFeeBoolean',
              'registrationFeeAmount'
            )
          }
          errorMessage={errorForms?.registrationFeeAmount}
          onErrorChange={(value: string) =>
            onErrorChange?.(value, 'registrationFeeAmount')
          }
          checked={isChecked('calcRegistrationFeeBoolean')}
          inputValue={monetaryMask(data?.registrationFeeAmount)}
        />
        <CheckboxInput
          dataTest={`enable-maintenance-fee-value-for-${type}`}
          width="50%"
          label="Manutenção"
          onChange={(checked, inputValue) =>
            onChangeTax(
              checked,
              inputValue,
              'calcMaintenanceFeeBoolean',
              'maintenanceFeeAmount'
            )
          }
          errorMessage={errorForms?.maintenanceFeeAmount}
          onErrorChange={(value: string) =>
            onErrorChange?.(value, 'maintenanceFeeAmount')
          }
          checked={isChecked('calcMaintenanceFeeBoolean')}
          inputValue={monetaryMask(data?.maintenanceFeeAmount)}
        />
        <CheckboxInput
          dataTest={`enable-cancellation-fee-value-for-${type}`}
          width="50%"
          label="Cancelamento"
          onChange={(checked, inputValue) =>
            onChangeTax(
              checked,
              inputValue,
              'calcCancellationFeeBoolean',
              'cancellationFeeAmount'
            )
          }
          errorMessage={errorForms?.cancellationFeeAmount}
          onErrorChange={(value: string) =>
            onErrorChange?.(value, 'cancellationFeeAmount')
          }
          checked={isChecked('calcCancellationFeeBoolean')}
          inputValue={monetaryMask(data?.cancellationFeeAmount)}
        />
        <CheckboxInput
          dataTest={`enable-pay-off-fee-value-for-${type}`}
          width="50%"
          label="Quitação"
          onChange={(checked, inputValue) =>
            onChangeTax(
              checked,
              inputValue,
              'calcPayOffFeeBoolean',
              'payOffFeeAmount'
            )
          }
          errorMessage={errorForms?.payOffFeeAmount}
          onErrorChange={(value: string) =>
            onErrorChange?.(value, 'payOffFeeAmount')
          }
          checked={isChecked('calcPayOffFeeBoolean')}
          inputValue={monetaryMask(data?.payOffFeeAmount)}
        />
      </FlexWrapFormContainer>
    </SessionFormDefault>
  );
};
