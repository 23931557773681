import { FC, useContext, useEffect, useState } from 'react';
import { Container, CredentialsContainer } from './styles';
import DefaultButton from '../default-button';
import Auth, {
  EntityType,
} from '@services/ms-core-trinus-auth/controllers/auth/index';
import { TextInput } from '../form/form-inputs/text-input';
import AlertContext from '@context/alert-context/alertContext';
import { ThemeName } from '../default-button/styles';
import { IoMdCopy } from 'react-icons/io';
import LoadingContext from '../../context/loading-context/loadingContext';

interface EntityCredentialsProps {
  entityType?: string;
  entityId?: number;
  entityKey?: string;
  disabled?: boolean;
  keyDescription?: string;
  onUnsavedChanges?: () => boolean;
}

interface Credentials {
  key?: string;
  secret: string;
}

const EntityCredentials: FC<EntityCredentialsProps> = ({
  entityType,
  entityId,
  disabled,
  keyDescription,
  onUnsavedChanges,
}) => {
  const alert = useContext(AlertContext);
  const loading = useContext(LoadingContext);

  const [showGetCredentials, setShowGetCredentials] = useState<boolean>(false);
  const [copiedCrendetials, setCopiedCrendetials] = useState<boolean>(false);
  const [credentials, setCredentials] = useState<Credentials>({
    key: '',
    secret: '',
  });

  useEffect(() => {
    if (entityType === EntityType.USER) {
      setCredentials({ key: keyDescription, secret: '' });
    }
  }, [keyDescription, entityType]);

  const GetCredentials = async () => {
    if (onUnsavedChanges?.()) {
      return;
    }

    loading.startLoading(true);

    if (entityType === EntityType.USER && credentials.key) {
      setShowGetCredentials(!showGetCredentials);
    }

    if (entityType === EntityType.APPLICATION) {
      setShowGetCredentials(!showGetCredentials);
    }

    const currentCredentials = await Auth.changeCrendentials(
      entityType,
      entityId,
      credentials.key
    );

    if (currentCredentials?.status !== 200) {
      loading.startLoading(false);
      alert.error(
        currentCredentials?.response?.data?.message ||
          currentCredentials?.message
      );

      return;
    }

    setCredentials({
      key: currentCredentials?.data?.key,
      secret: currentCredentials?.data?.secret,
    });

    loading.startLoading(false);
    alert.warning('Ao fechar a tela, as credenciais não serão salvas!');
  };

  const copy = async (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!copiedCrendetials) {
      e.preventDefault();
      await navigator.clipboard.writeText(
        JSON.stringify({
          usuário: credentials.key,
          senha: credentials.secret,
        })
      );
      alert.success('Credenciais copiadas!');

      setCopiedCrendetials(true);
    }
  };

  const renderGetCredentialsButton = () => {
    if (!credentials.secret || disabled) {
      const ruleToDisableForUser =
        entityType === EntityType.USER && (credentials?.key?.length ?? 0) < 8;

      return (
        <DefaultButton
          dataTest={'generate-credentials'}
          theme={ThemeName.PRIMARY_REGULAR}
          onClick={GetCredentials}
          disabled={disabled || ruleToDisableForUser}
          label={
            !disabled
              ? 'Gerar credenciais'
              : 'Não permitido para usuário inativo'
          }
        >
          Gerar credenciais
        </DefaultButton>
      );
    } else {
      return (
        <DefaultButton
          dataTest={'copy-credentials'}
          width={'1.5rem'}
          height={'1.5rem'}
          style={{
            backgroundColor: '#ededed',
            padding: 0,
            display: !disabled ? 'flex' : 'none',
          }}
          onClick={copy}
          disabled={copiedCrendetials}
          className="copy-button"
          label="Copiar credenciais"
        >
          <IoMdCopy size={'10vw'} className="copy-icon" />
        </DefaultButton>
      );
    }
  };

  const renderCredentials = () => {
    return (
      <>
        <CredentialsContainer
          style={{
            display:
              credentials.key?.length && credentials.secret?.length && !disabled
                ? 'flex'
                : 'none',
          }}
        >
          <TextInput
            dataTest={`username-input`}
            readOnly
            label={'Usuário'}
            type="button"
            value={credentials.key}
            width="100%"
            style={{
              textAlign: 'left',
            }}
          />
          <TextInput
            dataTest={`password-input`}
            readOnly
            label={'Senha'}
            type="button"
            value={credentials.secret}
            width="100%"
            style={{
              textAlign: 'left',
              display: !disabled ? 'flex' : 'none',
            }}
          />
        </CredentialsContainer>
        {renderGetCredentialsButton()}
      </>
    );
  };

  return <Container>{renderCredentials()}</Container>;
};

export default EntityCredentials;
