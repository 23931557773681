import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.CORE_TRINUS_PAY_BASE_URL,
});

instance.interceptors.request.use((config) => {
  const userLanguage = navigator?.language;
  config.headers['Content-Language'] = userLanguage || 'pt-BR';
  return config;
});

export default instance;
