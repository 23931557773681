import styled from 'styled-components';

export const Container = styled.div`
  width: 99%;
  height: 6rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  border: 1px solid #d9d9d9;
  padding: 0.5rem;
  border-radius: 0.5rem;
  font-size: 0.75rem;

  &:hover {
    border-radius: 0.5rem;
    background-color: #d9d9d9;
    cursor: pointer;
  }
`;

export const OptionItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  div, label, span {
      &:hover {
      cursor: pointer;
    }
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.2rem;

  label {
    font-weight: bold;
  }
`;