import {
  FC,
  FormHTMLAttributes,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import { FormDefault } from '../form';
import FinalBeneficiaryService from '@services/ms-core-trinus-pay/controllers/final-beneficiary';
import { FormHeader } from '../form/form-header';
import AlertContext from '@context/alert-context/alertContext';
import { BasicDataSession } from './basic-data-session';
import { FinalBeneficiaryAccountSession } from './final-beneficiary-account';
import { GridFormContainer } from '../form/styles';
import { Models } from '@services/ms-core-trinus-pay/models';
import { InternalAccountSessions } from './internal-account-session';
import { EntityLinkSessions } from './entity-link-session';
import LoadingContext from '@context/loading-context/loadingContext';
import { TaxBankSlipSession } from '@components/form/session-form/tax-bank-slip-session';
import { SearchInput } from '../input-search';
import { MdOutlineAccountBalanceWallet } from 'react-icons/md';
import { InputRef } from '@components/input-search/search-input';
import { useFormContext } from '@context/form-context/FormContext';
import Library from '@utils/Library';
import { ConfirmationModal } from '@components/modal/confirmation-modal';

interface FinalBeneficiaryFormProps
  extends FormHTMLAttributes<HTMLFormElement> {}

interface FinalBenenficiarySearchInputProps {
  onClickInItem: (value: Models.FinalBeneficiary) => void;
}

const FinalBenenficiarySearchInput: FC<FinalBenenficiarySearchInputProps> = ({
  onClickInItem,
}) => {
  const [searchResults, setSearchResults] = useState(undefined);
  const [notFoundMsg, setNotFoundMsg] = useState<string>();
  const [isLoadingSearch, setIsLoadingSearch] = useState<boolean>(false);

  const getName = (data: Models.FinalBeneficiary) => data.finalBeneficiaryName;

  const handleFinalBeneficiarySearch = async (value: string) => {
    if (!value) {
      setNotFoundMsg(undefined);
      setSearchResults(undefined);
      return;
    }
    setIsLoadingSearch(true);

    const result = await FinalBeneficiaryService.findFinalBeneficiariesByTerm(
      value
    );

    if (result?.status !== 200) {
      const msg = 'Nenhum resultado...';
      setNotFoundMsg(msg);
      setSearchResults(undefined);
      setIsLoadingSearch(false);
      return;
    }

    setNotFoundMsg(undefined);
    setSearchResults(result.data);
    setIsLoadingSearch(false);
  };

  const handleFormToEdit = (
    e: React.MouseEvent<HTMLButtonElement>,
    finalBeneficiary: Models.FinalBeneficiary
  ) => {
    e.preventDefault();
    onClickInItem(finalBeneficiary);
  };

  const renderSVGIcon = () => {
    return <MdOutlineAccountBalanceWallet fill="#1a6ce8" />;
  };

  return (
    <SearchInput
      dataTest={`search-final-beneficiary-by-name`}
      itemIcon={renderSVGIcon()}
      searchValue={handleFinalBeneficiarySearch}
      displayResult={getName}
      searchResults={searchResults}
      notFoundMsg={notFoundMsg}
      resultToEdit={handleFormToEdit}
      isLoading={isLoadingSearch}
      maxLength={120}
    />
  );
};

export const FinalBeneficiaryForm: FC<FinalBeneficiaryFormProps> = ({
  ...props
}) => {
  const alert = useContext(AlertContext);
  const loading = useContext(LoadingContext);

  const {
    confirmModal,
    errorForms,
    formattedMainRegistration,
    formToEdit,
    handleActionWithConfirmation,
    initialFormData,
    isValidMainRegistrationId,
    loadValueWithCents,
    onErrorChange,
    savedForm,
    setConfirmModal,
    setErrorForms,
    setErrorMessageInputMainRegistration,
    setErrorMessageInputZipCode,
    setFormToEdit,
    setSavedForm,
    updateData,
    validateFeeFields,
    validationRequiredFieldsForm,
  } = useFormContext();

  const [disableSaveButton, setDisableSaveButton] = useState<boolean>(false);
  const inputSelectedRef = useRef<InputRef>(null);
  const [formFinalBeneficiaryTitle, setFormFinalBeneficiaryTitle] =
    useState<string>('');

  const [finalBeneficiaryData, setFinalBeneficiaryData] =
    useState<Models.FinalBeneficiary>();

  const emptyErrors = {
    beneficiaryName: '',
    mainRegistrationId: '',
    addressDescription: '',
    neighborhoodName: '',
    cityName: '',
    stateType: '',
    zipCode: '',
    internalBankAccountId: '',
    maintenanceFeeAmount: '',
    registrationFeeAmount: '',
    cancellationFeeAmount: '',
    payOffFeeAmount: '',
  };

  useEffect(() => {
    if (savedForm?.beneficiaryName) {
      setFormFinalBeneficiaryTitle(savedForm?.beneficiaryName);
    } else {
      setFormFinalBeneficiaryTitle('Cadastrando Beneficiário Final');
    }
  }, [savedForm]);

  useEffect(() => {
    if (finalBeneficiaryData?.finalBeneficiaryId > 0) {
      handleFindFinalBeneficiaryById(finalBeneficiaryData?.finalBeneficiaryId);
    }
  }, [finalBeneficiaryData?.finalBeneficiaryId]);

  const handleFindFinalBeneficiaryById = async (finalBeneficiaryId: number) => {
    clearForm();

    loading.startLoading(true);

    const foundFinalBeneficiary =
      await FinalBeneficiaryService.findFinalBeneficiaryById(
        finalBeneficiaryId
      );

    if (foundFinalBeneficiary?.status !== 200) {
      loading.startLoading(false);
      alert.error(
        foundFinalBeneficiary.message ||
          foundFinalBeneficiary.response.data.message
      );
      return;
    }

    loading.startLoading(false);
    updateFinalBeneficiaryData(foundFinalBeneficiary.data);
  };

  const handleSendFinalBeneficiaryDataToSave = async () => {
    setDisableSaveButton(true);
    loading.startLoading(true);
    const result = await FinalBeneficiaryService.maintainFinalBeneficiary(
      formToEdit
    );
    const status = result.status;
    if (status === 201 || status === 200) {
      const message = savedForm?.finalBeneficiaryId
        ? `O Beneficiário final foi editado.`
        : `O Beneficiário final foi criado.`;

      alert.success(message);
      updateFinalBeneficiaryData(result.data);
    } else {
      loading.startLoading(false);
      alert.error(result.response.data.message || result.message);
    }

    setDisableSaveButton(false);
    loading.startLoading(false);
  };

  const handleSelect = (data: Models.FinalBeneficiary) => {
    handleActionWithConfirmation(() => setFinalBeneficiaryData(data));
  };

  const handleFormClean = () => {
    handleActionWithConfirmation(clearForm);
  };

  const updateFinalBeneficiaryData = (
    data: Models.FinalBeneficiaryFindResponse | Models.FinalBeneficiaryData
  ) => {
    const chooseProperty = (
      nestedProp: any,
      directProp: any,
      defaultValue = undefined
    ) => {
      return nestedProp ?? directProp ?? defaultValue;
    };

    const dataToUpdate = {
      finalBeneficiaryId: Number(data.finalBeneficiaryId),
      beneficiaryName: data.beneficiaryName,
      mainRegistrationId: formattedMainRegistration(data?.mainRegistrationId),
      addressDescription: chooseProperty(
        (data as Models.FinalBeneficiaryFindResponse)?.address
          ?.addressDescription,
        (data as Models.FinalBeneficiaryData)?.addressDescription
      ),
      neighborhoodName: chooseProperty(
        (data as Models.FinalBeneficiaryFindResponse)?.address
          ?.neighborhoodName,
        (data as Models.FinalBeneficiaryData)?.neighborhoodName
      ),
      cityName: chooseProperty(
        (data as Models.FinalBeneficiaryFindResponse)?.address?.cityName,
        (data as Models.FinalBeneficiaryData)?.cityName
      ),
      stateType: chooseProperty(
        (data as Models.FinalBeneficiaryFindResponse)?.address?.stateType,
        (data as Models.FinalBeneficiaryData)?.stateType
      ),
      zipCode: chooseProperty(
        (data as Models.FinalBeneficiaryFindResponse)?.address?.zipCode,
        (data as Models.FinalBeneficiaryData)?.zipCode
      ),
      calcPayOffFeeBoolean: chooseProperty(
        (data as Models.FinalBeneficiaryFindResponse)?.feeParams
          ?.calcPayOffFeeBoolean,
        (data as Models.FinalBeneficiaryData)?.calcPayOffFeeBoolean,
        'N'
      ),
      payOffFeeAmount: loadValueWithCents(
        chooseProperty(
          (data as Models.FinalBeneficiaryFindResponse)?.feeParams
            ?.payOffFeeAmount,
          (data as Models.FinalBeneficiaryData)?.payOffFeeAmount
        )
      ),
      calcRegistrationFeeBoolean: chooseProperty(
        (data as Models.FinalBeneficiaryFindResponse)?.feeParams
          ?.calcRegistrationFeeBoolean,
        (data as Models.FinalBeneficiaryData)?.calcRegistrationFeeBoolean,
        'N'
      ),
      registrationFeeAmount: loadValueWithCents(
        chooseProperty(
          (data as Models.FinalBeneficiaryFindResponse)?.feeParams
            ?.registrationFeeAmount,
          (data as Models.FinalBeneficiaryData)?.registrationFeeAmount
        )
      ),
      calcCancellationFeeBoolean: chooseProperty(
        (data as Models.FinalBeneficiaryFindResponse)?.feeParams
          ?.calcCancellationFeeBoolean,
        (data as Models.FinalBeneficiaryData)?.calcCancellationFeeBoolean,
        'N'
      ),
      cancellationFeeAmount: loadValueWithCents(
        chooseProperty(
          (data as Models.FinalBeneficiaryFindResponse)?.feeParams
            ?.cancellationFeeAmount,
          (data as Models.FinalBeneficiaryData)?.cancellationFeeAmount
        )
      ),
      calcMaintenanceFeeBoolean: chooseProperty(
        (data as Models.FinalBeneficiaryFindResponse)?.feeParams
          ?.calcMaintenanceFeeBoolean,
        (data as Models.FinalBeneficiaryData)?.calcMaintenanceFeeBoolean,
        'N'
      ),
      maintenanceFeeAmount: loadValueWithCents(
        chooseProperty(
          (data as Models.FinalBeneficiaryFindResponse)?.feeParams
            ?.maintenanceFeeAmount,
          (data as Models.FinalBeneficiaryData)?.maintenanceFeeAmount
        )
      ),
      internalBankAccountId: data?.internalBankAccountId
        ? Number(data.internalBankAccountId)
        : undefined,
      coreBankingAccountId: chooseProperty(
        (data as Models.FinalBeneficiaryFindResponse)?.coreBanking?.accountId,
        (data as Models.FinalBeneficiaryData)?.coreBankingAccountId
      ),
      coreBankingAccountType: chooseProperty(
        (data as Models.FinalBeneficiaryFindResponse)?.coreBanking?.accountType,
        (data as Models.FinalBeneficiaryData)?.coreBankingAccountType
      ),
      coreBankingAccountNumber: chooseProperty(
        (data as Models.FinalBeneficiaryFindResponse)?.coreBanking
          ?.accountNumber,
        (data as Models.FinalBeneficiaryData)?.coreBankingAccountNumber
      ),
      coreBankingBankCode: chooseProperty(
        (data as Models.FinalBeneficiaryFindResponse)?.coreBanking?.bankCode,
        (data as Models.FinalBeneficiaryData)?.coreBankingBankCode
      ),
      coreBankingBranchCode: chooseProperty(
        (data as Models.FinalBeneficiaryFindResponse)?.coreBanking?.branchCode,
        (data as Models.FinalBeneficiaryData)?.coreBankingBranchCode
      ),
      coreBankingClearingCode: chooseProperty(
        (data as Models.FinalBeneficiaryFindResponse)?.coreBanking
          ?.clearingCode,
        (data as Models.FinalBeneficiaryData)?.coreBankingClearingCode
      ),
    };

    updateData(dataToUpdate);
  };

  const validateFinalBeneficiaryData = () => {
    const requiredFields = [
      'beneficiaryName',
      'mainRegistrationId',
      'addressDescription',
      'neighborhoodName',
      'cityName',
      'stateType',
      'zipCode',
      'internalBankAccountId',
    ];

    const booleanFields = [
      { field: 'calcPayOffFeeBoolean', associatedField: 'payOffFeeAmount' },
      {
        field: 'calcRegistrationFeeBoolean',
        associatedField: 'registrationFeeAmount',
      },
      {
        field: 'calcCancellationFeeBoolean',
        associatedField: 'cancellationFeeAmount',
      },
      {
        field: 'calcMaintenanceFeeBoolean',
        associatedField: 'maintenanceFeeAmount',
      },
    ];

    const resultValidateRequiredFields = validationRequiredFieldsForm(
      { ...formToEdit },
      requiredFields,
      emptyErrors
    );

    const resultValidateFeeFields = validateFeeFields(
      { ...formToEdit },
      booleanFields
    );

    setErrorForms({
      ...resultValidateRequiredFields.formErrorsFound,
      ...resultValidateFeeFields.errors,
    });

    if (
      resultValidateRequiredFields.isValid &&
      resultValidateFeeFields.isValid &&
      isValidMainRegistrationId(formToEdit['mainRegistrationId']) &&
      formToEdit['zipCode']?.length === 8 &&
      formToEdit['stateType']?.length === 2
    ) {
      handleSendFinalBeneficiaryDataToSave();
    } else {
      alert.error(
        'Não foi possível salvar. Por favor, revise o formulário e tente novamente.'
      );
    }
  };

  const clearForm = () => {
    setConfirmModal({ show: false });
    setSavedForm(undefined);
    setFormToEdit({ ...initialFormData });
    setErrorForms({ ...emptyErrors });
    setErrorMessageInputZipCode('');
    setErrorMessageInputMainRegistration('');
    setFinalBeneficiaryData(undefined);
    inputSelectedRef?.current?.clearInput();
    alert.clear();
  };

  return (
    <FormDefault {...props}>
      <FormHeader
        title={formFinalBeneficiaryTitle}
        disableButton={disableSaveButton}
        hasHeaderDefaultButtons={true}
        handleButton={validateFinalBeneficiaryData}
        handleCancel={handleFormClean}
      >
        <FinalBenenficiarySearchInput onClickInItem={handleSelect} />
      </FormHeader>
      <GridFormContainer>
        <div style={{ width: '100%' }}>
          <BasicDataSession
            finalBeneficiaryData={formToEdit}
            errorForms={errorForms}
          />
          <TaxBankSlipSession
            data={formToEdit}
            onUpdate={(data: Models.FinalBeneficiaryData) => {
              setFormToEdit(data);
            }}
            type="final-beneficiary"
            errorForms={errorForms}
            onErrorChange={onErrorChange}
          />
        </div>

        <div>
          <FinalBeneficiaryAccountSession
            finalBeneficiaryData={formToEdit}
            errorForms={errorForms}
          />
          <InternalAccountSessions
            internalBankAccountId={formToEdit?.internalBankAccountId}
            onUpdate={(value) => {
              setFormToEdit({
                ...formToEdit,
                internalBankAccountId: value,
              });
            }}
            errorForms={errorForms}
            onErrorChange={onErrorChange}
            inputRef={inputSelectedRef}
          />
          <EntityLinkSessions
            finalBeneficiaryId={formToEdit?.finalBeneficiaryId}
            errorForms={errorForms}
          />
        </div>
      </GridFormContainer>
      {confirmModal.show && (
        <ConfirmationModal
          displayModal={true}
          title={confirmModal?.title}
          message={confirmModal?.message}
          onConfirm={() => confirmModal?.confirmAction?.()}
          onCancel={() => {
            setConfirmModal({ show: false });
          }}
        />
      )}
    </FormDefault>
  );
};
