import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-height: 20vh;
  margin-top: 0.1rem;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 8px #ddd;
  border-radius: 0.5rem;
  overflow-y: scroll;
  overflow-x: hidden;
  position: absolute;
  background-color: white;

  > p {
    font-size: 0.7rem;
    padding: 0.5rem;
  }
`;

export const SearchItem = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
  border: none;
  border-bottom: 1px solid #55678b;
  height: 5vh;
  gap: 0.5rem;
  padding: 0.5rem;

  &:hover {
    cursor: pointer;
  }

  &:last-child {
    border-bottom: none;
  }

  > span {
    width: 90%;
    text-align: start;
    font-size: 0.75rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > svg {
    width: 1rem;
    height: 1rem;
  }

  @media (max-width: 300px) {
    padding: 0.1rem 0;

    > span {
      font-size: 0.5rem;
    }

    > svg {
      display: none;
    }
  }
`;
