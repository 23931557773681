import {
  FC,
  useCallback,
  useState,
  useEffect,
  HTMLAttributes,
  useContext,
} from 'react';
import { Models } from '@services/ms-core-trinus-auth/models';
import EntityPay from '@services/ms-core-trinus-pay/controllers/entity';
import EntityAuth from '@services/ms-core-trinus-auth/controllers/entity';
import { InputSelected } from '@components/form/form-inputs/input-selected';
import { SessionFormDefault } from '@components/form/session-form';
import { MdOutlineJoinLeft } from 'react-icons/md';
import AlertContext from '@context/alert-context/alertContext';
import LoadingContext from '@context/loading-context/loadingContext';

interface EntityLinkSessionsProps extends HTMLAttributes<HTMLDivElement> {
  finalBeneficiaryId?: number;
  errorMessage?: string;
  width?: string;
  errorForms?: { [key: string]: string };
}

export const EntityLinkSessions: FC<EntityLinkSessionsProps> = ({
  finalBeneficiaryId,
  errorMessage,
  width = '100%',
  errorForms,
  ...props
}) => {
  const [entityData, setEntityData] = useState<Models.AuthEntity>();
  const [searchResults, setSearchResults] = useState(undefined);
  const [notFoundMsg, setNotFoundMsg] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>();
  const alert = useContext(AlertContext);
  const loading = useContext(LoadingContext);

  const getName = (data: Models.AuthEntity) => {
    if (!data) {
      return;
    }

    return `${data?.fullName}`;
  };

  const findEntityLinkedFinalBeneficiary = async () => {
    setEntityData(undefined);

    if (!finalBeneficiaryId) {
      return;
    }
    setIsLoading(true);

    const result = await EntityPay.findEntityByFinalBeneficiaryId(
      finalBeneficiaryId
    );

    const entityId = result?.data?.entities?.[0];

    if (result?.status !== 200 || !entityId) {
      setIsLoading(false);
      return;
    }

    const resultAuth = await EntityAuth.findEntityByEntityId(entityId);
    if (resultAuth?.status !== 200) {
      setIsLoading(false);
      setEntityData(undefined);

      alert.error(resultAuth?.message || resultAuth?.response?.data?.message);
      return;
    }

    const data = {
      entityId: resultAuth.data?.entityId,
      fullName: resultAuth.data?.fullName,
      entityType: resultAuth.data?.entityType,
    };

    setEntityData(data);
    setIsLoading(false);
  };

  const handleEntitySearch = useCallback(async (value: string) => {
    if (!value) {
      setNotFoundMsg(undefined);
      setSearchResults(undefined);
      return;
    }
    setIsLoading(true);

    const result = await EntityAuth.findEntityByEntityName(
      Models.EntityType.etApplication,
      value
    );

    if (result?.status !== 200) {
      const msg = 'Nenhum resultado...';
      setNotFoundMsg(msg);
      setSearchResults(undefined);
      setIsLoading(false);
      return;
    }

    setNotFoundMsg(undefined);
    setSearchResults(result.data);
    setIsLoading(false);
  }, []);

  const handleSelectedValue = useCallback(
    async (
      e: React.MouseEvent<HTMLButtonElement>,
      entity: Models.AuthEntity
    ) => {
      e.preventDefault();
      loading.startLoading(true);
      setEntityData(entity);
      setSearchResults(undefined);

      const result = await EntityPay.linkEntityToFinalBeneficiary(
        finalBeneficiaryId,
        entity?.entityId
      );

      if (result?.status !== 201) {
        loading.startLoading(false);
        setEntityData(undefined);
        alert.error(result?.message || result?.response?.data?.message);
      } else {
        loading.startLoading(false);
        alert.success(`ERP vinculado com sucesso ao beneficiário final.`);
      }
    },
    [finalBeneficiaryId, alert]
  );

  const handleRemoveSelectedValue = useCallback(async () => {
    loading.startLoading(true);
    const entity = entityData;
    setEntityData(undefined);
    setSearchResults(undefined);

    const result = await EntityPay.RemoveLinkOfEntityEndFinalBeneficiary(
      finalBeneficiaryId,
      entityData?.entityId
    );

    if (result?.status !== 200) {
      loading.startLoading(false);
      setEntityData(entity);
      alert.error(result?.message || result?.response?.data?.message);
    } else {
      loading.startLoading(false);
      alert.success(`ERP desvinculado com sucesso do beneficiário final.`);
    }
  }, [entityData, finalBeneficiaryId, alert]);

  const renderSVGIcon = () => {
    return <MdOutlineJoinLeft fill="#1a6ce8" />;
  };

  useEffect(() => {
    findEntityLinkedFinalBeneficiary();
  }, [finalBeneficiaryId]);

  const selectedValue = getName(entityData);

  if (!finalBeneficiaryId) {
    return <></>;
  }

  return (
    <SessionFormDefault subTitle="Vincular ERP" width={width} {...props}>
      <InputSelected
        dataTest={`link-application-to-final-beneficiary`}
        itemIcon={renderSVGIcon()}
        label="Entidade"
        customPlaceholder={'Pesquisar por nome da aplicação'}
        searchValue={handleEntitySearch}
        displayResult={getName}
        searchResults={searchResults}
        notFoundMsg={notFoundMsg}
        onSelectedValue={handleSelectedValue}
        selectedValue={selectedValue}
        onRemoveValue={handleRemoveSelectedValue}
        errorMessage={errorMessage}
        maxLength={300}
        isLoading={isLoading}
      />
    </SessionFormDefault>
  );
};
