export namespace Models {
  export interface AuthCredentials {
    key: string;
    secret: string;
    secretExpirationDate: string;
  }
  export interface AuthEntity {
    entityId: number;
    fullName: string;
    entityType: string;
  }

  export interface AuthErrors {
    response: {
      data: {
        message: string;
      };
    };
    status: number;
    message?: string;
  }

  export interface ListGrant {
    entityId: number;
    fullName: string;
    email: string;
    type: string;
    activeGrants: {
      applicationEntityId: number;
      fullName: string;
      keyList: string[];
      isRevoked: boolean;
    }[];
    availableGrants: {
      applicationEntityId: number;
      fullName: string;
      keyList: string[];
    }[];
  }

  export interface AuthToken {
    token: string;
    refreshToken: string;
  }
  export interface AuthEntityData {
    entityId?: number;
    fullName?: string;
    firstName?: string;
    lastName?: string;
    entityType?: string;
    emailDescription?: string;
    detailDescription?: string;
    statusType?: string;
    keyDescription?: string;
  }

  export interface AuthMaintainEntityResponse {
    entityId: number;
    fullName: string;
    entityType: string;
    emailDescription?: string;
    detailDescription?: string;
    statusType: string;
    firstName?: string;
    lastName?: string;
    keyDescription?: string;
  }

  export enum EntityType {
    etApplication = 'APP',
    etUser = 'USE',
  }
}
