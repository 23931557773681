import { InternalBankAccountForm } from '@components/internal-bank-account-form';
import { FormProvider } from '@context/form-context/FormContext';

export const InternalBankAccount = () => {
  return (
    <FormProvider
      initialFormData={{
        calcPayOffFeeBoolean: 'N',
        payOffFeeAmount: 0,

        calcRegistrationFeeBoolean: 'N',
        registrationFeeAmount: 0,

        calcCancellationFeeBoolean: 'N',
        cancellationFeeAmount: 0,

        calcMaintenanceFeeBoolean: 'N',
        maintenanceFeeAmount: 0,

        statusType: 'ACT',
        interBkBenefPersonType: 'LEG',
        accountType: 'CUR',
      }}
    >
      <InternalBankAccountForm />
    </FormProvider>
  );
};
