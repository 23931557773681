import { FC, HTMLAttributes, useEffect } from 'react';
import { SessionFormDefault } from '@components/form/session-form';
import { TextInput } from '@components/form/form-inputs/text-input';
import { FlexWrapFormContainer } from '@components/form/styles';
import { Models } from '@services/ms-core-trinus-pay/models';
import { SelectList } from '@components/form/select-list';
import { useForm } from '@hooks/useForm';

interface AccountIdentificationSessionProps
  extends HTMLAttributes<HTMLDivElement> {
  internalBankAccountData: Models.InternalBankAccountData;
  onUpdate: (data: Models.InternalBankAccountData) => void;
  width?: string;
  height?: string;
  errorForms?: { [key: string]: string };
  onErrorChange?: (value: string, field: string) => void;
}

export const AccountIdentificationSession: FC<
  AccountIdentificationSessionProps
> = ({
  internalBankAccountData,
  onUpdate,
  width,
  height,
  errorForms,
  onErrorChange,
  ...props
}) => {
  const {
    onChangeTextInputValue,
    handleAccountTypeChangeSelect,
    handleAccountType,
    accountType,
    handleChangeOnlyNumbers,
    handleChangeWithoutSpecialCharacters,
  } = useForm({ ...internalBankAccountData }, onUpdate);

  useEffect(() => {
    if (internalBankAccountData) {
      handleAccountType('accountType');
    }
  }, [internalBankAccountData]);

  return (
    <SessionFormDefault
      subTitle="Dados da conta no banco parceiro"
      width="100%"
      {...props}
    >
      <FlexWrapFormContainer
        $justifyContent="space-between"
        $flexDirection="row"
      >
        <FlexWrapFormContainer $width="50%" $justifyContent="space-between">
          <TextInput
            dataTest={`internal-bank-account-number-input`}
            type="text"
            value={internalBankAccountData?.accountNumberCode ?? ''}
            label="Número da conta"
            width="50%"
            onChange={(e) => handleChangeOnlyNumbers(e, 'accountNumberCode')}
            maxLength={50}
            required
            errorMessage={errorForms?.accountNumberCode}
            onErrorChange={(value) =>
              onErrorChange?.(value, 'accountNumberCode')
            }
          />
          <TextInput
            dataTest={`internal-bank-account-verifying-number`}
            type="text"
            value={internalBankAccountData?.accountVerifyingNumberCode ?? ''}
            label="Código de verificação"
            width="50%"
            onChange={(e) =>
              handleChangeOnlyNumbers(e, 'accountVerifyingNumberCode')
            }
            maxLength={1}
            required
            errorMessage={errorForms?.accountVerifyingNumberCode}
            onErrorChange={(value) =>
              onErrorChange?.(value, 'accountVerifyingNumberCode')
            }
          />
          <SelectList
            dataTest={`internal-bank-account-type-select`}
            selectValue={accountType}
            label="Tipo de Conta"
            width="100%"
            selectOptions={['Conta Corrente', 'Conta Poupança']}
            onChange={(e) => handleAccountTypeChangeSelect(e, 'accountType')}
            required
          />
          <TextInput
            dataTest={`internal-bank-account-ispb-code-input`}
            type="Text"
            value={internalBankAccountData?.ispbCode ?? ''}
            label="Código ISPB"
            width="100%"
            onChange={(e) => handleChangeOnlyNumbers(e, 'ispbCode')}
            maxLength={50}
          />
          <TextInput
            dataTest={`internal-bank-account-region-type-input`}
            type="Text"
            value={internalBankAccountData?.regionType ?? ''}
            label="País"
            width="100%"
            onChange={(e) => onChangeTextInputValue(e, 'regionType')}
            maxLength={3}
            required
            errorMessage={errorForms?.regionType}
            onErrorChange={(value) => onErrorChange?.(value, 'regionType')}
          />
        </FlexWrapFormContainer>

        <FlexWrapFormContainer $width="50%" $justifyContent="space-between">
          <TextInput
            dataTest={`internal-bank-account-branch-code-input`}
            type="text"
            value={internalBankAccountData?.bankBranchCode ?? ''}
            label="Agência"
            width="100%"
            onChange={(e) =>
              handleChangeWithoutSpecialCharacters(e, 'bankBranchCode')
            }
            maxLength={50}
            required
            errorMessage={errorForms?.bankBranchCode}
            onErrorChange={(value) => onErrorChange?.(value, 'bankBranchCode')}
          />
          <TextInput
            dataTest={`internal-bank-account-bank-beneficiary-code-input`}
            type="Text"
            value={internalBankAccountData?.interBkBenefCode ?? ''}
            label="Código do beneficiário"
            width="100%"
            onChange={(e) => handleChangeOnlyNumbers(e, 'interBkBenefCode')}
            maxLength={50}
            required
            errorMessage={errorForms?.interBkBenefCode}
            onErrorChange={(value) =>
              onErrorChange?.(value, 'interBkBenefCode')
            }
          />
          <TextInput
            dataTest={`internal-bank-account-bank-code-input`}
            type="text"
            value={internalBankAccountData?.bankCode ?? ''}
            maxLength={50}
            label="Código do Banco"
            width="100%"
            onChange={(e) => handleChangeOnlyNumbers(e, 'bankCode')}
            required
            errorMessage={errorForms?.bankCode}
            onErrorChange={(value) => onErrorChange?.(value, 'bankCode')}
          />
          <TextInput
            dataTest={`internal-bank-account-bank-wallet-code-input`}
            type="Text"
            value={internalBankAccountData?.bankWalletCode ?? ''}
            label="Código da carteira"
            width="100%"
            onChange={(e) => handleChangeOnlyNumbers(e, 'bankWalletCode')}
            maxLength={50}
          />
        </FlexWrapFormContainer>
      </FlexWrapFormContainer>
    </SessionFormDefault>
  );
};
