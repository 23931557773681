import styled, { css } from 'styled-components';

interface InputProps {
  $isFocused?: boolean;
  $isValid?: boolean;
}

export const Input = styled.input<InputProps>`
  margin: 0;
  box-sizing: border-box;
  width: 100%;
  font-size: 0.9rem;
  padding: 0.5rem;
  border-radius: 0.25rem;
  outline: none;

  ${({ $isFocused, $isValid }) => {
    let borderColor: string;
    if ($isFocused) {
      borderColor = '#000';
    } else if (!$isValid) {
      borderColor = 'red';
    } else {
      borderColor = '#d9d9d9';
    }

    return css`
      border: 1px solid ${borderColor};
    `;
  }}

  ${({ disabled }) =>
    disabled &&
    css`
      border: 1px solid #b6c1c2;
      background-color: white;
    `}
`;
