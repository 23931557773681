import {
  ButtonHTMLAttributes,
  MouseEvent,
  ReactNode,
  forwardRef,
  ForwardedRef,
} from 'react';
import { Button, ThemeName } from './styles';
interface DefaultButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: ReactNode;
  label?: string;
  title?: string;
  width?: string | number;
  height?: string | number;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  Icon?: () => ReactNode;
  iconPosition?: 'before' | 'after';
  theme?: ThemeName;
  disabled?: boolean;
  dataTest: string;
}

const DefaultButton = forwardRef<HTMLButtonElement, DefaultButtonProps>(
  (
    {
      children,
      title,
      label,
      width,
      height,
      onClick,
      Icon,
      iconPosition = 'before',
      theme = ThemeName.PRIMARY_BOLD,
      disabled,
      className,
      dataTest,
      ...props
    },
    ref?: ForwardedRef<HTMLButtonElement>
  ) => {
    const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      if (!disabled) {
        onClick(e);
      }
    };

    return (
      <Button
        type="button"
        data-testid={dataTest}
        disabled={disabled}
        $theme={theme}
        $width={width}
        $height={height}
        title={label}
        onClick={handleClick}
        ref={ref ?? null}
        {...props}
      >
        <>
          {children}
          {iconPosition === 'before' && Icon && Icon()}
          {title}
          {iconPosition === 'after' && Icon && Icon()}
        </>
      </Button>
    );
  }
);

export default DefaultButton;
