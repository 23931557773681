import { FC, ChangeEvent, MouseEvent } from 'react';
import { Container, CustomModal } from './styles';
import { OptionItem } from '@components/modal/options-modal/options-items';
import { FlexWrapFormContainer } from '@components/form/styles';
import Library from '@utils/Library';

interface OptionsModalProps {
  children?: React.ReactNode;
  displayModal: boolean;
  $width?: string;
  $height?: string;
  justifyChildrenContent?: string;
  modalHasCheckBoxes: boolean;
  modalOptions: any[];
  optionSelected: boolean;
  onCheckBoxChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onClick?: (e: MouseEvent<HTMLInputElement>) => void;
  required: boolean;
}

export const OptionsModal: FC<OptionsModalProps> = ({
  children,
  displayModal,
  $width,
  $height,
  justifyChildrenContent,
  modalHasCheckBoxes,
  modalOptions,
  optionSelected,
  onCheckBoxChange,
  onClick,
  required,
}) => {
  return (
    <CustomModal
      displayModal={displayModal}
      width={$width}
      height={$height}
      modalCanBeClosed={false}
    >
      <Container>
        {modalOptions.map((option: any, index: number) => {
          const optionsKeys = Library.getObjectKeys(option);
          return (
            <OptionItem
              data-testid="option-item"
              key={index}
              modalHasCheckBoxes={modalHasCheckBoxes}
              optionData={option}
              optionsKeys={optionsKeys}
              checkBoxChecked={optionSelected === option}
              onCheckBoxChange={() => onCheckBoxChange(option)}
              onClick={() => onClick(option)}
              requiredCheckBox={required}
            />
          );
        })}
      </Container>
      <FlexWrapFormContainer $justifyContent={justifyChildrenContent}>
        {children}
      </FlexWrapFormContainer>
    </CustomModal>
  );
};
