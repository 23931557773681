import {
  FC,
  useRef,
  useState,
  useEffect,
  cloneElement,
  ButtonHTMLAttributes,
} from 'react';
import DefaultButton from '../default-button';
import { ModalWrapper, ChildWrapper } from './styles';
import { ThemeName } from '../default-button/styles';

interface ModalProps {
  disabled?: boolean;
  buttonTitle: string;
  children: React.ReactElement<any>;
  dataTest: string;
}

const DynamicPositionedModal: FC<ModalProps> = ({
  disabled = false,
  buttonTitle,
  children,
  dataTest,
}) => {
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const modalRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  let resizeTimer: ReturnType<typeof setTimeout>;

  const handlePosition = () => {
    if (isOpen && modalRef.current && buttonRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      const modalRect = modalRef.current.getBoundingClientRect();
      const auxPosition = {
        top: buttonRect.bottom + 10,
        left: buttonRect.left,
      };

      if (buttonRect.left + modalRect.width > window.innerWidth) {
        auxPosition.left = buttonRect.right - modalRect.width;
      }

      setPosition(auxPosition);
    }
  };

  const handleResize = () => {
    clearTimeout(resizeTimer);

    resizeTimer = setTimeout(() => {
      handlePosition();
    }, 50);
  };

  const handleShowModal = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    handleResize();
  }, [isOpen, modalRef?.current, buttonRef?.current]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      clearTimeout(resizeTimer);
    };
  }, []);

  return (
    <>
      <DefaultButton
        dataTest={dataTest}
        theme={ThemeName.PRIMARY_REGULAR}
        ref={buttonRef}
        disabled={disabled}
        label={!disabled ? buttonTitle : 'Não permitido para usuário inativo'}
        title={buttonTitle}
        onClick={handleShowModal}
      />
      {isOpen && (
        <ModalWrapper
          data-testid='permissions-modal'
          ref={modalRef}
          className={isOpen ? 'fade-in' : 'fade-out'}
          style={{ top: `${position.top}px`, left: `${position.left}px` }}
        >
          <ChildWrapper>
            {cloneElement(children, { onClose: handleShowModal })}
          </ChildWrapper>
        </ModalWrapper>
      )}
    </>
  );
};

export default DynamicPositionedModal;
