import { FC } from 'react';

import { Container, SearchItem } from './styles';

interface SearchResultsProps {
  results: any[] | undefined;
  displayResult: (result: any) => any;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>, result: any) => void;
  itemIcon?: React.ReactNode;
  notFoundMsg: string;
}

export const SearchResults: FC<SearchResultsProps> = ({
  results,
  displayResult,
  onClick,
  itemIcon,
  notFoundMsg,
}) => {
  const sendResultToEdit = (
    e: React.MouseEvent<HTMLButtonElement>,
    result: any
  ) => {
    onClick(e, result);
  };

  const renderSVGIcon = () => {
    if (itemIcon) {
      return itemIcon;
    }
  };

  const handleShowResults = () => {
    if (!results && !notFoundMsg) {
      return <></>;
    } else if (!results && notFoundMsg) {
      return <p>{notFoundMsg}</p>;
    }

    return results.map((result, index) => {
      return (
        <SearchItem
          key={index}
          data-testid={`${index}-search-item`}
          onClick={(e) => sendResultToEdit(e, result)}
        >
          {renderSVGIcon()}
          <span>{displayResult(result)}</span>
        </SearchItem>
      );
    });
  };

  return <Container>{handleShowResults()}</Container>;
};
