import { AxiosResponse } from 'axios';
import Context from '@services/ms-core-trinus-pay/context';
import { Models } from '@services/ms-core-trinus-pay/models';
import Auth from '@services/ms-core-trinus-auth/controllers/auth';

class InternalBankAccount {
  /* Sobre esta função */ /**
   * Buscar lista de contas internas pelo número da conta
   * @param {string} searchTerm Recebe o termo de busca a ser pesquisada
   **/
  public async findInternalBankAccountByTerm(
    searchTerm: string
  ): Promise<
    AxiosResponse<Models.InternalBankAccount[]> & Models.MsCoreTrinusPayErrors
  > {
    const token = await Auth.getToken();
    if (!token) return;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return await Context.get(
      `/internal-bank-account/search/${searchTerm}`,
      config
    )
      .then((Response) => {
        return Response;
      })
      .catch((Exception) => {
        return Exception;
      });
  }

  /* Sobre esta função */ /**
   * Buscar a contas internas pelo id
   * @param {number} internalBankAccountId Recebe o termo de busca a ser pesquisada
   **/
  public async findInternalBankAccountById(
    internalBankAccountId: number
  ): Promise<
    AxiosResponse<Models.InternalBankAccountFindById> &
      Models.MsCoreTrinusPayErrors
  > {
    const token = await Auth.getToken();
    if (!token) return;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return await Context.get(
      `/internal-bank-account/internal-bank-account-id/${internalBankAccountId}`,
      config
    )
      .then((Response) => {
        return Response;
      })
      .catch((Exception) => {
        return Exception;
      });
  }

  /* Sobre esta função */ /**
   * Criar ou editar uma conta interna
   * @param {Models.InternalBankAccountData} internalBankAccountData Recebe o os dados de conta interna a ser cadastrado ou editado
   **/
  public async maintainInternalBankAccount(
    internalBankAccountData: Models.InternalBankAccountData
  ): Promise<
    AxiosResponse<Models.InternalBankAccountData> & Models.MsCoreTrinusPayErrors
  > {
    const token = await Auth.getToken();
    if (!token) return;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    let response: Promise<AxiosResponse>;

    if (internalBankAccountData?.internalBankAccountId) {
      response = Context.put(
        `/internal-bank-account/${internalBankAccountData.internalBankAccountId}`,
        internalBankAccountData,
        config
      );
    } else {
      response = Context.post(
        `/internal-bank-account`,
        internalBankAccountData,
        config
      );
    }

    return response
      .then((Response) => Response)
      .catch((Exception) => Exception);
  }
}

export default new InternalBankAccount();
